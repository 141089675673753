import { Box, styled } from "@mui/material";
import bgImg from '../../assets/background.jpeg'


const StyledAuth = styled(Box)(({ theme }) => ({

    backgroundImage: `url(${bgImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100vh',
    width: '100vw',
    padding: '30px',
    display: 'flex',
    position : 'relative',
    gap: '30px',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column'
    },

    '.email-feild': {

        '& .MuiInputBase-root': {

            border: '1px solid #e0e0e0',
            padding: '7px 10px',
            borderRadius: '10px',
            transition: 'all 0.4s ease-in-out',
            backgroundColor: '#f5f5f5',
        },
        '& .Mui-focused': {
            backgroundColor: 'black !important',
            color: 'white',
            '& .MuiSvgIcon-root': {
                color: 'white'
            },
            '& ::placeholder': {
                color: 'white',
                opacity : '0.7'
            }
        },

        [theme.breakpoints.down('sm')]: {
            width: '100%',

        }

    },

}))

export {
    StyledAuth
}