import { configureStore } from '@reduxjs/toolkit'
import { userApi } from '../services/userApi';
import { awsApi } from '../services/awsApi';

const store = configureStore({
    reducer: {
        [userApi.reducerPath]: userApi.reducer,
        [awsApi.reducerPath]: awsApi.reducer,


    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(userApi.middleware)
    .concat(awsApi.middleware)
})

export default store;