import { Box, Grid, IconButton, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import StatesContext from '../../../context/StatesContext'
import { useActivePackagesListQuery, useUserInfoQuery } from '../../../services/userApi'
import { Link } from 'react-router-dom'
import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import moment from 'moment'

const Header = () => {

    const { data } = useUserInfoQuery()
    const { data: list, refetch } = useActivePackagesListQuery()

    const context = useContext(StatesContext)
    const { setuserFound, userFound, timerDays, setTimerDays,
        timerHours, setTimerHours,
        timerMinutes, setTimerMinutes,
        timerSeconds, setTimerSeconds,
        ispkgbuyed, setispkgbuyed,
        expiryTime, setexpiryTime,
        months, setmonths
    } = context

    const [url, seturl] = useState('')
    const [isFound, setisFound] = useState(false)

    let interval;

    const startTimer = () => {

        const countDownDate = new Date(list && list.totalExpireTime && list.totalExpireTime).getTime();

        interval = setInterval(() => {
            const now = new Date().getTime();

            const distance = countDownDate - now;

            let monthDifference = moment(countDownDate).diff(new Date(), 'months', true);
            monthDifference = monthDifference.toString().split('.')[0]
            monthDifference = Number(monthDifference)

            const days = Math.floor(distance / (24 * 60 * 60 * 1000)) % 30
            const hours = Math.floor(
                (distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
            );
            const minutes = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
            const seconds = Math.floor((distance % (60 * 1000)) / 1000);

            if (distance < 0) {
                // Stop Timer
                clearInterval(interval.current);

            } else {
                // Update Timer
                setTimerDays(days);
                setTimerHours(hours);
                setTimerMinutes(minutes);
                setTimerSeconds(seconds);
                setmonths(monthDifference)
            }
        }, 1000);

    };

    useEffect(() => {
        if (expiryTime > 0) {
            startTimer();
        }
    });

    useEffect(() => {
        if (list && list.isExpireTime > 0) {
            setexpiryTime(list.isExpireTime)
        }
        if (list && list.activePackages.length) {
            if (list.activePackages[0].name !== 'Trial') {
                seturl(list.activePackages[0]._id)
            }
            let isActive = list.activePackages.find(item => item.expiresAt)
            if (!isActive) {
                let diff = 0
                list.activePackages.forEach((item => {
                    if (item.days === 0.2) {

                        diff = diff + 43200000

                    } else {

                        diff = diff + (item.days * 86400000)

                    }
                }))
                setisFound(true)
                diff = diff + list.adminTime

                let countDownDate = new Date(new Date().getTime() + diff).getTime()
                const now = new Date().getTime();

                let monthDifference = moment(new Date(new Date(new Date().getTime() + diff))).diff(new Date(), 'months', true);
                monthDifference = monthDifference.toString().split('.')[0]
                monthDifference = Number(monthDifference)

                const distance = countDownDate - now;
                const days = Math.floor(distance / (24 * 60 * 60 * 1000)) % 30
                const hours = Math.floor(
                    (distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
                );
                const minutes = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
                const seconds = Math.floor((distance % (60 * 1000)) / 1000);

                if (monthDifference === 0) {
                    setmonths(0);

                } else {
                    setmonths(monthDifference);

                }
                if (days === 0) {
                    setTimerDays(0);

                } else {
                    setTimerDays(days);

                }
                if (hours === 0) {
                    setTimerHours(0);

                } else {
                    setTimerHours(hours);

                }
                if (minutes === 0) {
                    setTimerMinutes(0);

                } else {
                    setTimerMinutes(minutes);

                }
                if (seconds === 0) {
                    setTimerSeconds(0);

                } else {
                    setTimerSeconds(seconds);

                }

            }
        }
    }, [list])


    useEffect(() => {

        if (data) {
            if (data.name) {
                setuserFound(data)
            }
        }

    }, [data])


    useEffect(() => {
        if (ispkgbuyed) {
            window.location.reload()
            setispkgbuyed(false)
        }
    }, [ispkgbuyed])


    return (
        <>
            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', zIndex: '1' }}>
                <Grid item xs={12} sm={6} lg={4}>
                    <Box component={Link} to={'/history'}
                        padding='10px 0' display='flex' bgcolor='#ef5350' flexDirection={'column'} justifyContent={'center'} alignItems='center' sx={{ cursor: 'pointer', textDecoration: 'none', position: 'relative' }}>
                        <Typography fontSize='18px' color='white'>Active Package</Typography>
                        <Box display='flex' gap='7px'>
                            {list && list.activePackages.length ? list.activePackages.map((item, i) => (
                                <Box key={i}>
                                    {i < 3 && (
                                        <Typography fontSize='20px' color='white' fontWeight={'600'}>
                                            {
                                                list.activePackages.length === 1 ? `${item.name.length > 15 ? `${item.name.substring(0, 15)}...` : item.name}` :
                                                    list.activePackages.length === 2 ? ` ${i === 0 ? `${item.name.length > 11 ? `${item.name.substring(0, 11)}...` : item.name}` : `+ ${item.name.length > 11 ? `${item.name.substring(0, 11)}...` : item.name}`} ` :
                                                        list.activePackages.length > 2 && ` ${i === 0 ? `${item.name.length > 6 ? `${item.name.substring(0, 6)}...` : item.name}` : `+ ${item.name.length > 6 ? `${item.name.substring(0, 6)}...` : item.name}`} `
                                            }
                                        </Typography>
                                    )}

                                </Box>
                            )) : (
                                <Typography fontSize='20px' color='white' fontWeight={'600'}>
                                    None
                                </Typography>
                            )}
                        </Box>

                        {list && list.activePackages.length > 3 && (
                            <IconButton disableRipple sx={{ color: 'white', position: 'absolute', bottom: '0', right: '0' }}>
                                <KeyboardArrowRightOutlined />
                            </IconButton>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} >
                    <Box component={Link} to='/mychannel'
                        padding='10px 0' display='flex' bgcolor='#ef5350' flexDirection={'column'} justifyContent={'center'} alignItems='center' sx={{ cursor: 'pointer', textDecoration: 'none' }}>
                        <Typography fontSize='18px' color='white'>Channel Name</Typography>
                        <Typography fontSize='20px' color='white' fontWeight={'600'}> {userFound ? userFound.name : 'Not connected yet'} </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <Tooltip title={isFound && 'Countdown will start when your live streaming starts'}>
                        <Box padding='10px 0' display='flex' bgcolor='#ef5350' flexDirection={'column'} justifyContent={'center'} alignItems='center'>
                            <Typography fontSize='18px' color='white'>Expires in</Typography>
                            <Typography fontSize='20px' color='white' fontWeight={'600'}>{months < 10 ? `0${months}` : months}:{timerDays < 10 ? `0${timerDays}` : timerDays}:{timerHours < 10 ? `0${timerHours}` : timerHours}:{timerMinutes < 10 ? `0${timerMinutes}` : timerMinutes}:{timerSeconds < 10 ? `0${timerSeconds}` : timerSeconds}</Typography>
                        </Box>
                    </Tooltip>
                </Grid>
            </Grid>

        </>
    )

}

export default Header