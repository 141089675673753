import { Alert, Backdrop, Box, Button, IconButton, Snackbar, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { motion } from 'framer-motion';
import { ContentCopyOutlined, Menu } from "@mui/icons-material";
import Sidebar from '../../Sidebar/Sidebar'
import { StyledUser } from "../../Users/style";
import { Loader } from "../../../../Components";
import Header from "../../Users/Header/Header";
import { useLocation, useParams } from "react-router-dom";
import { useAdminvideoUrlsQuery, useGetSingleUserQuery } from "../../../../services/userApi";
import Server1 from "./Server1";
import Server2 from "./Server2";
import Server3 from "./Server3";
import Server4 from "./Server4";
import Server5 from "./Server5";


const UserCredentails = () => {

    const [open, setopen] = useState(false)
    // eslint-disable-next-line no-unused-vars
    const [percentage, setpercentage] = useState('')
    const [openServer1, setopenServer1] = useState(false)
    const [mxg, setmxg] = useState('')
    const [openAlert, setOpenAlert] = useState(false);
    const [isDisabled, setisDisabled] = useState(false)
    const [serverName, setserverName] = useState('None')
    const [openServer2, setopenServer2] = useState(false)
    const [openServer3, setopenServer3] = useState(false)
    const [openServer4, setopenServer4] = useState(false)
    const [openServer5, setopenServer5] = useState(false)

    const { state } = useLocation()
    const { id } = useParams()

    const { data, isFetching, refetch } = useAdminvideoUrlsQuery(id)
    const { data: userData, refetch: userRefetch } = useGetSingleUserQuery(id)

    const handleClose = (e) => {
        if (e === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };

    const handleSave = (value) => {

        if (value === 'StreamKey') {
            if (data) {
                navigator.clipboard.writeText(`${data.Urls[0].streamUrl}/${data.Urls[0].streamKey}`)
            }
        } else {
            navigator.clipboard.writeText(value)
        }
        setmxg('Copied to clipboard successfully!')

    }


    useEffect(() => {
        if (userData && userData.user.status === 'Warning') {
            setOpenAlert(true)
            setmxg("You have visited media server from this user but didn't click on start streaming button")
        }
        if (userData && userData.user.serverName) {
            setserverName(userData.user.serverName)
        }

        if (userData && (userData.user.status === 'Finished' || userData.user.status === 'Active')) {
            setisDisabled(true)

        }
    }, [userData])


    return (
        <StyledUser>

            <Snackbar open={openAlert} autoHideDuration={mxg === "You have visited media server from this user but didn't click on start streaming button" ? 6000 : 3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center', }}>

                <Alert onClose={handleClose} severity={mxg === "You have visited media server from this user but didn't click on start streaming button" ? 'warning' : 'success'} sx={{ width: '100%' }} >
                    {mxg}
                </Alert>

            </Snackbar>

            <Box display={{ xs: 'none', md: 'flex' }} >
                <Sidebar />
            </Box>
            <Box display={{ xs: 'flex', md: 'none' }} sx={{ position: 'fixed', top: '10px', left: '10px', zIndex: '1' }}>
                <IconButton onClick={() => setopen(!open)} >
                    <Menu />
                </IconButton>
            </Box>
            <Backdrop open={open} onClick={() => setopen(false)} />

            <Box>
                {open && (
                    <Box>
                        <motion.div

                            animate={{ x: [-300, 0] }}
                            transition={{ duration: 0.85, ease: 'easeOut' }}
                            className='myMenu'

                        >

                            <Sidebar open={open} setopen={setopen} />
                        </motion.div>

                    </Box>
                )}

            </Box>

            <Box >

                <Box marginLeft={{ md: '310px' }} className="user-con" sx={{
                    paddingBottom: '0 !important'
                }}>
                    <Header setpercentage={setpercentage} serverName={serverName} />
                    <Box display='flex' flexDirection={'column'} marginTop='30px' gap='10px' alignItems={'center'}>
                        <Box component='img' src={state.data.avatar.url} height='120px' width='120px' borderRadius={'50%'} sx={{
                            objectFit: 'cover', transition: 'all 1.6s ease-in-out',
                            '&:hover': {
                                transform: 'rotateY(360deg)',
                            }
                        }} />
                        <Typography fontSize='25px' textTransform='capitalize' fontWeight='bold'>{state.data.name}</Typography>
                    </Box>
                    <Box display='flex' justifyContent={'center'} >
                        <Box bgcolor='white' borderRadius={'20px'} padding={{ xs: '20px 10px', sm: '20px' }} marginTop='30px' width={{ xs: '100%', md: '60%' }}>

                            <Typography fontWeight={'bold'} textAlign='center' fontSize={{ xs: '22px', sm: '28px' }} marginBottom='20px'>Streaming Credentials</Typography>
                            {isFetching ? <Loader height={'30vh'} /> : (

                                <>
                                    {data.Urls.length > 0 && (
                                        <Box padding={{ sm: '10px' }} marginTop='10px'>
                                            <Box display='flex' gap={{ xs: '15px', md: '30px' }} justifyContent={'space-between'} alignItems='center'>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: '26px'
                                                }}>
                                                    <Typography fontWeight={'bold'} borderRadius={'20px'} textAlign='center' fontSize='17px' width={'max-content'} padding='3px 12px'> Stream Url </Typography>
                                                    {(() => {
                                                        const arr = [];

                                                        for (let i = 0; i < data.Urls[0].videoUrls.length; i++) {
                                                            arr.push(
                                                                <Typography borderRadius={'20px'} textAlign={'center'} fontWeight={'bold'} fontSize='17px' padding='3px 12px'> {i === 0 ? '1st' : i === 1 ? '2nd' : i === 2 ? '3rd' : i === 3 ? '4th' : `${i + 1}th`} Url</Typography>
                                                            );
                                                        }
                                                        return arr;
                                                    })()}
                                                </Box>
                                                <Box
                                                    sx={{
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        gap: '20px',
                                                    }}
                                                >
                                                    <Box display='flex' alignItems={'center'} gap='10px' >
                                                        <Box width='100%'
                                                            sx={{
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                            }}
                                                        >
                                                            <Typography fontSize={'14px'} backgroundColor='#eeeeee' padding='3px 12px' borderRadius={'20px'}
                                                                sx={{
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                }}
                                                            >{data.Urls[0].streamUrl}/{data.Urls[0].streamKey}</Typography>
                                                        </Box>
                                                        <IconButton onClick={() => {
                                                            setOpenAlert(true)
                                                            handleSave('StreamKey')
                                                        }}>
                                                            <ContentCopyOutlined sx={{ fontSize: "18px" }} />
                                                        </IconButton>
                                                    </Box>
                                                    {data.Urls[0].videoUrls && data.Urls[0].videoUrls.length > 0 && data.Urls[0].videoUrls.map((item, i) => {

                                                        return <Box display='flex' alignItems={'center'} gap='10px' key={i}>
                                                            {item.videoUrl.length === 0 ? (
                                                                <Box>
                                                                    <Typography fontSize={'14px'} backgroundColor='rgba(255,0,0,0.5)' color='white' padding='3px 12px' borderRadius={'20px'}>This url has been left empty by the user</Typography>
                                                                </Box>
                                                            ) : (
                                                                <Box display='flex' alignItems={'center'} gap='10px' width='100%'>
                                                                    <Tooltip title={item.status === 'Active' ? 'Active' : 'Unactive'}>
                                                                        <Box width='100%'
                                                                            sx={{
                                                                                textOverflow: 'ellipsis',
                                                                                whiteSpace: 'nowrap',
                                                                                overflow: 'hidden',
                                                                            }}
                                                                        >
                                                                            <Typography fontSize={'14px'} backgroundColor={item.status === 'Active' ? 'rgba(147, 250, 165,0.5)' : 'rgba(128,0,128,0.2)'} padding='3px 12px' borderRadius={'20px'}
                                                                                sx={{
                                                                                    textOverflow: 'ellipsis',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                }}
                                                                            >{item.videoUrl}</Typography>
                                                                        </Box>
                                                                    </Tooltip>
                                                                    <IconButton onClick={() => {
                                                                        setOpenAlert(true)
                                                                        handleSave(item.videoUrl)
                                                                    }}>
                                                                        <ContentCopyOutlined sx={{ fontSize: "18px" }} />
                                                                    </IconButton>
                                                                </Box>

                                                            )}

                                                        </Box>
                                                    })}
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                                </>
                            )}
                        </Box>
                    </Box>

                    <Box marginTop={'70px'} gap='20px' display='flex' flexWrap='wrap' justifyContent='center'>
                        <Button variant="contained" color='error'
                            sx={{
                                textTransform: 'none',
                                borderRadius: '10px',
                                fontSize: '17px',
                            }}
                            onClick={() => setopenServer1(true)}
                            disabled={isDisabled}
                        >
                            Server 1
                        </Button>
                        <Button variant="contained" color='error'
                            sx={{
                                textTransform: 'none',
                                borderRadius: '10px',
                                fontSize: '17px',
                            }}
                            onClick={() => setopenServer2(true)}
                            disabled={isDisabled}
                        >
                            Server 2
                        </Button>
                        <Button variant="contained" color='error'
                            sx={{
                                textTransform: 'none',
                                borderRadius: '10px',
                                fontSize: '17px',
                            }}
                            onClick={() => setopenServer3(true)}
                            disabled={isDisabled}
                        >
                            Server 3
                        </Button>
                        <Button variant="contained" color='error'
                            sx={{
                                textTransform: 'none',
                                borderRadius: '10px',
                                fontSize: '17px',
                            }}
                            onClick={() => setopenServer4(true)}
                            disabled={isDisabled}
                        >
                            Server 4
                        </Button>
                        <Button variant="contained" color='error'
                            sx={{
                                textTransform: 'none',
                                borderRadius: '10px',
                                fontSize: '17px',
                            }}
                            disabled={isDisabled}
                            onClick={() => setopenServer5(true)}
                        >
                            Server 5
                        </Button>
                    </Box>
                </Box>
            </Box>

            <Server1 openServer1={openServer1} setopenServer1={setopenServer1} setOpenAlert={setOpenAlert} setmxg={setmxg} refetch={refetch} setisDisabled={setisDisabled} userRefetch={userRefetch} />
            <Server2 openServer2={openServer2} setopenServer2={setopenServer2} setOpenAlert={setOpenAlert} setmxg={setmxg} refetch={refetch} setisDisabled={setisDisabled} userRefetch={userRefetch} />          
            <Server3 openServer3={openServer3} setopenServer3={setopenServer3} setOpenAlert={setOpenAlert} setmxg={setmxg} refetch={refetch} setisDisabled={setisDisabled} userRefetch={userRefetch} />
            <Server4 openServer4={openServer4} setopenServer4={setopenServer4} setOpenAlert={setOpenAlert} setmxg={setmxg} refetch={refetch} setisDisabled={setisDisabled} userRefetch={userRefetch} />
            <Server5 openServer5={openServer5} setopenServer5={setopenServer5} setOpenAlert={setOpenAlert} setmxg={setmxg} refetch={refetch} setisDisabled={setisDisabled} userRefetch={userRefetch} />

        </StyledUser >
    )
}
export default UserCredentails;