import { Box, Grid, IconButton, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useAdminUserActivePackagesQuery, useAdminUserInfoQuery } from '../../../../services/userApi'
import { Link, useLocation, useParams } from 'react-router-dom'
import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import StatesContext from '../../../../context/StatesContext';
import moment from 'moment'


const Header = ({ setpercentage, packageName, myPackageExpire, serverName }) => {

    const context = useContext(StatesContext)
    const { isTimeUpdated, setisTimeUpdated } = context

    const { id } = useParams()

    const { data } = useAdminUserInfoQuery(id)
    const { data: list, refetch } = useAdminUserActivePackagesQuery(id)

    const [userFound, setuserFound] = useState('')
    const [timerHours, setTimerHours] = useState(0)
    const [timerMinutes, setTimerMinutes] = useState(0)
    const [timerSeconds, setTimerSeconds] = useState(0)
    const [timerDays, setTimerDays] = useState(0)
    const [expiryTime, setexpiryTime] = useState(0)
    const [months, setmonths] = useState(0)
    const [packageDetailPage, setpackageDetailPage] = useState(false)

    const location = useLocation()
    let x = location.pathname.split('/')[3]


    let interval;

    const startTimer = () => {

        let countDownDate

        if (packageDetailPage) {
            countDownDate = new Date(myPackageExpire).getTime();
        } else {
            countDownDate = new Date(list && list.totalExpireTime && list.totalExpireTime).getTime();
        }


        interval = setInterval(() => {
            const now = new Date().getTime();

            const distance = countDownDate - now;

            let monthDifference = moment(countDownDate).diff(new Date(), 'months', true);
            monthDifference = monthDifference.toString().split('.')[0]
            monthDifference = Number(monthDifference)

            const days = Math.floor(distance / (24 * 60 * 60 * 1000)) % 30
            const hours = Math.floor(
                (distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
            );
            const minutes = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
            const seconds = Math.floor((distance % (60 * 1000)) / 1000);

            if (distance < 0) {
                // Stop Timer
                clearInterval(interval.current);

            } else {
                // Update Timer
                setTimerDays(days);
                setTimerHours(hours);
                setTimerMinutes(minutes);
                setTimerSeconds(seconds);
                setmonths(monthDifference)

            }
        }, 1000);

    };

    useEffect(() => {
        if (packageDetailPage) {
            if (new Date(myPackageExpire).getTime() > 0) {
                startTimer();
            }
        } else {
            if (expiryTime > 0) {
                startTimer();
            }
        }

    });

    useEffect(() => {
        if (!packageDetailPage && list && list.isExpireTime > 0) {

            setexpiryTime(list.isExpireTime)

            let timeDiff = 0

            list.activePackages.forEach((item) => {

                if (item.name === 'Trial') {
                    timeDiff = timeDiff + 43200000

                } else {

                    timeDiff = timeDiff + (item.days * 86400000)

                }
            })

           let mydate = new Date(list.totalStartTime + timeDiff)

            var startDate = list.totalStartTime;
            var endDate = new Date(mydate).getTime()
            var todayDate = new Date().getTime();

            // Get the total possible timestamp value
            var total = endDate - startDate;

            // Get the current value
            var current = todayDate - startDate;

            // Get the percentage
            let x = (current / total) * 100
            x = x.toString()
            x = x.split('.')[0]
            x = Number(x)
            if (x <= 100) {
                setpercentage(x)
            } else {
                setpercentage(100)
            }
        }

        if (data && list && list.activePackages.length) {

            let isActive = list.activePackages.find(item => item.expiresAt)
            if (!isActive) {
                let diff = 0
                list.activePackages.forEach((item => {
                    if (item.days === 0.2) {

                        diff = diff + 43200000

                    } else {

                        diff = diff + (item.days * 86400000)

                    }
                }))
                diff = diff + list.adminTime
                let countDownDate = new Date(new Date().getTime() + diff).getTime()
                const now = new Date().getTime();

                let monthDifference = moment(new Date(new Date(new Date().getTime() + diff))).diff(new Date(), 'months', true);
                monthDifference = monthDifference.toString().split('.')[0]
                monthDifference = Number(monthDifference)

                const distance = countDownDate - now;
                const days = Math.floor(distance / (24 * 60 * 60 * 1000)) % 30
                const hours = Math.floor(
                    (distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
                );
                const minutes = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
                const seconds = Math.floor((distance % (60 * 1000)) / 1000);

                if (days === 0) {
                    setTimerDays(0);

                } else {
                    setTimerDays(days);

                }
                if (monthDifference === 0) {
                    setmonths(0);

                } else {
                    setmonths(monthDifference);

                }
                if (hours === 0) {
                    setTimerHours(0);

                } else {
                    setTimerHours(hours);

                }
                if (minutes === 0) {
                    setTimerMinutes(0);

                } else {
                    setTimerMinutes(minutes);

                }
                if (seconds === 0) {
                    setTimerSeconds(0);

                } else {
                    setTimerSeconds(seconds);

                }

            }
        }

    }, [list, data])


    useEffect(() => {

        if (data) {
            if (data.name) {
                setuserFound(data)
            }
        }

    }, [data])


    useEffect(() => {

        if (x === 'package') {
            setpackageDetailPage(true)
        }

    }, [])

    useEffect(() => {
        if (isTimeUpdated) {
            setisTimeUpdated(false)
            window.location.reload()
        }
    }, [isTimeUpdated])


    return (
        <>
            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Grid item xs={12} sm={6} lg={4}>
                    <Box component={!packageDetailPage ? Link : 'div'} to={`/admin/user/packages/${id}`}
                        padding='10px 0' display='flex' bgcolor='#ef5350' flexDirection={'column'} justifyContent={'center'} alignItems='center' sx={{ cursor: 'pointer', textDecoration: 'none', position: 'relative' }}>
                        <Typography fontSize='18px' color='white'>{packageDetailPage ? 'Package Name' : 'Active Packages'}</Typography>
                        {packageDetailPage ? (
                            <Box display='flex' gap='7px'>

                                <Box>
                                    <Typography fontSize='20px' color='white' fontWeight={'600'}>
                                        {packageName}
                                    </Typography>
                                </Box>

                            </Box>
                        ) : (
                            <Box display='flex' gap='7px'>

                                {list && list.activePackages.length ? list.activePackages.map((item, i) => (
                                    <Box key={i}>
                                        {i < 3 && (
                                            <Typography fontSize='20px' color='white' fontWeight={'600'}>
                                                {
                                                    list.activePackages.length === 1 ? `${item.name.length > 15 ? `${item.name.substring(0, 15)}...` : item.name}` :
                                                        list.activePackages.length === 2 ? ` ${i === 0 ? `${item.name.length > 11 ? `${item.name.substring(0, 11)}...` : item.name}` : `+ ${item.name.length > 11 ? `${item.name.substring(0, 11)}...` : item.name}`} ` :
                                                            list.activePackages.length > 2 && ` ${i === 0 ? `${item.name.length > 6 ? `${item.name.substring(0, 6)}...` : item.name}` : `+ ${item.name.length > 6 ? `${item.name.substring(0, 6)}...` : item.name}`} `
                                                }
                                            </Typography>
                                        )}

                                    </Box>
                                )) : (
                                    <Typography fontSize='20px' color='white' fontWeight={'600'} >
                                        None
                                    </Typography>
                                )}
                            </Box>
                        )}


                        {!packageDetailPage && list && list.activePackages.length > 3 && (
                            <IconButton disableRipple sx={{ color: 'white', position: 'absolute', bottom: '0', right: '0' }}>
                                <KeyboardArrowRightOutlined />
                            </IconButton>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} >
                    {serverName ? (
                        <Box
                            padding='10px 0' display='flex' bgcolor='#ef5350' flexDirection={'column'} justifyContent={'center'} alignItems='center' sx={{ cursor: 'pointer', textDecoration: 'none' }}>
                            <Typography fontSize='18px' color='white'>Server Name</Typography>
                            <Typography fontSize='20px' color='white' fontWeight={'600'}>{serverName}</Typography>
                        </Box>
                    ) : (

                        <Box
                            padding='10px 0' display='flex' bgcolor='#ef5350' flexDirection={'column'} justifyContent={'center'} alignItems='center' sx={{ cursor: 'pointer', textDecoration: 'none' }}>
                            <Typography fontSize='18px' color='white'>Channel Name</Typography>
                            <Typography fontSize='20px' color='white' fontWeight={'600'}> {userFound ? userFound.name : 'Not connected yet'} </Typography>
                        </Box>
                    )}
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <Box padding='10px 0' display='flex' bgcolor='#ef5350' flexDirection={'column'} justifyContent={'center'} alignItems='center'>
                        <Typography fontSize='18px' color='white'>Expires in</Typography>
                        <Typography fontSize='20px' color='white' fontWeight={'600'}>{months < 10 ? `0${months}` : months}:{timerDays < 10 ? `0${timerDays}` : timerDays}:{timerHours < 10 ? `0${timerHours}` : timerHours}:{timerMinutes < 10 ? `0${timerMinutes}` : timerMinutes}:{timerSeconds}</Typography>
                    </Box>
                </Grid>
            </Grid>

        </>
    )

}

export default Header