import { Alert, Box, Button, CircularProgress, Snackbar, Step, StepConnector, stepConnectorClasses, StepLabel, Stepper, styled, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import logo from '../../../assets/logo.jpeg'
import { StyledAuth } from '../style'
import StatesContext from '../../../context/StatesContext'
import { useResendOtpMutation, useVerifyOtpMutation } from "../../../services/userApi";
import { Link, useNavigate, useParams } from 'react-router-dom'
import { AttachMoneyOutlined, DoneAllOutlined, GroupAdd, LinkOutlined, TrendingUpRounded } from '@mui/icons-material'


const Verification = () => {

    const { id } = useParams();

    const context = useContext(StatesContext);
    const { setUserInfo, useremail, setisUserUpdate } = context;

    const [email, setemail] = useState('')
    const [otp, setotp] = useState();
    const [openAlert, setOpenAlert] = useState(false);


    const navigate = useNavigate()

    const [verify, response] = useVerifyOtpMutation();
    const [send, res] = useResendOtpMutation()

    const handleSubmit = (e) => {

        e.preventDefault();

        const myForm = new FormData();

        myForm.set("userId", id);
        myForm.set("otp", otp);

        verify({ id, otp });
    };
    const handleResend = (e) => {

        e.preventDefault();

        send({ id, useremail });
    };

    const handleClose = (e) => {
        if (e === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };

    useEffect(() => {
        if (response.status === "fulfilled") {
            setUserInfo(response.data.user);
            setisUserUpdate(true)

        }
    }, [response]);
    useEffect(() => {
        if (res.status === "fulfilled") {
            setOpenAlert(true)
        }
    }, [res]);




    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 40,
            [theme.breakpoints.down('md')]: {
                top: 22
            }
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundColor: 'white'
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundColor: 'white'
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 4,
            border: 0,
            backgroundColor:
                theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderRadius: 1,
            [theme.breakpoints.down('md')]: {
                height: 3
            }
        },
    }));

    const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 80,
        height: 80,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
            backgroundImage:
                'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
            backgroundImage:
                'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        }),
        transition: 'all 1.6s ease-in-out',
        '&:hover': {
            transform: 'rotateY(360deg)',
        },
        [theme.breakpoints.down('md')]: {
            width: 50,
            height: 50,
        },

    }));

    function ColorlibStepIcon(props) {
        const { active, completed, className } = props;

        const icons = {
            1: <GroupAdd sx={{ fontSize: { md: '40px' } }} />,
            2: <AttachMoneyOutlined sx={{ fontSize: { md: '40px' } }} />,
            3: <LinkOutlined sx={{ fontSize: { md: '40px' } }} />,
            4: <DoneAllOutlined sx={{ fontSize: { md: '40px' } }} />,
        };

        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    const steps = ['Create an account', 'Buy Package', 'Give stream Key & Live Video Url', 'You are ready to stream'];


    return (
        <StyledAuth>

            <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center', }}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }} >
                    Verification code has been resesnd successfully
                </Alert>
            </Snackbar>

            <Box flex={{ md: '0.55', lg: '0.35' }}
                bgcolor='white'
                borderRadius={'13px'}
                boxShadow='0 0 10px rgba(0,0,0,0.1)'
                display='flex'
                justifyContent={'center'}
                alignItems='center'
                padding='40px 0'
                marginTop={{ xs: '30px', md: '0' }}
            >
                <Box display='flex' gap='10px' flexDirection={'column'} width='100%' alignItems='center'>
                    <Box component={Link} to='/' sx={{ cursor: 'pointer', width: '100px', borderRadius: '50px' }}>
                        <Box component='img' src={logo} width='100px' borderRadius={'50px'} sx={{
                            objectFit: 'center',
                            transition: 'all 1.6s ease-in-out',
                            '&:hover': {
                                transform: 'rotateY(360deg)',
                            }
                        }} />
                    </Box>
                    <Box padding='0 20px'>
                        <Typography textAlign={'center'} fontWeight={'bold'} marginTop={'15px'} fontSize='25px'>Verification</Typography>
                        <Typography textAlign={'center'} fontSize='14px' color='#757575'>We just sent your authentication code via email to {useremail}.</Typography>
                    </Box>
                    <Box component={'form'} onSubmit={handleSubmit} width='86%' display='flex' gap='10px' marginTop={'15px'} flexDirection={'column'} alignItems='center'>
                        <TextField
                            placeholder='Enter Verification Code'
                            className='email-feild'
                            required
                            fullWidth
                            type={'number'}
                            onChange={(e) => setotp(e.target.value)}
                            InputProps={{
                                disableUnderline: true,
                                style: {
                                    fontSize: 15,
                                }
                            }}
                            variant="standard"
                        />

                        <Box display='flex' justifyContent={'flex-end'} width='100%'>
                            <Button variant='contained' size='small'
                                sx={{
                                    textTransform: 'none',
                                    borderRadius: '10px',
                                    fontSize: '14px',
                                    backgroundColor: '#e0e0e0',
                                    color: 'black',
                                    fontWeight: 'bold',
                                    '&:hover': {
                                        backgroundColor: '#e0e0e0',
                                        color: 'black',
                                    }
                                }}
                                onClick={handleResend}
                            >
                                {res.isLoading ? <CircularProgress size={25} /> : 'Resend'}
                            </Button>
                        </Box>

                        {response.error && (

                            <Box display='flex' justifyContent={'flex-start'} width='100%'>
                                <Typography color='red' fontSize='15px' fontWeight={'500'}>
                                    {response.error.data.message}
                                </Typography>
                            </Box>

                        )}
                        <Button type='submit' variant='contained' sx={{ width: '100%', textTransform: 'none', marginTop: '15px', borderRadius: '10px' }}>
                            {response.isLoading ? <CircularProgress sx={{color : 'white'}}/> : 'Confirm'}
                        </Button>
                    </Box>
                </Box>
            </Box>



            <Box flex={'1'}
                display='flex'
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems='center'
                padding={{ md: '0 2em' }}
            >
                <Typography fontWeight={'bold'} color='white' textAlign='center' fontSize={{ xs: '20px', sm: '30px', md: '35px' }}>Unlock the new Technique of Growing <br /> Subscribers & Earn 13x More</Typography>
                <Box marginTop={{ xs: '30px', sm: '40px', md: '60px' }}>
                    <Stepper alternativeLabel activeStep={3} connector={<ColorlibConnector />}>
                        {steps.map((label) => {
                            let x
                            if (label === 'Create an account') {
                                x = '/register'
                            } else if (label === 'Buy Package') {
                                x = '/#plans'
                            } else {
                                x = ''
                            }
                            return <Step key={label}
                                component={x && Link}
                                to={x}
                                sx={{ textDecoration: 'none' }}
                            >
                                <StepLabel
                                    StepIconComponent={ColorlibStepIcon}
                                    sx={{
                                        '.MuiStepLabel-label': {
                                            fontSize: { xs: '10px', sm: '17px' },
                                            fontWeight: 'bold !important',
                                            color : 'white !important'

                                        }
                                    }}
                                >{label}</StepLabel>
                            </Step>
                        })}
                    </Stepper>
                </Box>
            </Box>
        </StyledAuth>
    )
}

export default Verification