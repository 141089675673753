import { Backdrop, Box, FormControl, IconButton, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import React, { useState } from "react";
import { motion } from 'framer-motion';
import { Menu, ReadMoreOutlined } from "@mui/icons-material";
import { useAllusersNotificationsQuery } from "../../../services/userApi";
import Sidebar from '../Sidebar/Sidebar'
import { useNavigate } from "react-router-dom";
import { RegisteredUserCon, StyledSelect, StyledUser } from "../Users/style";
import { Loader } from "../../../Components";
import { NoProductHeading } from "../../User/History/style";
import { StyledPagination } from "../../User/Dashboard/style";

const Notifications = () => {

    const [currentPage, setcurrentPage] = useState(1)
    const [open, setopen] = useState(false)
    const [status, setStatus] = useState('')
    const [selectedValue, setselectedValue] = useState('All')

    const { data, isFetching } = useAllusersNotificationsQuery({ status, currentPage })

    const navigate = useNavigate()

    const handleChange = (e) => {

        setselectedValue(e.target.value)


        if (e.target.value === 'All') {

            setStatus('')
            setcurrentPage(1)


        } else {
            setStatus(e.target.value);
            setcurrentPage(1)
        }


    };

    return (
        <StyledUser>

            <Box display={{ xs: 'none', md: 'flex' }} >
                <Sidebar />
            </Box>
            <Box display={{ xs: 'flex', md: 'none' }} sx={{ position: 'fixed', top: '10px', left: '10px', zIndex: '1' }}>
                <IconButton onClick={() => setopen(!open)} >
                    <Menu />
                </IconButton>
            </Box>
            <Backdrop open={open} onClick={() => setopen(false)} />

            <Box>
                {open && (
                    <Box>
                        <motion.div

                            animate={{ x: [-300, 0] }}
                            transition={{ duration: 0.85, ease: 'easeOut' }}
                            className='myMenu'

                        >

                            <Sidebar open={open} setopen={setopen} />
                        </motion.div>

                    </Box>
                )}

            </Box>

            <Box >

                <Box marginLeft={{ md: '310px' }} className="user-con">
                    <Box bgcolor={'white'} borderRadius='35px' padding='1rem' minHeight={{ md: '80vh', lg: '88vh' }}>
                        <Box display='flex' justifyContent={'space-between'}>
                            <Typography fontWeight={'bold'} fontSize={{ xs: '25px', md: '35px' }} >Streaming Credentials</Typography>
                            <StyledSelect>
                                <FormControl focused={false} sx={{ width: { xs: '115px', sm: '135px' }, borderRadius: '20px !important' }}>
                                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        required
                                        name='Status'
                                        value={selectedValue}
                                        label="Status"
                                        onChange={handleChange}
                                        InputProps={{
                                            disableUnderline: true,
                                            style: {
                                                borderRadius: '20px'
                                            }
                                        }}
                                        MenuProps={{ disableScrollLock: true }}
                                    >
                                        <MenuItem value={'Active'}>Active</MenuItem>
                                        <MenuItem value={'Pending'}>Pending</MenuItem>
                                        <MenuItem value={'Processing'}>Updating</MenuItem>
                                        <MenuItem value={'Finished'}>Finished</MenuItem>
                                        <MenuItem value={'Warning'}>Warning</MenuItem>
                                        <MenuItem value={'All'}>All</MenuItem>

                                    </Select>
                                </FormControl>
                            </StyledSelect>
                        </Box>
                        <Box>
                            {isFetching ? <Loader height={'75vh'} /> : (
                                <Box>
                                    <Box display='flex' flexDirection={'column'} gap='1rem' marginTop={'10px'} >
                                        {data.allUsers.length > 0 && data.allUsers.map((item, i) => {
                                            let fullTime = new Date(item.modifiedAt)
                                            fullTime = fullTime.toLocaleTimeString()

                                            let firstTime = fullTime.slice(0, 5)
                                            let zone = fullTime.slice(8, 11)
                                            let x = firstTime + zone
                                            return <RegisteredUserCon key={i} bgcolor={item.status === 'Warning' ? 'rgba(255,0,0,0.2)' : '#F1EBEB'}>
                                                <Box display='flex' alignItems={'center'} gap={{ xs: '20px', sm: '30px' }}>
                                                    <Box component={'img'} src={item.avatar.url} height={{ xs: '70px', sm: '90px' }} width={{ xs: '70px', sm: '90px' }} borderRadius={'50px'} sx={{ objectFit: 'cover' }} />
                                                    <Box display='flex' flexDirection='column'>
                                                        <Box>
                                                            <Typography fontWeight={'bold'} fontSize={{ xs: '20px', sm: '25px' }}>{item.name}</Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography fontWeight={'bold'} fontSize={{ xs: '15px', sm: '20px' }}>{item.email}</Typography>
                                                        </Box>
                                                        <Box display={'flex'} gap='10px'>
                                                            <Typography fontWeight={'bold'} fontSize={{ xs: '15px', sm: '20px' }}>Status:</Typography>
                                                            <Typography fontWeight={'bold'} fontSize={{ xs: '15px', sm: '20px' }} color={item.status === 'Warning' ? 'yellow' : item.status === 'Pending' || item.status === 'Processing' ? '#AA336A' : item.status === 'Finished' || item.status === 'Not Connected' ? 'red' : 'green'}
                                                            >{item.status === 'Processing' ? 'Updating' : item.status}</Typography>
                                                        </Box>
                                                        <Box display={'flex'} gap='10px'>
                                                            <Typography fontWeight={'bold'} fontSize={{ xs: '15px', sm: '20px' }}>Time:</Typography>
                                                            <Typography fontWeight={'bold'} fontSize={{ xs: '15px', sm: '20px' }}
                                                            >{new Date(item.modifiedAt).toDateString()}, {x}</Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <IconButton onClick={() => navigate(`/admin/user/credentials/${item._id}`, { state: { data: item } })} disabled={item.status === 'Not Connected' && true}>
                                                        <ReadMoreOutlined />
                                                    </IconButton>
                                                </Box>
                                            </RegisteredUserCon>

                                        })}
                                    </Box>

                                </Box>
                            )}

                            {!isFetching && data && data.allUsers.length === 0 && (
                                <Box display={'flex'} justifyContent='center' marginTop={{ md: '5em' }}>
                                    <NoProductHeading>No User Found</NoProductHeading>
                                </Box>
                            )}

                            {data && data.resultPerPage < data.filteredUsersCount && (

                                <StyledPagination color='primary' size='large'
                                    count={Math.ceil(data.filteredUsersCount / data.resultPerPage)}
                                    page={currentPage}
                                    shape='rounded'
                                    onChange={(event, value) => setcurrentPage(value)}
                                    showLastButton showFirstButton hideNextButton hidePrevButton
                                    onClick={() => window.scrollTo(0, 0)}

                                />
                            )}

                        </Box>
                    </Box>
                </Box>
            </Box>
        </StyledUser>
    )
}
export default Notifications;