import React, { useContext, useEffect } from "react";
import DashboardIcon from '@mui/icons-material/Dashboard';

import { SidebarConatiner } from './style'
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material'
import { NavLink, useNavigate } from 'react-router-dom'
import { Logout, YouTube, AttachMoney, HistoryOutlined, Settings, Menu, Create, CloudUploadOutlined, Circle, PendingActionsOutlined } from '@mui/icons-material'
import { useLogoutMutation } from '../../../services/userApi'
import StatesContext from '../../../context/StatesContext'

const Sidebar = ({ open, setopen, largeOpen, setlargeOpen, setanimation, setsidebarAnimation, PackageDetail }) => {


    const context = useContext(StatesContext);
    const { setUserInfo, userInfo, uploading } = context;
    const [logout, logoutResponse] = useLogoutMutation()

    const navigate = useNavigate()

    const handleOpen = () => {
        if (open) setopen(false)
    }

    const myRoutes = [
        {
            title: `Dashboard`,
            icon: <DashboardIcon sx={{ fontSize: '30px' }} />,
            route: '/dashboard'
        },
        {
            title: `Your Channel`,
            icon: <YouTube sx={{ fontSize: '30px' }} />,
            route: '/mychannel'
        },
        {
            title: `Upload Video`,
            icon: <CloudUploadOutlined sx={{ fontSize: '30px' }} />,
            route: '/video/upload'
        },
        {
            title: `Plans`,
            icon: <AttachMoney sx={{ fontSize: '30px' }} />,
            route: '/pricing'
        },
        {
            title: `Make Plans`,
            icon: <Create sx={{ fontSize: '30px' }} />,
            route: '/plans/create'
        },
        {
            title: `Active Packages`,
            icon: <PendingActionsOutlined sx={{ fontSize: '30px' }} />,
            route: '/packages/active'
        },
        {
            title: `History`,
            icon: <HistoryOutlined sx={{ fontSize: '30px' }} />,
            route: '/history'
        },
        {
            title: `Settings`,
            icon: <Settings sx={{ fontSize: '30px' }} />,
            route: '/settings'
        },

    ]

    const handelLogout = (e) => {
        e.preventDefault()
        logout()
    }

    useEffect(() => {

        if (logoutResponse.status === 'fulfilled') {
            setUserInfo('')
            navigate('/login')
        }


    }, [logoutResponse])

    return (

        <SidebarConatiner>

            <Box display={{ xs: 'none', md: 'flex' }} marginTop='3px' marginRight={'3px'} justifyContent='flex-end'>
                <IconButton onClick={() => {
                    setanimation([300, 0])
                    setsidebarAnimation([0, -300])
                    setlargeOpen(false)
                }}>
                    <Menu />
                </IconButton>
            </Box>

            <Box display='flex' gap='10px' flexDirection={'column'} alignItems={'center'} justifyContent='center' marginTop={{ xs: '0', md: '20px' }} >
                <Box height='160px' width='160px' sx={{ borderRadius: '50%', objectFit: 'cover', borderStyle: 'outset', borderWidth: '4px', borderBottomColor: '#EDB79F', borderLeftColor: '#EDB79F', borderRightColor: '#63E2C6', borderTopColor: '#63E2C6' }}>
                    <Box component='img' src={userInfo && userInfo.avatar.url} padding='7px' height='100%' width='100%' sx={{
                        borderRadius: '50%', objectFit: 'cover',
                        transition: 'all 1.6s ease-in-out',
                        '&:hover': {
                            transform: 'rotateY(360deg)',
                        }
                    }} />
                </Box>
                <Typography fontWeight={'500'} lineHeight='30px' fontSize='20px'>Hello,</Typography>
                <Typography fontWeight={'bold'} textTransform='capitalize' lineHeight='30px' fontSize='25px'>{userInfo && userInfo.name}</Typography>
            </Box>

            <Box display='flex' flexDirection={'column'} gap='10px' marginBottom={'1rem'} marginTop={{ xs: '20px' }} sx={{ overflowY: 'auto' }} padding='0 0.7rem'>
                {myRoutes.map((item, i) => (
                    <NavLink key={i}
                        to={`${item.route}`}
                        className={({ isActive }) => (isActive || PackageDetail && item.title === 'History' ? 'active-link' : 'unactive-link')}
                        onClick={handleOpen}
                    >
                        {item.icon}
                        <Typography>{item.title}</Typography>
                        {item.title === 'Upload Video' && uploading && (
                            <Circle sx={{ fontSize: '15px' }} />
                        )}

                    </NavLink>
                ))}
                <Box className="unactive-link">
                    <Logout sx={{ fontSize: '30px' }} />
                    <Typography component={'li'} sx={{ listStyleType: 'none' }} onClick={handelLogout}>Logout</Typography>
                </Box>
            </Box>
        </SidebarConatiner >
    )
}
export default Sidebar;