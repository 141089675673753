import { Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'

const Loader = ({ height, text }) => {

  return (
    <Box display={'flex'} justifyContent='center'
      alignItems={'center'} height={height && height} flexDirection='column' gap='20px'>

      <CircularProgress size={80} />
      {text && (
        <Typography>{text}</Typography>
      )}
    </Box>
  )
}

export default Loader