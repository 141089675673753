import { Box, styled } from "@mui/material";


const StyledCheckout = styled(Box)(({ theme }) => ({

    '.btn':{
        color : 'white',
        borderRadius : '10px'
    }
}))



export {

    StyledCheckout

}