import { Alert, Backdrop, Box, Button, CircularProgress, Dialog, IconButton, InputAdornment, Snackbar, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion';
import Sidebar from '../Sidebar/Sidebar';
import { StyledNavbar } from '../Dashboard/style';
import { Cancel, DescriptionOutlined, InfoOutlined, InsertLink, KeyOutlined, LinkOutlined, MailOutlineOutlined, Menu, TitleOutlined, VisibilityOff } from '@mui/icons-material';
import Header from '../Header/Header';
import img from '../../../assets/youtube.png'
import { useAddStreamMutation, useDisconnectMutation, useLiveUrlMutation, useLoginUserMutation, useSaveStreamKeyMutation, useUpdatevideoUrlsMutation, useVideoUrlsQuery } from '../../../services/userApi';
import StatesContext from '../../../context/StatesContext';
import { Link, Navigate, useNavigate } from 'react-router-dom';

const MyChannel = () => {

  const context = useContext(StatesContext)
  const { userFound, setuserFound, userInfo, setUserInfo, setisUserUpdate, setisVideoAdded } = context

  const [open, setopen] = useState(false)
  const [url, seturl] = useState('')
  const [openAlert, setOpenAlert] = useState(false);
  const [openAdd, setopenAdd] = useState(false)
  const [openLive, setopenLive] = useState(false)
  const [title, settitle] = useState('')
  const [desc, setdesc] = useState('')
  const [mxg, setmxg] = useState('')
  const [myVideoUrl, setmyVideoUrl] = useState([])
  const [streamKey, setstreamKey] = useState('')
  const [error, seterror] = useState('')
  const [editVideo, seteditVideo] = useState(false)
  const [updateUrl, setupdateUrl] = useState([])
  const [showIcon, setshowIcon] = useState(false)
  const [largeOpen, setlargeOpen] = useState(true)
  const [animation, setanimation] = useState([])
  const [sidebarAnimation, setsidebarAnimation] = useState()
  const [isActive, setisActive] = useState(false)
  const [totalCount, settotalCount] = useState()
  const [streamUrl, setstreamUrl] = useState('')
  const [streamSample, setstreamSample] = useState(false)
  const [urlExists, seturlExists] = useState(false)
  const [liveUrl, setliveUrl] = useState('')

  const [loginUser, responseInfo] = useLoginUserMutation()
  const [disconnect, res] = useDisconnectMutation()
  const [addStream, response] = useAddStreamMutation()
  const [saveStream, streamRes] = useSaveStreamKeyMutation()
  const { data, refetch } = useVideoUrlsQuery()
  const [updateUrlVideo, updateRes] = useUpdatevideoUrlsMutation()
  const [liveUrlSave, UrlRes] = useLiveUrlMutation()

  const navigate = useNavigate()

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setisActive(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }


  const wrapperRef = useRef(null)

  useOutsideAlerter(wrapperRef);

  const handleClose = (e) => {
    if (e === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  const handleClick = (e) => {
    e.preventDefault()
    addStream({ title, desc })
  }

  const handleLiveUrl = (e) => {
    e.preventDefault()
    liveUrlSave(liveUrl)
  }


  const handleSaveStream = (e) => {
    e.preventDefault()
    seterror('')
    if (streamKey.length > 22) {
      saveStream({ streamKey, myVideoUrl, streamUrl })
    } else {
      seterror('Your stream key must be 24 characters long')
    }
  }

  const handleUpdateUrl = (e) => {
    e.preventDefault()
    updateUrlVideo(updateUrl)
  }

  useEffect(() => {

    loginUser()

    if (userInfo) {


      let x = []

      if (userInfo.videosCount > 8) {
        settotalCount(8)
        for (let index = 0; index < 8; index++) {
          x.push({ videoUrl: '' })
        }
      } else {
        settotalCount(userInfo.videosCount)

        for (let index = 0; index < userInfo.videosCount; index++) {
          x.push({ videoUrl: '' })
        }
      }


      setmyVideoUrl(x)
    }

  }, [userInfo])

  useEffect(() => {

    if (data && data.Urls.length) {

      let x = []
      let index
      for (index = 0; index < data.Urls[0].videoUrls.length; index++) {
        x.push({ videoUrl: data.Urls[0].videoUrls[index].videoUrl })
      }

      if (userInfo.videosCount > 8) {

        for (let index = 0; index < 8; index++) {
          if (data.Urls[0].videoUrls.length - 1 < index)
            x.push({ videoUrl: '' })
        }
      } else {
        for (let index = 0; index < userInfo.videosCount; index++) {
          if (data.Urls[0].videoUrls.length - 1 < index)
            x.push({ videoUrl: '' })
        }
      }
      setupdateUrl(x)
    }

  }, [data])

  useEffect(() => {

    if (responseInfo.status === 'fulfilled') {
      seturl(responseInfo.data.url)
    }

  }, [responseInfo])

  useEffect(() => {

    if (res.status === 'fulfilled') {
      setuserFound(null)
      setOpenAlert(true)
      setmxg('Your Channel has been disconnected.')
    }
    if (res.error) {
      setOpenAlert(true)
      setmxg('You can only disconnect your channel when your live streaming will end')
    }

  }, [res])

  useEffect(() => {
    if (response.status === 'fulfilled') {
      setOpenAlert(true)
      setopenAdd(false)
      setisVideoAdded(true)
      setmxg('Your live streaming has been added in your channel.')

    }
  }, [response])

  useEffect(() => {
    if (streamRes.status === 'fulfilled') {
      setOpenAlert(true)
      setopenLive(false)
      setmxg('Soon your streaming will start after reviewing by our team.')
      refetch()
      setisUserUpdate(true)
    }
  }, [streamRes])

  useEffect(() => {

    if (updateRes.status === 'fulfilled') {

      setOpenAlert(true)
      setmxg('Your video url has been updated.')
      seteditVideo(false)
      refetch()

    }
    if (UrlRes.status === 'fulfilled') {

      setOpenAlert(true)
      setmxg('Your response has been submitted.')
      setUserInfo(UrlRes.data.user)

    }

  }, [updateRes, UrlRes])

  const handleVideoChange = (value, itemIndex) => {

    setmyVideoUrl(existingItems => {
      return [
        ...existingItems.slice(0, itemIndex),
        {
          // spread all the other items in the object and update only the score
          ...existingItems[itemIndex],
          videoUrl: value
        },
        ...existingItems.slice(itemIndex + 1),
      ]

    })
  }
  const handleVideoUpdateChange = (value, itemIndex) => {

    setupdateUrl(existingItems => {
      return [
        ...existingItems.slice(0, itemIndex),
        {
          // spread all the other items in the object and update only the score
          ...existingItems[itemIndex],
          videoUrl: value
        },
        ...existingItems.slice(itemIndex + 1),
      ]

    })
  }

  return (

    <StyledNavbar padding={largeOpen && '1.5rem'} >

      <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center', }}>
        <Alert onClose={handleClose} severity={res.error ? 'error' : 'success'} sx={{ width: '100%' }} >
          {mxg}
        </Alert>
      </Snackbar>

      <Backdrop open={open} onClick={() => setopen(false)} sx={{ zIndex: '1' }} />

      <Box display={{ xs: 'flex', md: 'none', position: 'fixed', top: '0', left: '0', zIndex: '1' }} sx={{ padding: '15px' }}>
        <IconButton onClick={() => setopen(!open)} sx={{ color: 'black' }}>
          <Menu />
        </IconButton>
      </Box>

      <Box>
        {open && (
          <Box>
            <motion.div

              animate={{ x: [-300, 0] }}
              transition={{ duration: 0.85, ease: 'easeOut' }}
              className='myMenu'
            >
              <Sidebar open={open} setopen={setopen} />
            </motion.div>

          </Box>
        )}

      </Box>

      <Box display={{ xs: 'none', md: 'flex' }} >
        <motion.div
          animate={{ x: sidebarAnimation }}
          transition={{ duration: 0.85, ease: 'easeOut' }}
          className='myMenu'
        >
          <Sidebar largeOpen={largeOpen} setlargeOpen={setlargeOpen} setanimation={setanimation} setsidebarAnimation={setsidebarAnimation} />
        </motion.div>
      </Box>

      <Box marginLeft={{ md: largeOpen ? '300px' : '0' }}>
        <motion.div
          animate={{ x: animation }}
          transition={{ duration: 0.85, ease: 'easeOut' }}
        >
          {!largeOpen && (
            <Box display={{ xs: 'none', md: 'flex' }} paddingTop='3px' paddingLeft={'3px'}>
              <IconButton onClick={() => {
                setlargeOpen(true)
                setanimation([-300, 0])
                setsidebarAnimation([-300, 0])
              }}>
                <Menu />
              </IconButton>
            </Box>
          )}

          <Box padding={!largeOpen && '0.4rem 1.5rem'}>

            <Header />

            {userFound ? (
              <Box>
                <Box display='flex' marginTop={'35px'} padding='0 20px' flexDirection={{ xs: 'column', sm: 'row' }} justifyContent={'space-between'} alignItems='center' gap={{ xs: '40px', sm: '20px' }}>
                  <Box>
                    <Box display='flex' alignItems='center' gap='25px'>
                      <Box component='img' src={userFound.pic} width='80px' height='80px' borderRadius={'50%'} sx={{ objectFit: 'cover' }} />
                      <Typography fontSize='25px' fontWeight={'500'}>{userFound.name}</Typography>
                    </Box>
                  </Box>
                  <Box display='flex' justifyContent={'flex-end'}>
                    <Button variant='contained'
                      sx={{
                        textTransform: 'none',
                        borderRadius: '25px',
                        fontSize: '17px',
                        color: 'black',
                        backgroundColor: '#eeeeee',
                        transition: 'all 0.5s ease-in-out',
                        '&:hover': {
                          backgroundColor: 'black',
                          color: 'white'
                        }
                      }}
                      onClick={() => disconnect()}
                      disabled={userInfo && userInfo.activePackages.length > 0 && true}
                    >
                      Disconnect Channel
                    </Button>
                  </Box>
                </Box>
                {userInfo.activePackages.length ? (
                  <>
                    {userInfo.streamCount > 0 ? (
                      <Box display='flex' flexDirection={{ xs: 'column', sm: 'row' }} gap='25px' alignItems={{ sm: 'center' }} height={{ xs: '30vh', sm: '57vh' }} justifyContent={'center'}>
                        <Button variant='outlined'
                          sx={{
                            textTransform: 'none',
                            borderRadius: '28px',
                            padding: '5px 27px',
                            fontSize: { xs: '18px', sm: '20px' },
                            margin: { xs: '0 50px', sm: '0' },
                            borderColor: 'black',
                            borderWidth: '2px',
                            color: 'black',
                            backgroundColor: '#eeeeee',
                            transition: 'all 0.6s ease-in-out',
                            '&:hover': {
                              backgroundColor: 'black',
                              color: 'white',
                              transform: 'scale(1.1)'
                            }
                          }}
                          onClick={() => setopenAdd(true)}
                        >
                          Add Live Streaming
                        </Button>
                        <Button variant='outlined'
                          sx={{
                            textTransform: 'none',
                            borderRadius: '28px',
                            borderColor: 'black',
                            borderWidth: '2px',
                            padding: '5px 27px',
                            fontSize: { xs: '18px', sm: '20px' },
                            margin: { xs: '0 50px', sm: '0' },
                            color: 'black',
                            backgroundColor: '#eeeeee',
                            transition: 'all 0.6s ease-in-out',
                            '&:hover': {
                              backgroundColor: 'black',
                              color: 'white',
                              transform: 'scale(1.1)'
                            }
                          }}
                          onClick={() => setopenLive(true)}
                        >
                          Start Live Streaming
                        </Button>

                      </Box>
                    ) : (
                      <>

                        {userInfo.liveUrl ? (

                          <Box display='flex' alignItems={{ sm: 'center' }} height={{ sm: '54vh' }} marginTop={{ xs: '30px', sm: '' }} justifyContent={'center'}

                          >

                            <Button variant='outlined'
                              sx={{
                                textTransform: 'none',
                                borderRadius: '28px',
                                padding: '5px 27px',
                                fontSize: '20px',
                                borderColor: 'black',
                                borderWidth: '2px',
                                color: 'black',
                                backgroundColor: '#eeeeee',
                                transition: 'all 0.6s ease-in-out',
                                '&:hover': {
                                  backgroundColor: 'black',
                                  color: 'white',
                                  transform: 'scale(1.1)'
                                }
                              }}
                              onClick={() => seteditVideo(true)}
                            >
                              Edit Video Url
                            </Button>
                          </Box>
                        ) : (

                          <>
                            <Box sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              marginTop: '20px'
                            }}>
                              <Box borderRadius='10px'
                                sx={{
                                  backgroundColor: 'white',
                                  width: '400px',
                                  padding: '20px'
                                }}
                              >
                                <Typography fontSize='15px' textAlign='center' color='#757575'>Please provide your live streaming video url</Typography>
                                <Box component={'form'} onSubmit={handleLiveUrl} width='90%' margin='0 auto' marginTop='20px'>
                                  <Box sx={{ position: 'relative' }}>
                                    <TextField
                                      placeholder='Youtube Live Streaming Url'
                                      id='Email'
                                      fullWidth
                                      onClick={() => setisActive(true)}
                                      ref={wrapperRef}
                                      required
                                      onChange={(e) => setliveUrl(e.target.value)}
                                      InputProps={{
                                        disableUnderline: true,
                                        style: {
                                          fontSize: 15,
                                        },
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <InsertLink sx={{ fontSize: '25px' }} />
                                          </InputAdornment>
                                        )
                                      }}
                                      sx={{

                                        '& .MuiInputBase-root': {
                                          border: '1px solid #e0e0e0',
                                          padding: '7px 17px',
                                          borderRadius: '10px',
                                          transition: 'all 0.4s ease-in-out',
                                          backgroundColor: '#f5f5f5',
                                        },
                                        '& .Mui-focused': {
                                          backgroundColor: 'black !important',
                                          color: 'white',
                                          '& .MuiSvgIcon-root': {
                                            color: 'white'
                                          },
                                          '& ::placeholder': {
                                            color: 'white',
                                            opacity: '0.7'
                                          }
                                        },

                                      }}
                                      variant="standard"
                                    />
                                    <IconButton sx={{ position: 'absolute', right: '0', top: '4px' }} disableRipple onClick={() => setshowIcon(!showIcon)}>
                                      <InfoOutlined sx={{ fontSize: '23px', backgroundColor: showIcon && '#757575', color: isActive || showIcon === true ? 'white' : 'unset', borderRadius: showIcon && '50px' }} />
                                    </IconButton>

                                    {showIcon && (
                                      <motion.div
                                        animate={{ x: [-100, 0] }}
                                        transition={{ duration: 0.85, ease: 'easeOut' }}
                                      >
                                        <Box marginTop={'10px'} marginBottom='5px' gap='7px' bgcolor={'#e0e0e0'} padding='7px 10px' borderRadius={'10px'}>
                                          <Typography color='#757575' fontSize={'13px'}>Go to your youtube channel content page and there from live streaming copy the video link.</Typography>
                                          <Box display='flex' alignItems={'center'} gap='7px' marginBottom={'5px'}>
                                            <Typography fontWeight={'bold'} fontSize={'13px'}>Sample:</Typography>
                                            <Typography color='#757575' fontSize={'11px'}>https://www.youtube.com/watch?v=gwpv</Typography>
                                          </Box>
                                        </Box>
                                      </motion.div>
                                    )}

                                  </Box>

                                  <Box display='flex' marginTop='15px' justifyContent='flex-end'>
                                    <Button variant="contained" type='submit' color='error' size='small'
                                      sx={{
                                        textTransform: 'none',
                                        borderRadius: '10px',
                                        fontSize: '15px'
                                      }}>
                                      Submit
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Box display='flex' alignItems={{ sm: 'center' }} marginTop={'60px'} justifyContent={'center'}

                            >

                              <Button variant='outlined'
                                sx={{
                                  textTransform: 'none',
                                  borderRadius: '28px',
                                  padding: '5px 27px',
                                  fontSize: '20px',
                                  borderColor: 'black',
                                  borderWidth: '2px',
                                  color: 'black',
                                  backgroundColor: '#eeeeee',
                                  transition: 'all 0.6s ease-in-out',
                                  '&:hover': {
                                    backgroundColor: 'black',
                                    color: 'white',
                                    transform: 'scale(1.1)'
                                  }
                                }}
                                onClick={() => seteditVideo(true)}
                              >
                                Edit Video Url
                              </Button>
                            </Box>
                          </>
                        )}

                      </>
                    )}
                  </>
                ) : (
                  <Box display='flex' gap='6px' alignItems={'center'} justifyContent='center' height={{ xs: '30vh', md: '50vh' }}>
                    <Typography color='#757575' textAlign={'center'} fontSize={'15px'}>You don't have any active package please buy an <Typography component={'li'} onClick={() => navigate('/pricing')} sx={{ textDecoration: 'none', listStyleType: 'none', cursor: 'pointer' }} color='#ef5350' textAlign={'center'} fontSize={'15px'}>package.</Typography>
                    </Typography>
                  </Box>
                )}

              </Box>
            ) : (

              <Box display='flex' flexDirection={'column'} alignItems={'center'} justifyContent='center' height='65vh'>

                <Box component={'img'} width='250px' src={img} sx={{ objectFit: 'cover' }} />

                <Button
                  variant='outlined'
                  size='large'
                  sx={{
                    textTransform: 'none',
                    borderColor: '#ef5350',
                    borderRadius: '28px',
                    padding: '5px 30px',
                    color: '#ef5350',
                    fontSize: '25px',
                    marginTop: '-40px',
                    transition: 'all 0.6s ease-in-out',
                    '&:hover': {
                      backgroundColor: '#ef5350',
                      color: 'white',
                      transform: 'scale(1.1)'
                    }
                  }}
                  href={url && url}
                >
                  Connect Channel
                </Button>
              </Box>

            )}
          </Box>
        </motion.div>
      </Box>

      {/* Adding Stream */}

      <Dialog
        open={openAdd}
        onClose={() => setopenAdd(false)}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: {
            width: { xs: '94%', sm: '400px' },
            borderRadius: '20px'
          }
        }}

      >
        <Box padding='25px' >

          <Box display={'flex'} justifyContent='flex-end' >
            <IconButton className='cancel-icon' onClick={() => setopenAdd(false)}>
              <Cancel />
            </IconButton>
          </Box>
          <Box marginTop='20px'
            sx={{
              backgroundColor: '#ef5350',
              borderRadius: '10px',
              padding: '7px 0'
            }}>
            <Typography fontWeight={'500'} fontSize='20px' color='white' textAlign='center'>
              Add Live Streaming
            </Typography>
          </Box>

          <Box component={'form'} width='90%' onSubmit={handleClick} margin='0 auto'>
            <Box display='flex' flexDirection={'column'} gap='20px' margin='10px 0' marginTop='30px'>
              <TextField
                placeholder='Enter Your Title'
                id='Email'
                required
                fullWidth
                onChange={(e) => settitle(e.target.value)}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    fontSize: 15,
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <TitleOutlined sx={{ fontSize: '25px' }} />
                    </InputAdornment>
                  )
                }}
                sx={{

                  '& .MuiInputBase-root': {
                    border: '1px solid #e0e0e0',
                    padding: '7px 10px',
                    borderRadius: '10px',
                    transition: 'all 0.4s ease-in-out',
                    backgroundColor: '#f5f5f5',
                  },
                  '& .Mui-focused': {
                    backgroundColor: 'black !important',
                    color: 'white',
                    '& .MuiSvgIcon-root': {
                      color: 'white'
                    },
                    '& ::placeholder': {
                      color: 'white',
                      opacity: '0.7'
                    }
                  },

                }}
                variant="standard"
              />
              <TextField
                placeholder='Enter Your Description'
                id='Email'
                multiline
                required
                minRows={3}
                fullWidth
                onChange={(e) => setdesc(e.target.value)}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    fontSize: 15,
                  },
                }}
                sx={{

                  '& .MuiInputBase-root': {
                    border: '1px solid #e0e0e0',
                    padding: '7px 17px',
                    borderRadius: '10px',
                    transition: 'all 0.4s ease-in-out',
                    backgroundColor: '#f5f5f5',
                  },
                  '& .Mui-focused': {
                    backgroundColor: 'black !important',
                    color: 'white',

                    '& ::placeholder': {
                      color: 'white',
                      opacity: '0.7'
                    }
                  },

                }}
                variant="standard"
              />
            </Box>
            <Box >

              <Button variant='contained' color='error'

                sx={{
                  textTransform: 'none',
                  fontSize: 15,
                  width: '100%',
                  borderRadius: '10px',
                  transition: 'all 0.5s ease-in-out',
                  marginTop: '10px',
                  marginBottom: '10px'
                }}
                type='submit'

              >
                {response.isLoading ? <CircularProgress sx={{ color: 'white' }} /> : 'Submit'}
              </Button>
            </Box>
          </Box>
        </Box>

      </Dialog>


      {/* Sending Stream Key*/}

      <Dialog
        open={openLive}
        onClose={() => setopenLive(false)}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: {
            width: { xs: '94%', sm: '400px' },
            borderRadius: '20px'
          }
        }}

      >
        <Box padding='25px' >

          <Box display={'flex'} justifyContent='flex-end' >
            <IconButton className='cancel-icon' onClick={() => setopenLive(false)}>
              <Cancel />
            </IconButton>
          </Box>
          <Box marginTop='20px'
            sx={{
              backgroundColor: '#ef5350',
              borderRadius: '10px',
              padding: '7px 0'
            }}>
            <Typography fontWeight={'500'} fontSize='20px' color='white' textAlign='center'>
              Stream Key & Video Url
            </Typography>
          </Box>

          <Box component={'form'} width='90%' onSubmit={handleSaveStream} margin='0 auto'>
            <Box display='flex' flexDirection={'column'} gap='20px' margin='10px 0' marginTop='50px'>

              <Box sx={{ position: 'relative', width: '100%' }}>
                <TextField
                  placeholder='Youtube Stream Key'
                  id='Email'
                  fullWidth
                  onClick={() => setisActive(true)}
                  ref={wrapperRef}
                  required
                  onChange={(e) => setstreamKey(e.target.value)}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      fontSize: 15,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <KeyOutlined sx={{ fontSize: '25px' }} />
                      </InputAdornment>
                    )
                  }}
                  sx={{

                    '& .MuiInputBase-root': {
                      border: '1px solid #e0e0e0',
                      padding: '7px 17px',
                      borderRadius: '10px',
                      transition: 'all 0.4s ease-in-out',
                      backgroundColor: '#f5f5f5',
                    },
                    '& .Mui-focused': {
                      backgroundColor: 'black !important',
                      color: 'white',
                      '& .MuiSvgIcon-root': {
                        color: 'white'
                      },
                      '& ::placeholder': {
                        color: 'white',
                        opacity: '0.7'
                      }
                    },

                  }}
                  variant="standard"
                />
                <IconButton sx={{ position: 'absolute', right: '0', top: '4px' }} disableRipple onClick={() => setshowIcon(!showIcon)}>
                  <InfoOutlined sx={{ fontSize: '23px', backgroundColor: showIcon && '#757575', color: isActive || showIcon === true ? 'white' : 'unset', borderRadius: showIcon && '50px' }} />
                </IconButton>

                {showIcon && (
                  <motion.div
                    animate={{ x: [-300, 0] }}
                    transition={{ duration: 0.85, ease: 'easeOut' }}
                  >
                    <Box display='flex' marginTop={'10px'} gap='7px' bgcolor={'#e0e0e0'} padding='7px 10px' borderRadius={'10px'}>
                      <Typography fontWeight='bold' fontSize={'13px'}>Stream key sample:</Typography>
                      <Typography color='#757575' fontSize={'13px'}>achg-jokd-3ijd-jidk-sjks</Typography>
                    </Box>
                  </motion.div>
                )}

              </Box>
              <Box sx={{ position: 'relative', width: '100%' }}>
                <TextField
                  placeholder='Your Stream Url'
                  id='Email'
                  fullWidth
                  onClick={() => setisActive(true)}
                  ref={wrapperRef}
                  required
                  onChange={(e) => setstreamUrl(e.target.value)}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      fontSize: 15,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <KeyOutlined sx={{ fontSize: '25px' }} />
                      </InputAdornment>
                    )
                  }}
                  sx={{

                    '& .MuiInputBase-root': {
                      border: '1px solid #e0e0e0',
                      padding: '7px 17px',
                      borderRadius: '10px',
                      transition: 'all 0.4s ease-in-out',
                      backgroundColor: '#f5f5f5',
                    },
                    '& .Mui-focused': {
                      backgroundColor: 'black !important',
                      color: 'white',
                      '& .MuiSvgIcon-root': {
                        color: 'white'
                      },
                      '& ::placeholder': {
                        color: 'white',
                        opacity: '0.7'
                      }
                    },

                  }}
                  variant="standard"
                />
                <IconButton sx={{ position: 'absolute', right: '0', top: '4px' }} disableRipple onClick={() => setstreamSample(!streamSample)}>
                  <InfoOutlined sx={{ fontSize: '23px', backgroundColor: streamSample && '#757575', color: streamSample || isActive === true ? 'white' : 'unset', borderRadius: streamSample && '50px' }} />
                </IconButton>

                {streamSample && (
                  <motion.div
                    animate={{ x: [-300, 0] }}
                    transition={{ duration: 0.85, ease: 'easeOut' }}
                  >
                    <Box display='flex' marginTop={'10px'} gap='7px' bgcolor={'#e0e0e0'} padding='7px 10px' borderRadius={'10px'}>
                      <Typography fontWeight='bold' fontSize={'13px'}>Sample:</Typography>
                      <Typography color='#757575' fontSize={'13px'}>rtmp://a.rtmp.youtube.com/live2</Typography>
                    </Box>
                  </motion.div>
                )}

              </Box>
              {(() => {
                const arr = [];

                let x
                if (userInfo.videosCount > 8) {
                  x = 8
                } else {
                  x = userInfo.videosCount
                }

                for (let i = 0; i < x; i++) {
                  arr.push(
                    <TextField
                      placeholder={`${i === 0 ? '1st' : i === 1 ? '2nd' : i === 2 ? '3rd' : `${i + 1}th`} Video Live Url`}
                      id='Email'
                      fullWidth
                      onChange={(e) => handleVideoChange(e.target.value, i)}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontSize: 15,
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <LinkOutlined sx={{ fontSize: '25px' }} />
                          </InputAdornment>
                        )
                      }}
                      sx={{

                        '& .MuiInputBase-root': {
                          border: '1px solid #e0e0e0',
                          padding: '7px 17px',
                          borderRadius: '10px',
                          transition: 'all 0.4s ease-in-out',
                          backgroundColor: '#f5f5f5',
                        },
                        '& .Mui-focused': {
                          backgroundColor: 'black !important',
                          color: 'white',
                          '& .MuiSvgIcon-root': {
                            color: 'white'
                          },
                          '& ::placeholder': {
                            color: 'white',
                            opacity: '0.7'
                          }
                        },

                      }}
                      variant="standard"
                    />
                  );
                }
                return arr;
              })()}
            </Box>
            <Box display='flex' justifyContent='flex-end'>

              <Button variant='outlined' color='error'
                size='small'
                sx={{
                  textTransform: 'none',
                  fontSize: 13,
                  borderRadius: '10px',
                  transition: 'all 0.5s ease-in-out',
                  marginTop: '10px',
                  marginBottom: '10px'
                }}
                onClick={() => navigate('/video/upload')}
              >
                Upload Video
              </Button>
            </Box>

            <Box >
              {error && (

                <Box display='flex' justifyContent={'flex-start'} width='100%'>
                  <Typography color='red' fontSize='13px' fontWeight={'500'}>
                    {error}
                  </Typography>
                </Box>

              )}
              <Button variant='contained' color='error'
                sx={{
                  textTransform: 'none',
                  fontSize: 15,
                  width: '100%',
                  borderRadius: '10px',
                  transition: 'all 0.5s ease-in-out',
                  marginTop: '10px',
                  marginBottom: '10px'
                }}
                type='submit'
              >
                {streamRes.isLoading ? <CircularProgress sx={{ color: 'white' }} /> : 'Submit'}
              </Button>
            </Box>

          </Box>
        </Box>

      </Dialog>

      {/*Edit Video Url*/}

      <Dialog
        open={editVideo}
        onClose={() => seteditVideo(false)}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: {
            width: { xs: '94%', sm: '400px' },
            borderRadius: '20px'
          }
        }}

      >
        <Box padding='25px' >

          <Box display={'flex'} justifyContent='flex-end' >
            <IconButton className='cancel-icon' onClick={() => seteditVideo(false)}>
              <Cancel />
            </IconButton>
          </Box>
          <Box marginTop='20px'
            sx={{
              backgroundColor: '#ef5350',
              borderRadius: '10px',
              padding: '7px 0'
            }}>
            <Typography fontWeight={'500'} fontSize='20px' color='white' textAlign='center'>
              Edit Video Url
            </Typography>
          </Box>

          <Box component={'form'} width='90%' onSubmit={handleUpdateUrl} margin='0 auto'>
            <Box display='flex' flexDirection={'column'} gap='20px' margin='10px 0' marginTop='50px'>

              {(() => {
                const arr = [];
                let x
                if (userInfo.videosCount > 8) {
                  x = 8
                } else {
                  x = userInfo.videosCount
                }
                for (let i = 0; i < x; i++) {
                  arr.push(
                    <TextField
                      placeholder={`${i === 0 ? '1st' : i === 1 ? '2nd' : i === 2 ? '3rd' : `${i + 1}th`} Video Live Url`}
                      id='Email'
                      fullWidth

                      onChange={(e) => handleVideoUpdateChange(e.target.value, i)}
                      value={updateUrl.length && updateUrl[i].videoUrl}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontSize: 15,
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <LinkOutlined sx={{ fontSize: '25px' }} />
                          </InputAdornment>
                        )
                      }}
                      sx={{

                        '& .MuiInputBase-root': {
                          border: '1px solid #e0e0e0',
                          padding: '7px 17px',
                          borderRadius: '10px',
                          transition: 'all 0.4s ease-in-out',
                          backgroundColor: '#f5f5f5',
                        },
                        '& .Mui-focused': {
                          backgroundColor: 'black !important',
                          color: 'white',
                          '& .MuiSvgIcon-root': {
                            color: 'white'
                          },
                          '& ::placeholder': {
                            color: 'white',
                            opacity: '0.7'
                          }
                        },

                      }}
                      variant="standard"
                    />
                  );
                }
                return arr;
              })()}
            </Box>

            <Box display='flex' justifyContent='flex-end'>

              <Button variant='outlined' color='error'
                size='small'
                sx={{
                  textTransform: 'none',
                  fontSize: 13,
                  borderRadius: '10px',
                  transition: 'all 0.5s ease-in-out',
                  marginTop: '10px',
                  marginBottom: '10px'
                }}
                onClick={() => navigate('/video/upload')}
              >
                Upload Video
              </Button>
            </Box>

            <Box >

              <Button variant='contained' color='error'
                sx={{
                  textTransform: 'none',
                  fontSize: 15,
                  width: '100%',
                  borderRadius: '10px',
                  transition: 'all 0.5s ease-in-out',
                  marginTop: '20px',
                  marginBottom: '10px'
                }}
                type='submit'
              >
                Update
              </Button>
            </Box>


          </Box>
        </Box>

      </Dialog>

    </StyledNavbar >
  )
}

export default MyChannel