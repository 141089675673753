import React, { useEffect } from "react";
import { useState } from "react";
import { Alert, Backdrop, Box, Button, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select, Snackbar, styled, Switch, TextField, toggleButtonClasses, Typography, useMediaQuery } from "@mui/material";
import { useGetSingleUserQuery, useUpdateUserAdminMutation, useUpdateUserPasswordAdminMutation } from "../../../../services/userApi";
import { useParams, Link } from "react-router-dom";
import { motion } from 'framer-motion';
import { Menu } from "@mui/icons-material";
import { StyledUser } from "../style";
import { Country } from 'country-state-city'
import Sidebar from '../../Sidebar/Sidebar'
import { Loader } from "../../../../Components";
import Header from "../Header/Header";


import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import AddTime from "./AddTime";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: '250px',
            position: 'absolute',
            bottom: '0',
            right: '-100%',

        },
    },
    disableScrollLock: true
};



const UserDetails = () => {

    const [name, setname] = useState('')
    const [country, setcountry] = useState('')
    const [email, setemail] = useState('')
    const [phone, setphone] = useState()
    const [avatar, setavatar] = useState('')
    const [newPassword, setnewPassword] = useState()
    const [open, setopen] = useState(false)
    const [openAlert, setOpenAlert] = useState(false);
    const [message, setmessage] = useState('')
    const [avatarPreview, setavatarPreview] = useState('')

    const [percentage, setpercentage] = useState('')
    const [openAdd, setopenAdd] = useState(false)


    const { id } = useParams()

    const { data, isFetching, refetch } = useGetSingleUserQuery(id)
    const [updateUser, response] = useUpdateUserAdminMutation()
    const [updatePassword, responseInfo] = useUpdateUserPasswordAdminMutation()


    const handleProfileUpdate = (e) => {

        e.preventDefault()

        updateUser({ name, country, avatar, phone, id })

    }

    const handlePasswordUpdate = (e) => {

        e.preventDefault()

        updatePassword({ newPassword, id })


    }

    const handleImgChange = (e) => {

        const selectedFile = e.target.files[0];

        if (selectedFile.type === 'image/png' || selectedFile.type === 'image/svg' || selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/gif' || selectedFile.type === 'image/tiff' || selectedFile.type === 'image/jpeg') {

            const reader = new FileReader()
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setavatar(reader.result)
                    setavatarPreview(reader.result)
                }
            }
            reader.readAsDataURL(e.target.files[0])
        } else {
            setmessage('Please Select An Image!')
            setOpenAlert(true);
        }

    }


    const handleClose = (e) => {
        if (e === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };

    useEffect(() => {

        if (data) {

            setname(data.user.name && data.user.name)
            setcountry(data.user.country && data.user.country)
            setemail(data.user.email && data.user.email)
            setphone(data.user.phone && data.user.phone)
            setnewPassword(data.user.password && data.user.password)
            setavatarPreview(data.user.avatar && data.user.avatar.url)

        }
    }, [data])


    useEffect(() => {

        if (response.status === 'fulfilled') {
            refetch()
            setmessage('User Profile has been Updated!')
            setOpenAlert(true);

        }
        if (responseInfo.status === 'fulfilled') {
            setOpenAlert(true);
            setmessage('User Password has been Updated!')

        }



    }, [response, responseInfo])

    let UserProfile = true

    return (
        <StyledUser >

            <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center', }}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }} >
                    {message}
                </Alert>
            </Snackbar>

            <Box display={{ xs: 'none', md: 'flex' }} >
                <Sidebar UserProfile={UserProfile} />
            </Box>
            <Box display={{ xs: 'flex', md: 'none' }} sx={{ position: 'fixed', top: '10px', left: '10px', zIndex: '1' }}>
                <IconButton onClick={() => setopen(!open)} >
                    <Menu />
                </IconButton>
            </Box>
            <Backdrop open={open} onClick={() => setopen(false)} sx={{ zIndex: '1' }} />

            <Box>
                {open && (
                    <Box>
                        <motion.div

                            animate={{ x: [-300, 0] }}
                            transition={{ duration: 0.85, ease: 'easeOut' }}
                            className='myMenu'

                        >

                            <Sidebar open={open} setopen={setopen} UserProfile={UserProfile} />
                        </motion.div>

                    </Box>
                )}

            </Box>

            <Box marginLeft={{ md: '310px' }} className="user-con">
                <Header percentage={percentage} setpercentage={setpercentage} />

                <Box bgcolor={'#F2F1F1'} borderRadius='35px' marginTop='40px' padding='1rem' minHeight={{ md: '70vh', lg: '85vh' }}>
                    {isFetching ? <Loader height={'70vh'} /> : (
                        <>
                            <Box bgcolor={'white'} width='100%' borderRadius={'25px'} marginTop={{ xs: '40px', sm: '10px' }} padding='45px 40px'>
                                <Box display='flex' justifyContent={'space-between'} gap='10px' alignItems='center'>
                                    <Typography fontWeight={'500'} fontSize='30px'>Profile</Typography>
                                    <Box display='flex' gap='10px'>
                                        <Link to={`/admin/user/active-packages/${id}`} style={{ textDecoration: 'none' }}>
                                            <Button variant="contained" color='error'
                                                sx={{
                                                    textTransform: 'none',
                                                    borderRadius: '10px',
                                                    fontSize: '17px',
                                                    display: { xs: 'none', sm: 'flex' }
                                                }}
                                            >
                                                Active Packages
                                            </Button>
                                        </Link>
                                        <Link to={`/admin/user/packages/${id}`} style={{ textDecoration: 'none' }}>
                                            <Button variant="contained" color='error'
                                                sx={{
                                                    textTransform: 'none',
                                                    borderRadius: '10px',
                                                    fontSize: '17px',
                                                    display: { xs: 'none', sm: 'flex' }
                                                }}
                                            >
                                                Packages History
                                            </Button>
                                        </Link>
                                        <Button variant="contained" color='error'
                                            sx={{
                                                textTransform: 'none',
                                                borderRadius: '10px',
                                                fontSize: '17px',
                                                display: { xs: 'none', sm: 'flex' }
                                            }}
                                            onClick={() => setopenAdd(true)}
                                        >
                                            Add Time
                                        </Button>
                                        <Button variant="contained" color='error' onClick={handleProfileUpdate}
                                            sx={{
                                                textTransform: 'none',
                                                borderRadius: '10px',
                                                fontSize: '17px',
                                            }}>
                                            {response.isLoading ? <CircularProgress size={30} sx={{ color: 'white' }} /> : 'Save'}
                                        </Button>

                                    </Box>
                                </Box>
                                <Box display={{ xs: 'flex', sm: 'none' }} flexDirection='column' gap='15px' marginTop='30px' alignItems='center'>
                                    <Link to={`/admin/user/packages/${id}`} style={{ textDecoration: 'none' }}>
                                        <Button variant="contained" color='error'
                                            sx={{
                                                textTransform: 'none',
                                                borderRadius: '10px',
                                                fontSize: '17px',

                                            }}
                                        >
                                            Packages History
                                        </Button>
                                    </Link>
                                    <Link to={`/admin/user/active-packages/${id}`} style={{ textDecoration: 'none' }}>
                                        <Button variant="contained" color='error'
                                            sx={{
                                                textTransform: 'none',
                                                borderRadius: '10px',
                                                fontSize: '17px',

                                            }}
                                        >
                                            Active Packages
                                        </Button>
                                    </Link>
                                    <Button variant="contained" color='error'
                                        sx={{
                                            textTransform: 'none',
                                            borderRadius: '10px',
                                            fontSize: '17px',
                                        }}
                                        onClick={() => setopenAdd(true)}
                                    >
                                        Add Time
                                    </Button>
                                </Box>
                                <Box>
                                    <Box component='form' marginTop='40px'>
                                        <Box display={'flex'} flexDirection='column' gap='20px'>
                                            <Box display={'flex'} flexDirection={{ xs: 'column', lg: 'row' }} gap='20px' justifyContent='space-between' alignItems={'center'}>
                                                <Box display={'flex'} alignItems='center'>
                                                    <Typography component={'label'} htmlFor="Email" display={{ xs: 'none', sm: 'unset' }} width={{ xs: '103px', lg: '70px' }} fontWeight={'500'} fontSize={18}>
                                                        Email
                                                    </Typography>
                                                    <TextField
                                                        placeholder='Email'
                                                        className='feild'
                                                        id='Email'
                                                        type={'email'}
                                                        disabled
                                                        value={email}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            style: {
                                                                fontSize: 15,
                                                            }
                                                        }}
                                                        variant="standard"
                                                    />
                                                </Box>

                                                <Box display={'flex'} alignItems='center' >
                                                    <Typography component={'label'} htmlFor="Country" display={{ xs: 'none', sm: 'unset' }} width='103px' fontWeight={'500'} fontSize={18}>
                                                        Country
                                                    </Typography>
                                                    <FormControl focused={false}>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select-label"
                                                            required
                                                            placeholder='Country'
                                                            name='country'
                                                            value={country}
                                                            onChange={(e) => setcountry(e.target.value)}
                                                            MenuProps={MenuProps}

                                                        >

                                                            {Country && Country.getAllCountries().map((name) => (

                                                                <MenuItem
                                                                    key={name.isoCode}
                                                                    value={name.name}
                                                                >
                                                                    {name.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </Box>
                                            <Box display={'flex'} flexDirection={{ xs: 'column', lg: 'row' }} gap='20px' justifyContent='space-between' alignItems={'center'}>
                                                <Box display={'flex'} alignItems='center' >
                                                    <Typography component={'label'} htmlFor="Phone" display={{ xs: 'none', sm: 'unset' }} width={{ xs: '103px', lg: '70px' }} fontWeight={'500'} fontSize={18}>
                                                        Phone
                                                    </Typography>
                                                    <TextField
                                                        placeholder={'Phone'}
                                                        className='feild'
                                                        id='Phone'
                                                        value={phone}
                                                        onChange={(e) => setphone(e.target.value)}
                                                        type={'number'}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            style: {
                                                                fontSize: 15,
                                                            }
                                                        }}
                                                        variant="standard"
                                                    />
                                                </Box>

                                                <Box display={'flex'} alignItems='center' >
                                                    <Typography component={'label'} htmlFor="WeChat" display={{ xs: 'none', sm: 'unset' }} width='103px' fontWeight={'500'} fontSize={18}>
                                                        Name
                                                    </Typography>
                                                    <TextField
                                                        placeholder={'Name'}
                                                        className='feild'
                                                        value={name}
                                                        onChange={(e) => setname(e.target.value)}
                                                        id='WeChat'
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            style: {
                                                                fontSize: 15,
                                                            }
                                                        }}
                                                        variant="standard"
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>

                                </Box>
                            </Box>

                            <Box marginTop={'40px'} paddingBottom='30px' display='flex' gap='1rem' justifyContent={{ xs: 'center' }} flexWrap='wrap'>

                                <Box bgcolor={'white'} width={{ xs: '100%', sm: '280px' }} height='300px' borderRadius={'25px'} padding='25px 20px'>

                                    <Typography fontWeight={'500'} fontSize='22px'>Update Password</Typography>
                                    <Box marginTop={'35px'} >
                                        <Typography component={'label'} htmlFor="password" fontWeight={'500'} fontSize={18}>
                                            New Password
                                        </Typography>
                                        <TextField
                                            placeholder='*****'
                                            className='feild'
                                            onChange={(e) => setnewPassword(e.target.value)}
                                            sx={{ width: '100% !important', marginTop: '10px' }}
                                            type='password'
                                            id='password'
                                            InputProps={{
                                                disableUnderline: true,
                                                style: {
                                                    fontSize: 15,

                                                }
                                            }}
                                            variant="standard"
                                        />
                                        <Box display='flex' justifyContent='center'>
                                            <Button variant="contained"
                                                color='error'
                                                onClick={handlePasswordUpdate}
                                                sx={{
                                                    textTransform: 'none',
                                                    marginTop: '44px',
                                                    textTransform: 'none',
                                                    borderRadius: '10px',
                                                    fontSize: '17px',
                                                    width: '100%'
                                                }}>Update</Button>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box bgcolor={'white'} width={{ xs: '100%', sm: '280px' }} height='300px' borderRadius={'25px'} padding='25px 20px'>

                                    <Typography fontWeight={'500'} fontSize='22px'>Profile Photo</Typography>

                                    <Box display='flex' justifyContent={'center'} marginTop='20px'>
                                        <Box component='img' src={avatarPreview} height='140px' width='140px' sx={{ borderRadius: '50%', objectFit: 'cover' }} />
                                    </Box>
                                    <Button variant="contained" color='error' component="label" sx={{
                                        textTransform: 'none', fontSize: '18px', fontWeight: '500', marginTop: '10px', textTransform: 'none',
                                        borderRadius: '10px',
                                        fontSize: '17px',
                                        width: '100%'
                                    }}>
                                        Upload New
                                        <input hidden onChange={handleImgChange} accept="image/*" type="file" />
                                    </Button>

                                </Box>
                                <Box component={Link} to={`/admin/user/packages/${id}`} sx={{ textDecoration: 'none' }} bgcolor={'white'} width={{ xs: '100%', sm: '280px' }} height='300px' borderRadius={'25px'} padding='25px 20px'>

                                    <Typography fontWeight={'500'} fontSize='22px' sx={{ textDecoration: 'none', color: 'black' }}>Package Used</Typography>

                                    <Box display='flex' justifyContent={'center'} alignItems='center' >
                                        <Box width='150px' height='150px' marginTop={'30px'}>
                                            <CircularProgressbar value={percentage ? percentage : 0} text={`${percentage ? percentage : 0}%`} strokeWidth={3}
                                                styles={buildStyles({

                                                    // Colors
                                                    textColor: '#ef5350',
                                                    trailColor: '#d6d6d6',
                                                    pathColor: `#ef5350`,

                                                })}
                                            />
                                        </Box>
                                    </Box>

                                </Box>
                            </Box>
                        </>
                    )}

                </Box>

            </Box>

            <AddTime openAdd={openAdd} setopenAdd={setopenAdd} myId={id} />
        </StyledUser>
    )
}
export default UserDetails;