import React from 'react'
import { Grid, Box, Avatar, Typography, Stack, Button, useMediaQuery } from '@mui/material'
import img from '../../../assets/logo.jpeg'
import { Facebook, Instagram, MailOutlineOutlined, Twitter, YouTube } from '@mui/icons-material'
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom'
import img1 from '../../../assets/footer1.jpeg'
import img2 from '../../../assets/footer2.jpeg'
import img3 from '../../../assets/footer3.jpeg'


const Footer = () => {

    const navigate = useNavigate()

    const handleClick = () => {
        const releventDiv = document.getElementById('works');
        releventDiv.scrollIntoView({ behavior: "smooth" })
    }

    const smallerthen900 = useMediaQuery('(max-width:900px)')


    return (
        <Box bgcolor={'black'} >
            <Box width={{ xs: '100%', sm: '95%' }} margin='0 auto' paddingBottom='10px' >

                <Box display='flex' gap={{ xs: '15px', sm: '35px' }} justifyContent='center' margin={'30px 0'} paddingTop='60px'>

                    <motion.div
                        whileInView={{ x: [-300, 0], opacity: [0, 1] }}
                        transition={{ duration: 1.3 }}
                        viewport={{ once: true }}
                    >
                        <Box component='div' onClick={() => navigate('/register')}
                            height={{ xs: '150px', sm: '250px', md: '530px' }} padding={{ xs: '6px', sm: '8px', md: '10px' }} bgcolor='#bdbdbd' width={{ xs: '100px', sm: '150px', md: '340px' }} sx={{ border: '1px solid white', borderRadius: '20px', cursor: 'pointer' }}>
                            <Box component={'img'} src={img1} height='100%' width='100%' sx={{ borderRadius: '17px' }} />
                        </Box>
                    </motion.div>

                    <motion.div
                        whileInView={{ y: [300, 0], opacity: [0, 1] }}
                        transition={{ duration: 1.3 }}
                        viewport={{ once: true }}
                    >
                        <Box component='div' onClick={() => window.open('mailto:zemaa.entertainment@gmail.com')}
                            height={{ xs: '150px', sm: '250px', md: '530px' }} padding={{ xs: '6px', sm: '8px', md: '10px' }} bgcolor='#bdbdbd' width={{ xs: '100px', sm: '150px', md: '340px' }} sx={{ border: '1px solid white', borderRadius: '20px', cursor: 'pointer' }}>
                            <Box component={'img'} src={img2} height='100%' width='100%' sx={{ borderRadius: '17px' }} />
                        </Box>
                    </motion.div>

                    <motion.div
                        whileInView={{ x: [300, 0], opacity: [0, 1] }}
                        transition={{ duration: 1.3, ease: 'easeOut' }}
                        viewport={{ once: true }}
                    >
                        <Box component='div' onClick={handleClick}
                            height={{ xs: '150px', sm: '250px', md: '530px' }} padding={{ xs: '6px', sm: '8px', md: '10px' }} bgcolor='#bdbdbd' width={{ xs: '100px', sm: '150px', md: '340px' }} sx={{ border: '1px solid white', borderRadius: '20px', cursor: 'pointer' }}>
                            <Box component={'img'} src={img3} height='100%' width='100%' sx={{ borderRadius: '17px' }} />
                        </Box>
                    </motion.div>

                </Box>
                <Box>

                    <Box onClick={() => window.scrollTo(0, 0)} display='flex' justifyContent={'center'} paddingTop={{ xs: '20px', sm: '30px' }} sx={{ cursor: 'pointer', borderRadius: '50px' }}>
                        <Avatar src={img}
                            sx={{
                                width: { xs: '70px', sm: '90px' },
                                height: { xs: '70px', sm: '90px' },
                                borderRadius: '50px',
                                transition: 'all 1.6s ease-in-out',
                                '&:hover': {
                                    transform: 'rotateY(360deg)',
                                }
                            }}
                        />
                    </Box>

                    <Typography color='white' fontSize={'25px'} paddingTop='15px' paddingBottom='15px' textAlign={'center'} fontWeight={'bold'}>
                        Contact Us
                    </Typography>
                    <Box display='flex' justifyContent={{ xs: 'center' }} gap='14px' alignItems='center' paddingBottom='10px'>
                        <Box component={'a'} href='https://www.instagram.com/zema.entertainment' target={'_blank'} >
                            <Instagram sx={{
                                fontSize: { xs: '35px', sm: '30px' }, textDecoration: 'none',
                                transition: 'all 0.6s ease-in-out',
                                color: '#9e9e9e',
                                '&:hover': {
                                    transform: 'scale(1.2)',
                                }
                            }}
                            />
                        </Box>
                        <Box component={'a'} href='https://www.facebook.com/zema.co.uk' target={'_blank'}>
                            <Facebook sx={{ fontSize: { xs: '35px', sm: '30px' }, textDecoration: 'none', transition: 'all 0.6s ease-in-out', color: '#9e9e9e', '&:hover': { transform: 'scale(1.2)' } }}
                            />
                        </Box>
                        <Box component={'a'} href='https://youtube.com/@Zema' target={'_blank'}>
                            <YouTube sx={{ fontSize: { xs: '35px', sm: '30px' }, textDecoration: 'none', transition: 'all 0.6s ease-in-out', color: '#9e9e9e', '&:hover': { transform: 'scale(1.2)' } }}
                            />
                        </Box>
                        <Box component={'a'} href='mailto:zemaa.entertainment@gmail.com' target={'_blank'}>
                            <MailOutlineOutlined sx={{ fontSize: { xs: '35px', sm: '30px' }, textDecoration: 'none', transition: 'all 0.6s ease-in-out', color: '#9e9e9e', '&:hover': { transform: 'scale(1.2)' } }}
                            />
                        </Box>
                    </Box>
                    <Typography fontWeight='bold' paddingBottom={'14px'} textAlign='center' fontSize={{ xs: '11px', md: '16px' }} color='white'>
                        Copyright&copy;2022zemaa.entertainment@gmail.com
                    </Typography>

                </Box>
            </Box>

    
        </Box >
    )
}

export default Footer