import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { motion } from 'framer-motion';
import img1 from '../../../assets/choose1.webp'
import img2 from '../../../assets/choose2.webp'
import img3 from '../../../assets/choose3.webp'

const ChooseUs = () => {
    return (
        <Container>
            <Box display='flex' flexDirection={'column'} marginTop={{ xs: '30px', sm: '50px' }} >
                <Typography fontFamily={'roboto'} textAlign='center' fontWeight='bold' fontSize={{ xs: '30px', sm: '35px' }}>
                    Why You Should Choose <br /> Us?
                </Typography>

                <Box display='flex' margin='0 auto' marginTop={'50px'} width={{ xs: '90%', sm: '100%' }}>

                    <Grid container spacing={5} >
                        <Grid item xs={12} sm={6} md={4}>
                            <motion.div
                                whileInView={{ x: [-300, 0], opacity: [0, 1] }}
                                transition={{ duration: 1.3 }}
                                viewport={{ once: true }}
                            >
                                <Box bgcolor={'white'} borderRadius='20px' sx={{ transition: 'all 0.75s ease-in-out', '&:hover': { transform: 'scale(1.1)' } }}>
                                    <Box component='img' src={img1} width='100%' height='240px' sx={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }} />
                                    <Box padding='14px'>
                                        <Typography fontWeight='bold' fontSize={{ xs: '24px', sm: '27px', md: '30px' }} textAlign={'center'}>Live Stream Quickly and Effectively</Typography>
                                    </Box>
                                </Box>
                            </motion.div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <motion.div
                                whileInView={{ y: [300, 0], opacity: [0, 1] }}
                                transition={{ duration: 1.3 }}
                                viewport={{ once: true }}
                            >
                                <Box bgcolor={'white'} borderRadius='20px' sx={{ transition: 'all 0.75s ease-in-out', '&:hover': { transform: 'scale(1.1)' } }}>
                                    <Box component='img' src={img2} width='100%' height='240px' sx={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }} />
                                    <Box padding='14px'>
                                        <Typography fontWeight='bold' fontSize={{ xs: '24px', sm: '27px', md: '30px' }} textAlign={'center'}>Best Platform for Live Streaming</Typography>
                                    </Box>
                                </Box>
                            </motion.div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <motion.div
                                whileInView={{ x: [300, 0], opacity: [0, 1] }}
                                transition={{ duration: 1.3, ease: 'easeOut' }}
                                viewport={{ once: true }}
                            >
                                <Box bgcolor={'white'} borderRadius='20px' sx={{ transition: 'all 0.75s ease-in-out', '&:hover': { transform: 'scale(1.1)' } }}>
                                    <Box component='img' src={img3} width='100%' height='240px' sx={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }} />
                                    <Box padding='14px'>
                                        <Typography fontWeight='bold' fontSize={{ xs: '24px', sm: '27px', md: '30px' }} textAlign={'center'}>Go Live With Pre-Recorded Videos</Typography>
                                    </Box>
                                </Box>
                            </motion.div>
                        </Grid>
                    </Grid>

                </Box>
            </Box>
        </Container>
    )
}

export default ChooseUs