import React, { useContext, useEffect } from "react";
import logo from '../../../assets/logo.jpeg';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { SidebarConatiner } from './style'
import { Box, Typography } from '@mui/material'
import { NavLink, useNavigate } from 'react-router-dom'
import { DnsOutlined, Logout, Notifications, Settings, WarningAmberOutlined } from '@mui/icons-material'
import { useLogoutMutation } from '../../../services/userApi';
import StatesContext from '../../../context/StatesContext';

const Navigation = ({ open, setopen, UserProfile }) => {

    const context = useContext(StatesContext);
    const { setUserInfo } = context;
    const [logout, logoutResponse] = useLogoutMutation()

    const navigate = useNavigate()

    const handleOpen = () => {
        if (open) setopen(false)
    }

    const myRoutes = [
        {
            title: 'Users',
            icon: <DashboardIcon sx={{ fontSize: '30px' }} />,
            route: '/admin/users'
        },
        {
            title: 'Notifications',
            icon: <Notifications sx={{ fontSize: '30px' }} />,
            route: '/admin/user/credentials'
        },
        {
            title: 'Warnings',
            icon: <WarningAmberOutlined sx={{ fontSize: '30px' }} />,
            route: '/admin/warnings'
        },
        {
            title: 'Servers',
            icon: <DnsOutlined sx={{ fontSize: '30px' }} />,
            route: '/admin/server'
        },
        {
            title: 'Settings',
            icon: <Settings sx={{ fontSize: '30px' }} />,
            route: '/admin/settings'
        },

    ]

    const handelLogout = (e) => {
        e.preventDefault()
        logout()
    }

    useEffect(() => {

        if (logoutResponse.status === 'fulfilled') {
            setUserInfo('')
            navigate('/login')
        }


    }, [logoutResponse])

    return (
        <SidebarConatiner>

            <Box display='flex' flexDirection={'column'} gap='10px' alignItems={'center'} justifyContent='center' borderBottom={'1px solid #e0e0e0'} paddingBottom='20px'>
                <Box component='img' src={logo} height='100px' width='100px' borderRadius='50px' sx={{
                    transition: 'all 1.6s ease-in-out',
                    '&:hover': {
                        transform: 'rotateY(360deg)',
                    }
                }} />
                <Typography fontWeight={'bold'} textAlign='center' fontSize='24px'>Zema <br /> Entertainment</Typography>
            </Box>

            <Box display='flex' flexDirection={'column'} gap='0.7rem' marginTop={'30px'}>
                {myRoutes.map((item, i) => (
                    <NavLink key={i}
                        to={`${item.route}`}
                        className={({ isActive }) => (isActive || UserProfile && item.title === 'Users' ? 'active-link' : 'unactive-link')}
                        onClick={handleOpen}
                    >
                        {item.icon}
                        <Typography>{item.title}</Typography>
                    </NavLink>
                ))}
                <Box className="unactive-link">
                    <Logout sx={{ fontSize: '30px' }} />
                    <Typography component={'li'} sx={{ listStyleType: 'none' }} onClick={handelLogout}>Logout</Typography>
                </Box>
            </Box>
        </SidebarConatiner>
    )
}
export default Navigation;