import { AttachMoneyOutlined, DoneAllOutlined, Face, GroupAdd, LinkOutlined, LockOutlined, MailOutlineOutlined, Visibility, VisibilityOff } from '@mui/icons-material'
import { Box, Button, CircularProgress, IconButton, InputAdornment, Step, StepConnector, stepConnectorClasses, StepLabel, Stepper, styled, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import logo from '../../../assets/logo.jpeg'
import { StyledAuth } from '../style'
import { useRegisterUserMutation } from "../../../services/userApi";
import StatesContext from '../../../context/StatesContext'


const Register = () => {

  const context = useContext(StatesContext);
  const { setUseremail } = context;


  const [email, setemail] = useState('')
  const [password, setpassword] = useState('')
  const [name, setname] = useState('')

  const [showPassword, setshowPassword] = useState(false)

  const [registerUser, response] = useRegisterUserMutation();

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    registerUser({ email, password, name });
  };


  useEffect(() => {

    if (response.status === "fulfilled") {
      navigate(`/verification/${response.data.data.userId}`);
      setUseremail(email)

    }
  }, [response]);


  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 40,
      [theme.breakpoints.down('md')]: {
        top: 22
      }
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: 'white'
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: 'white'
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 4,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
      [theme.breakpoints.down('md')]: {
        height: 3
      }
    },
  }));

  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 80,
    height: 80,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
    transition: 'all 1.6s ease-in-out',
    '&:hover': {
      transform: 'rotateY(360deg)',
    },
    [theme.breakpoints.down('md')]: {
      width: 50,
      height: 50,
    },

  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <GroupAdd sx={{ fontSize: { md: '40px' } }} />,
      2: <AttachMoneyOutlined sx={{ fontSize: { md: '40px' } }} />,
      3: <LinkOutlined sx={{ fontSize: { md: '40px' } }} />,
      4: <DoneAllOutlined sx={{ fontSize: { md: '40px' } }} />,
    };

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  const steps = ['Create an account', 'Buy Package', 'Give stream Key & Live Video Url', 'You are ready to stream'];



  return (
    <StyledAuth>
      <Box flex={{ md: '0.55', lg: '0.35' }}
        bgcolor='white'
        borderRadius={'13px'}
        boxShadow='0 0 10px rgba(0,0,0,0.1)'
        display='flex'
        justifyContent={'center'}
        alignItems='center'
        padding='40px 0'
        marginTop={{ xs: '30px', md: '0' }}
      >
        <Box display='flex' gap='10px' flexDirection={'column'} width='100%' alignItems='center'>
          <Box component={Link} to='/' sx={{
            cursor: 'pointer', width: '100px', borderRadius: '50px',
            transition: 'all 1.6s ease-in-out',
            '&:hover': {
              transform: 'rotateY(360deg)',
            }
          }}>
            <Box component='img' src={logo} width='100px' borderRadius={'50px'} sx={{ objectFit: 'center' }} />
          </Box>
          <Box>
            <Typography fontWeight={'bold'} marginTop={'15px'} fontSize='22px'>Welcome</Typography>
          </Box>
          <Box component='form' onSubmit={handleSubmit} width='86%' display='flex' gap='10px' flexDirection={'column'} alignItems='center'>
            <TextField
              placeholder='Enter Your Name'
              className='email-feild'
              id='Name'
              required
              fullWidth
              onChange={(e) => setname(e.target.value)}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontSize: 15,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <Face sx={{ fontSize: '25px' }} />
                  </InputAdornment>
                )
              }}
              variant="standard"
            />
            <TextField
              placeholder='Enter Your Email'
              className='email-feild'
              id='Email'
              required
              fullWidth
              type={'email'}
              onChange={(e) => setemail(e.target.value)}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontSize: 15,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlineOutlined sx={{ fontSize: '25px' }} />
                  </InputAdornment>
                )
              }}
              variant="standard"
            />
            <Box sx={{ position: 'relative', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <TextField
                placeholder='Enter Your Password'
                className='email-feild'
                id='Email'
                fullWidth
                required
                type={showPassword ? 'text' : 'password'}
                onChange={(e) => setpassword(e.target.value)}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    fontSize: 15,
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlined sx={{ fontSize: '25px' }} />
                    </InputAdornment>
                  )
                }}
                variant="standard"
              />
              <IconButton sx={{ position: 'absolute', right: '0', top: '4px' }} disableRipple onClick={() => setshowPassword(!showPassword)}>
                {showPassword ? <VisibilityOff sx={{ fontSize: '23px' }} /> : <Visibility sx={{ fontSize: '23px' }} />}
              </IconButton>

            </Box>
            {response.error && (

              <Box display='flex' justifyContent={'flex-start'} width='100%'>
                <Typography color='red' fontSize='15px' fontWeight={'500'}>
                  {response.error.data.message}
                </Typography>
              </Box>

            )}
            <Button variant='contained' type='submit' sx={{ width: '100%', textTransform: 'none', marginTop: '15px', borderRadius: '10px' }}>
              {response.isLoading ? <CircularProgress sx={{ color: 'white' }} /> : 'Sign up'}
            </Button>
          </Box>
          <Box display={'flex'} alignItems='center' marginTop={'20px'} gap='5px'>
            <Typography color='#757575' fontSize='17px'>Already have an account?</Typography>
            <Typography component={Link} color='#1976d2' to='/login' sx={{ fontSize: '17px' }}>Login</Typography>
          </Box>
          <Box display={'flex'} alignItems='center' gap='5px'>
            <Typography color='#757575' fontSize='17px'>If you want to learn</Typography>
            <Typography component={Link} color='#d32f2f' to='/' sx={{ fontSize: '19px' }}>how it works</Typography>
          </Box>
        </Box>
      </Box>


      <Box flex={'1'}
        display='flex'
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems='center'
        padding={{ md: '0 2em' }}
      >
        <Typography fontWeight={'bold'} color='white' textAlign='center' fontSize={{ xs: '20px', sm: '30px', md: '35px' }}>Unlock the new Technique of Growing <br /> Subscribers & Earn 13x More</Typography>
        <Box marginTop={{ xs: '30px', sm: '40px', md: '60px' }}>
          <Stepper alternativeLabel activeStep={3} connector={<ColorlibConnector />}>
            {steps.map((label) => {

              let x
              if (label === 'Create an account') {
                x = '/register'
              } else if (label === 'Buy Package') {
                x = '/#plans'
              } else {
                x = ''
              }

              return <Step key={label}
                component={x && Link}
                to={x}
                sx={{ textDecoration: 'none' }}

              >
                <StepLabel
                  StepIconComponent={ColorlibStepIcon}
                  sx={{
                    '.MuiStepLabel-label': {
                      fontSize: { xs: '10px', sm: '17px', md: '20px' },
                      fontWeight: 'bold !important',
                      color: 'white !important'
                    }
                  }}
                >{label}</StepLabel>
              </Step>
            })}
          </Stepper>
        </Box>
      </Box>
      <Box
        sx={{
          position: { md: 'absolute' },
          right: '15px',
          bottom: '15px',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Button
          variant='contained' color='error'
          className='blob-btn'
          sx={{
            borderRadius: '30px',
            fontSize: '16px',
            backgroundColor: 'white !important',
            color: 'black !important',
            textTransform: 'none',
            transition: 'all 0.7s ease-in-out',
            '&:hover': {
              color: 'white !important',
              transform: 'scale(0.9)'
            }
          }}
          onClick={() => window.open('https://codezoone.netlify.app')}
        >
          Developed by CodeZone
          <span class="blob-btn__inner">
            <span class="blob-btn__blobs">
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
            </span>
          </span>

        </Button>

      </Box>
    </StyledAuth>
  )
}

export default Register