import React, { useContext, useEffect, useState } from 'react'
import { Cancel, CreditCard, Event, VpnKey } from '@mui/icons-material';
import { Box, Button, CircularProgress, Dialog, IconButton, Typography } from '@mui/material'
import {
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import axios from 'axios'

import { StyledCheckout } from './style';
import StatesContext from '../../../../context/StatesContext';
import { useNavigate } from 'react-router';
import { useBasicPackageMutation, useCustomPackageMutation, useDiamondPackageMutation, useStandardPackageMutation } from '../../../../services/userApi';

const Checkout = ({ openCheckout, setopenCheckout, price, packageName, totalDays, quantity, customPackage }) => {

    const context = useContext(StatesContext)
    const { userInfo, setisUserUpdate, setispkgbuyed } = context

    const [basic, basicResponse] = useBasicPackageMutation()
    const [standard, standardResponse] = useStandardPackageMutation()
    const [diamond, diamondResponse] = useDiamondPackageMutation()
    const [custom, customResponse] = useCustomPackageMutation()

    const stripe = useStripe()
    const elements = useElements()
    const navigate = useNavigate()

    const [error, seterror] = useState()
    const [loading, setloading] = useState(false)

    const paymentSubmit = async (e) => {

        e.preventDefault()

        setloading(true)

        const paymentData = {
            amount: price,
        }

        try {

            const config = {
                headers: {
                    "Content-Type": "application/json",
                }
            }

            const { data } = await axios.post(
                'https://myserver.zemas.live/api/payment',
                paymentData,
                config

            )

            const client_secret = data.client_secret

            if (!stripe || !elements) return

            const result = await stripe.confirmCardPayment(client_secret, {
                payment_method: {
                    card: elements.getElement(CardNumberElement),
                    billing_details: {
                        name: userInfo.name,
                        email: userInfo.email,

                    }
                }
            })

            if (result.error) {
                setloading(false)
                seterror(result.error.message)
            } else {
                if (result.paymentIntent.status === 'succeeded') {

                    if (packageName === 'basic') {
                        basic()
                    }
                    if (packageName === 'standard') {
                        standard()
                    }
                    if (packageName === 'diamond') {
                        diamond()
                    }
                    if (packageName === 'custom') {
                        custom({ price, totalDays, quantity, customPackage })
                    }



                } else {
                    seterror('There is some issue while processing payment')
                }
            }

        } catch (error) {
            setloading(false)
            seterror(error)

        }
    }

    useEffect(() => {
        if (basicResponse.status === 'fulfilled' || standardResponse.status === 'fulfilled' || diamondResponse.status === 'fulfilled' || customResponse.status === 'fulfilled') {
            navigate('/dashboard')
            setisUserUpdate(true)
            setispkgbuyed(true)
            setloading(false)

        }
/* eslint-disable react-hooks/exhaustive-deps */

    }, [basicResponse, standardResponse, diamondResponse, customResponse])

    return (
        <Dialog
            open={openCheckout}
            onClose={() => setopenCheckout(false)}
            aria-labelledby="responsive-dialog-title"
            PaperProps={{
                sx: {
                    width: { xs: '90%', sm: '400px' },
                    borderRadius: '20px'
                },
            }}

        >
            <StyledCheckout padding='1rem 1rem' >

                <Box display={'flex'} justifyContent='flex-end' >
                    <IconButton className='cancel-icon' onClick={() => setopenCheckout(false)}>
                        <Cancel />
                    </IconButton>
                </Box>
                <Box marginTop='20px'
                    sx={{
                        backgroundColor: '#ef5350',
                        borderRadius: '10px',
                        padding: '7px 0'
                    }}>
                    <Typography fontWeight={'500'} fontSize='20px' color='white' textAlign='center'>
                        Checkout
                    </Typography>
                </Box>
                <Box component={'form'} onSubmit={paymentSubmit} margin='10px' marginTop={'50px'} display='flex' flexDirection={'column'} gap='20px'>
                    <Box display={'flex'} alignItems='center' border={'1px solid #e0e0e0'} borderRadius='10px' sx={{ transition: 'all 0.4s ease-in-out', '&:hover': { backgroundColor: '#f5f5f5' } }}>
                        <Box marginTop={'6px'} padding='2px 12px'><CreditCard sx={{ fontSize: '25px' }} /> </Box>
                        <Box width={'100%'} paddingLeft='5px'>    <CardNumberElement />  </Box>
                    </Box>
                    <Box display={'flex'} alignItems='center' border={'1px solid #e0e0e0'} borderRadius='10px' sx={{ transition: 'all 0.4s ease-in-out', '&:hover': { backgroundColor: '#f5f5f5' } }}>
                        <Box marginTop={'6px'} padding='2px 12px'><Event sx={{ fontSize: '25px' }} /> </Box>
                        <Box width={'100%'} paddingLeft='5px'>    <CardExpiryElement />  </Box>
                    </Box>
                    <Box display={'flex'} alignItems='center' border={'1px solid #e0e0e0'} borderRadius='10px' sx={{ transition: 'all 0.4s ease-in-out', '&:hover': { backgroundColor: '#f5f5f5' } }}>
                        <Box marginTop={'5px'} padding='2px 12px'><VpnKey sx={{ fontSize: '25px' }} /> </Box>
                        <Box width={'100%'} paddingLeft='5px'>    <CardCvcElement />  </Box>
                    </Box>
                    <Box>
                        {error && (
                            <Typography color='red' fontSize='18px' fontWeight={'500'}>
                                {error}
                            </Typography>
                        )}

                        <Button variant='contained' color='error'
                            sx={{
                                textTransform: 'none',
                                fontSize: 15,
                                width: '100%',
                                fontWeight: 'bold',
                                borderRadius: '10px',
                                transition: 'all 0.5s ease-in-out',
                                marginTop: '10px',
                                marginBottom: '10px'
                            }}
                            type='submit'
                        >
                            {loading ? <CircularProgress sx={{ color: 'white' }} size={27} /> : `Pay $${price}`}

                        </Button>
                    </Box>
                </Box>

            </StyledCheckout>

        </Dialog>
    )
}

export default Checkout