import { Box, Pagination, styled, Typography } from "@mui/material";

const StyledBox = styled(Box)(({ theme }) => ({

    "& .MuiOutlinedInput-input": {
        color: theme.palette.grey[900],
        backgroundColor: '#f2f7fb',
        transition: 'all 0.4s ease-in-out',
        fontFamily: 'Roboto',
        borderRadius: '20px',
        fontSize: '17px',

        '&:hover': {
            boxShadow: '0 0 25px #f2f7fb'
        },

    },
    "& .MuiInputLabel-root": {
        color: theme.palette.grey[500],
        fontSize: '17px',
        paddingTop: '6px',
        borderRadius: '20px',


    },
    '& .MuiSvgIcon-root': {
        transition: 'all 0.7s ease-in-out'
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
        borderRadius: '20px',
        color: theme.palette.grey[500]
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
        borderRadius: '20px',

    },


}))
const RegisteredUserCon = styled(Box)(({ theme }) => ({

    backgroundColor: '#F1EBEB',
    width: '100%',
    borderRadius: '20px',
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'auto',

}))

const StyledPagination = styled(Pagination)({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2.5em'
})

const NoProductHeading = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: '400',
    paddingBottom: '5px',
    borderBottom: '1px solid',
    color: 'rgba(0, 0, 0, 0.678)',
    [theme.breakpoints.down('sm')]: {

        width: '80%',
        marginBottom: '5em',
    },
    [theme.breakpoints.down('md')]: {

        marginTop: '2.5em',

    }
}))

export {
    RegisteredUserCon,
    StyledBox,
    StyledPagination,
    NoProductHeading
}