import { AppBar, Box, styled, Typography } from "@mui/material";
import Images from '../../../assets/bgWhite.png'

const StyledBox = styled(Box)(({ theme }) => ({

    '.blob-btn-my': {
        zIndex: 1,
        position: 'relative',
        transition: 'red 0.5s',
        marginTop: '20px',
        fontSize: '15px',
        textTransform: 'none',
        borderRadius: '23px',
    }
}))

export {
    StyledBox


}