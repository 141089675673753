import { Menu } from '@mui/icons-material'
import { Backdrop, Box, Grid, IconButton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion';
import { usePackageDeatilsQuery } from '../../../../services/userApi';
import { useParams } from 'react-router-dom';
import Loader from '../../../../Components/Loader';
import Logo from '../../../../assets/logo.jpeg'
import Header from '../Header/Header';
import Sidebar from '../../Sidebar/Sidebar'

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { StyledUser } from '../style';

const PackageDetailsAdmin = () => {

    const [open, setopen] = useState(false)
    const [startedTime, setstartedTime] = useState('')
    const [endedTime, setendedTime] = useState('')
    const [percentage, setpercentage] = useState()
    const [myPercentage, setmyPercentage] = useState('')
    const [packageName, setpackageName] = useState('')
    const [myPackageExpire, setmyPackageExpire] = useState('')

    const { packageId } = useParams()

    const { data, isFetching } = usePackageDeatilsQuery(packageId)


    useEffect(() => {

        if (data) {
            setpackageName(data.package.name)
        }

        if (data && data.package.startedAt) {

            let fullTime = new Date(data.package.startedAt)
            fullTime = fullTime.toLocaleTimeString()

            let firstTime = fullTime.slice(0, 5)
            let zone = fullTime.slice(8, 11)
            setstartedTime(firstTime + zone)

            var startDate = new Date(data.package.startedAt).getTime();
            var endDate = new Date(data.package.expiresAt).getTime()
            var todayDate = new Date().getTime();

            // Get the total possible timestamp value
            var total = endDate - startDate;

            // Get the current value
            var current = todayDate - startDate;

            // Get the percentage
            let x = (current / total) * 100

            x = x.toString()
            x = x.split('.')[0]
            x = Number(x)
            if (x <= 100) {
                setmyPercentage(x)
            } else {
                setmyPercentage(100)
            }
        }

        if (data && data.package.expiresAt) {

            setmyPackageExpire(data.package.expiresAt)

            let fullTime = new Date(data.package.expiresAt)
            fullTime = fullTime.toLocaleTimeString()

            let firstTime = fullTime.slice(0, 5)
            let zone = fullTime.slice(8, 11)
            setendedTime(firstTime + zone)

        }

    }, [data])


    let UserProfile = true

    return (
        <StyledUser >

            <Box display={{ xs: 'none', md: 'flex' }} >
                <Sidebar UserProfile={UserProfile} />
            </Box>
            <Box display={{ xs: 'flex', md: 'none' }} sx={{ position: 'fixed', top: '10px', left: '10px',zIndex: '1'  }}>
                <IconButton onClick={() => setopen(!open)} >
                    <Menu />
                </IconButton>
            </Box>
            <Backdrop open={open} onClick={() => setopen(false)} sx={{ zIndex: '1' }} />

            <Box>
                {open && (
                    <Box>
                        <motion.div

                            animate={{ x: [-300, 0] }}
                            transition={{ duration: 0.85, ease: 'easeOut' }}
                            className='myMenu'

                        >

                            <Sidebar open={open} setopen={setopen} UserProfile={UserProfile} />
                        </motion.div>

                    </Box>
                )}

            </Box>

            <Box marginLeft={{ md: '310px' }} className="user-con">
                {isFetching ? <Loader height={'100vh'} /> : (
                    <>
                        <Header packageName={packageName} myPackageExpire={myPackageExpire} setpercentage={setpercentage}/>

                        <Box marginTop='50px'>
                            <Grid container spacing={{ xs: 2.5, md: 1, lg: 6 }}>
                                <Grid item xs={12} sm={12} md={8}>
                                    <Box display='flex' flexDirection={'column'} gap='20px'>
                                        <Box bgcolor={'white'} padding={{ xs: '20px 15px', sm: '20px 33px' }} borderRadius={'20px'}>
                                            <Box display='flex' gap='10px' justifyContent={'space-between'}>
                                                <Box display='flex' flexDirection={'column'} gap='10px'>
                                                    <Typography fontSize={{ xs: '15px', sm: '17px', lg: '18px' }} fontWeight={'bold'}>Package Name</Typography>
                                                    <Typography fontSize={{ xs: '15px', sm: '17px', lg: '18px' }} fontWeight={'bold'}>Price</Typography>
                                                    <Typography fontSize={{ xs: '15px', sm: '17px', lg: '18px' }} fontWeight={'bold'}>No of Days</Typography>
                                                    <Typography fontSize={{ xs: '15px', sm: '17px', lg: '18px' }} fontWeight={'bold'}>No of Videos</Typography>
                                                    <Typography fontSize={{ xs: '15px', sm: '17px', lg: '18px' }} fontWeight={'bold'}>Status</Typography>
                                                    <Typography fontSize={{ xs: '15px', sm: '17px', lg: '18px' }} fontWeight={'bold'}>Started Date & Time</Typography>
                                                    <Typography fontSize={{ xs: '15px', sm: '17px', lg: '18px' }} fontWeight={'bold'}>Expiry Date & Time</Typography>
                                                </Box>
                                                <Box display='flex' flexDirection={'column'} gap='10px'>
                                                    <Typography fontSize={{ xs: '15px', sm: '17px', lg: '18px' }} fontWeight={'bold'} textAlign={'center'}>{data.package.name}</Typography>
                                                    <Typography fontSize={{ xs: '15px', sm: '17px', lg: '18px' }} fontWeight={'bold'} textAlign={'center'}>${data.package.price}</Typography>
                                                    <Typography fontSize={{ xs: '15px', sm: '17px', lg: '18px' }} fontWeight={'bold'} textAlign={'center'}>{data.package.days}</Typography>
                                                    <Typography fontSize={{ xs: '15px', sm: '17px', lg: '18px' }} fontWeight={'bold'} textAlign={'center'}>{data.package.videos}</Typography>
                                                    <Typography fontSize={{ xs: '15px', sm: '17px', lg: '18px' }} fontWeight={'bold'} color={`${data.package.status === 'Active' ? 'green' : 'red'}`} textAlign={'center'}>{data.package.status}</Typography>
                                                    <Typography fontSize={{ xs: '15px', sm: '15px', lg: '18px' }} fontWeight={'bold'} color={!data.package.startedAt && '#AA336A'} textAlign={'center'}>{data.package.startedAt ? `${new Date(data.package.startedAt).toDateString()}, ${startedTime}` : 'Processing'}</Typography>
                                                    <Typography fontSize={{ xs: '15px', sm: '15px', lg: '18px' }} fontWeight={'bold'} color={!data.package.expiresAt && '#AA336A'} textAlign={'center'}>{data.package.expiresAt ? `${new Date(data.package.expiresAt).toDateString()}, ${endedTime}` : 'Processing'}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box bgcolor='white' borderRadius={'20px'} padding='20px'>
                                            <Typography fontWeight='bold' fontSize='25px'>Package Used</Typography>
                                            <Box display='flex' justifyContent='center' marginTop='20px'>
                                                <Box width='120px' height='120px'>
                                                    <CircularProgressbar value={myPercentage ? myPercentage : 0} text={`${myPercentage ? myPercentage : 0}%`} strokeWidth={3}
                                                        styles={buildStyles({

                                                            // Colors
                                                            textColor: '#ef5350',
                                                            trailColor: '#d6d6d6',
                                                            pathColor: `#ef5350`,

                                                        })}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <Box>
                                        <Box bgcolor={'white'} padding='20px 33px' borderRadius={'20px'}>
                                            <Box display='flex' justifyContent={'center'}>
                                                <Box component='img' src={Logo} height='90px' width='90px' borderRadius={'50px'} sx={{
                                                    transition: 'all 1.6s ease-in-out',
                                                    '&:hover': {
                                                        transform: 'rotateY(360deg)',
                                                    }
                                                }} />
                                            </Box>
                                            <Typography marginTop='10px' fontSize='20px' fontWeight={'bold'} textAlign='center'>Zema <br /> Entertainment</Typography>
                                        </Box>
                                        <Box bgcolor={'white'} padding='20px 33px' borderRadius={'20px'} marginTop='20px'>
                                            <Box paddingBottom={'15px'} borderBottom='1px solid #bdbdbd'>
                                                <Typography fontWeight='bold' textAlign='center' fontSize={'22px'}>Order Summary</Typography>
                                            </Box>
                                            <Box display={'flex'} justifyContent='space-between' padding={'15px 0'} borderBottom='1px solid #bdbdbd'>
                                                <Box display='flex' flexDirection='column' gap='5px'>
                                                    <Typography fontSize={'18px'} fontWeight='bold' >Name</Typography>
                                                    <Typography fontSize={'18px'} fontWeight='bold' >Days</Typography>
                                                    <Typography fontSize={'18px'} fontWeight='bold' >Videos</Typography>
                                                </Box>
                                                <Box display='flex' flexDirection='column' gap='5px'>
                                                    <Typography fontSize={'18px'} textAlign='center' fontWeight='bold'>{data.package.name}</Typography>
                                                    <Typography fontSize={'18px'} textAlign='center' fontWeight='bold'>{data.package.days}</Typography>
                                                    <Typography fontSize={'18px'} textAlign='center' fontWeight='bold'>{data.package.videos}</Typography>
                                                </Box>
                                            </Box>
                                            <Box display='flex' marginTop={'10px'} justifyContent={'space-between'} >
                                                <Typography fontSize={'19px'} fontWeight='bold'>Total Price:</Typography>
                                                <Typography fontSize={'19px'} paddingRight='19px' fontWeight='bold'>${data.package.price}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                )}
            </Box>
        </StyledUser >

    )
}

export default PackageDetailsAdmin