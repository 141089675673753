import { Alert, Backdrop, Box, Button, Dialog, Grid, IconButton, InputAdornment, Snackbar, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { motion } from 'framer-motion';
import Sidebar from '../Sidebar/Sidebar';
import { Add, Cancel, Menu, Remove, TitleOutlined } from '@mui/icons-material';
import Header from '../Header/Header';
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Checkout from '../Plans/Checkout/Checkout';
import { StyledMakePlans, StyledInputCart, StyledButtonCart } from './style';

const MakePlans = () => {

    const [open, setopen] = useState(false)
    const [price, setprice] = useState('')
    const [openCheckout, setopenCheckout] = useState(false)
    const [largeOpen, setlargeOpen] = useState(true)
    const [animation, setanimation] = useState([])
    const [sidebarAnimation, setsidebarAnimation] = useState([])
    const [activebutton, setActivebutton] = useState('week')
    const [openAlert, setOpenAlert] = useState(false);
    const [totalPrice, settotalPrice] = useState('')
    const [totalDays, settotalDays] = useState()
    const [openAdd, setopenAdd] = useState(false)
    const [customPackage, setcustomPackage] = useState('')

    const [quantity, setquantity] = useState(1)
    const [Days, setDays] = useState(1)
    const [months, setmonths] = useState(1)
    const [daysprice, setDaysprice] = useState(0)
    const [mxg, setmxg] = useState('')
    const [videosPrice, setvideosPrice] = useState(0)

    const datakey = 'pk_live_51M6id0A40t8dD6c6AOi1EmVw6mfcOt4uzbKDk4XoI2YYGwMArT72MCFftNEp2KDe2Wsh0BYNyeJLksThZjVMT4k600XPmmczia'
    const [stripePromise, setStripePromise] = useState(() => loadStripe(datakey))


    const handleClose = (e) => {
        if (e === 'clickaway') {
            return;
        }
        setOpenAlert(false);
    };

    const increaseQuantity = () => {

        if (activebutton === 'week') {

            setDays(Days + 1)
        } else {
            setmonths(months + 1)
        }
    }

    const decreaseQuantity = () => {

        if (activebutton === 'week') {

            if (Days <= 0) return;
            setDays(Days - 1)
            setvideosPrice(0)
            setquantity(0)
        } else {
            if (months <= 0) return;
            setmonths(months - 1)
            setvideosPrice(0)
            setquantity(0)

        }

    }
    const increaseVideoQuantity = () => {

        if (activebutton === 'week') {
            if (Days < 8 && quantity === 2) {
                setmxg(`The maximum limit of videos in ${Days} days is 2`)
                setOpenAlert(true)
            } else if (Days < 15 && quantity === 4) {
                setmxg(`The maximum limit of videos in ${Days} days is 4`)
                setOpenAlert(true)
            } else if (Days < 22 && quantity === 6) {
                setmxg(`The maximum limit of videos in ${Days} days is 6`)
                setOpenAlert(true)
            } else if (Days > 29 && quantity === 8 || quantity === 8) {
                setmxg(`The maximum limit of videos is 8`)
                setOpenAlert(true)
            } else if (quantity < 8) {
                setquantity(quantity + 1)
                setvideosPrice(videosPrice + 0.5)
            }
        } else {
            if (quantity === 8) {
                setmxg(`The maximum limit of videos is 8`)
                setOpenAlert(true)
            } else {
                setquantity(quantity + 1)
                setvideosPrice(videosPrice + 0.5)
            }
        }

    }

    const decreaseVideoQuantity = () => {

        if (quantity <= 0) return;
        setquantity(quantity - 1)
        setvideosPrice(videosPrice - 0.5)

    }

    useEffect(() => {

        setDays(0)
        setmonths(0)
        setquantity(0)
        settotalDays(0)
        setvideosPrice(0)

    }, [activebutton])

    useEffect(() => {

        if (activebutton === 'week') {
            setDaysprice(Days)
            settotalDays(Days)
        } else {
            setDaysprice(months * 30)
            settotalDays(months * 30)
        }

    }, [Days, months, activebutton])

    useEffect(() => {

        settotalPrice(daysprice + videosPrice)

    }, [daysprice, videosPrice])


    return (
        <StyledMakePlans >

            <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center', }}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }} >
                    {mxg}
                </Alert>
            </Snackbar>

            <Backdrop open={open} onClick={() => setopen(false)} sx={{ zIndex: '1' }} />

            <Box display={{ xs: 'flex', md: 'none', position: 'fixed', top: '0', left: '0',zIndex: '1' }} sx={{ padding: '15px' }}>
                <IconButton onClick={() => setopen(!open)} sx={{ color: 'black', zIndex: '1' }}>
                    <Menu />
                </IconButton>
            </Box>

            <Box>
                {open && (
                    <Box>
                        <motion.div

                            animate={{ x: [-300, 0] }}
                            transition={{ duration: 0.85, ease: 'easeOut' }}
                            className='myMenu'
                        >
                            <Sidebar open={open} setopen={setopen} />
                        </motion.div>

                    </Box>
                )}

            </Box>

            <Box display={{ xs: 'none', md: 'flex' }} >
                <motion.div
                    animate={{ x: sidebarAnimation }}
                    transition={{ duration: 0.85, ease: 'easeOut' }}
                    className='myMenu'
                >
                    <Sidebar largeOpen={largeOpen} setlargeOpen={setlargeOpen} setanimation={setanimation} setsidebarAnimation={setsidebarAnimation} />
                </motion.div>
            </Box>

            <Box marginLeft={{ md: largeOpen ? '300px' : '0' }}>
                <motion.div
                    animate={{ x: animation }}
                    transition={{ duration: 0.85, ease: 'easeOut' }}
                >
                    {!largeOpen && (
                        <Box display={{ xs: 'none', md: 'flex' }} paddingTop='3px' paddingLeft={'3px'}>
                            <IconButton onClick={() => {
                                setlargeOpen(true)
                                setanimation([-300, 0])
                                setsidebarAnimation([-300, 0])
                            }}>
                                <Menu />
                            </IconButton>
                        </Box>
                    )}

                    <Box padding={!largeOpen && { sm: '0.4rem 1.5rem' }}>
                        <Box padding={largeOpen && '1.5rem'} paddingBottom='0'>
                            <Header />
                        </Box>
                        <Box padding={largeOpen && { xs: '0 10px', sm: '0 1.5rem' }} >
                            <Box bgcolor={'white'} marginTop='35px' borderRadius='10px' padding={{ xs: '20px 10px', sm: '20px' }}>
                                <Box display='flex' flexDirection={{ xs: 'column', sm: 'row' }} gap='20px' justifyContent={{ xs: 'center', sm: 'space-between' }} alignItems='center'>
                                    <Typography fontWeight={'bold'} fontSize='30px'>Time Period</Typography>
                                    <Box>
                                        <Button
                                            onClick={(e) => {
                                                setActivebutton('week')
                                            }}
                                            className={activebutton === 'week' ? 'activeButton' : 'unactiveButton'}
                                            style={{
                                                borderTopLeftRadius: '12px',
                                                borderBottomLeftRadius: '12px',
                                                borderRight: 'none'
                                            }}
                                            disableRipple
                                        >
                                            Days
                                        </Button>
                                        <Button
                                            onClick={(e) => {
                                                setActivebutton('monthly')
                                            }}
                                            className={activebutton === 'monthly' ? 'activeButton' : 'unactiveButton'}
                                            disableRipple
                                            style={{
                                                borderTopRightRadius: '12px',
                                                borderBottomRightRadius: '12px',
                                                borderLeft: 'none'

                                            }}
                                        >
                                            Monthly
                                        </Button>
                                    </Box>
                                </Box>

                                <Box marginTop={'20px'}>
                                    <Box backgroundColor='#424242' display={'flex'} padding={{ xs: '10px  0.3em', sm: '10px 0.7em' }} borderRadius='15px'>
                                        <Box sx={{ flex: { xs: '2', sm: '2' } }}><Typography color='white' fontWeight={'bold'} fontSize={{ xs: '13px', sm: '18px' }}>Resource</Typography></Box>
                                        <Box flex={'3'} display='flex' justifyContent='flex-start'><Typography color='white' marginLeft={'14px'} fontWeight={'bold'} fontSize={{ xs: '13px', sm: '18px' }}>Counts</Typography></Box>
                                        <Box flex={'1'} display='flex' justifyContent='flex-end'><Typography color='white' fontWeight={'bold'} fontSize={{ xs: '13px', sm: '18px' }}>Subtotal</Typography></Box>
                                    </Box>
                                    <Box marginTop='30px' marginBottom='40px'  >

                                        <Box display='flex' alignItems={'center'} marginTop={'0.5em'} >
                                            <Box flex={{ xs: '2', sm: '2' }} paddingLeft={{ xs: '0.2em', sm: '0.5em' }}>
                                                <Box display='flex'>
                                                    <Typography fontWeight='bold' fontSize={{ xs: '12px', sm: '20px' }}>Number of {activebutton === 'week' ? 'Days' : 'Months'}</Typography>
                                                </Box>
                                            </Box>
                                            <Box flex='3' display='flex' justifyContent={'flex-start'} alignItems='center'>
                                                <Box display={'flex'} alignItems='center'>
                                                    <StyledButtonCart onClick={decreaseQuantity}
                                                        sx={{
                                                            borderTopRightRadius: '20px',
                                                            borderBottomRightRadius: '20px',
                                                        }}
                                                    ><Remove sx={{ fontSize: '20px' }} /></StyledButtonCart>

                                                    <StyledInputCart ><Typography fontWeight={'bold'}>{activebutton === 'week' ? Days : months}</Typography></StyledInputCart>
                                                    <StyledButtonCart onClick={increaseQuantity}
                                                        sx={{
                                                            borderTopLeftRadius: '20px',
                                                            borderBottomLeftRadius: '20px'
                                                        }}
                                                    ><Add sx={{ fontSize: '20px' }} /></StyledButtonCart>
                                                </Box>
                                            </Box>

                                            <Box flex='1' display='flex' justifyContent={'flex-end'} alignItems='center' >
                                                <Typography paddingRight={{ xs: '0.2em', sm: '0.7em' }} fontWeight='bold' fontSize={{ xs: '15px', sm: '25px' }} fontFamily={'Roboto'}>${daysprice}</Typography>

                                            </Box>
                                        </Box>
                                        <Box display='flex' alignItems={'center'} marginTop={'0.5em'} >
                                            <Box flex={{ xs: '2', sm: '2' }} paddingLeft={{ xs: '0.2em', sm: '0.5em' }}>
                                                <Box display='flex'>
                                                    <Typography fontWeight='bold' fontSize={{ xs: '12px', sm: '20px' }}>Number of Videos</Typography>
                                                </Box>
                                            </Box>
                                            <Box flex='3' display='flex' justifyContent={'flex-start'} alignItems='center'>
                                                <Box display={'flex'} alignItems='center'>
                                                    <StyledButtonCart onClick={decreaseVideoQuantity}
                                                        sx={{
                                                            borderTopRightRadius: '20px',
                                                            borderBottomRightRadius: '20px',
                                                        }}
                                                    ><Remove sx={{ fontSize: '20px' }} /></StyledButtonCart>

                                                    <StyledInputCart ><Typography fontWeight={'bold'}>{quantity}</Typography></StyledInputCart>
                                                    <StyledButtonCart onClick={increaseVideoQuantity}
                                                        sx={{
                                                            borderTopLeftRadius: '20px',
                                                            borderBottomLeftRadius: '20px'
                                                        }}
                                                    ><Add sx={{ fontSize: '20px' }} /></StyledButtonCart>
                                                </Box>
                                            </Box>

                                            <Box flex='1' display='flex' justifyContent={'flex-end'} alignItems='center' >
                                                <Typography paddingRight={{ xs: '0.2em', sm: '0.7em' }} fontWeight='bold' fontSize={{ xs: '15px', sm: '25px' }} fontFamily={'Roboto'}>${videosPrice}</Typography>

                                            </Box>
                                        </Box>

                                    </Box>
                                    <Box display='flex' paddingRight={{ xs: '0', sm: '0.7em' }}>
                                        <Box flex={{ xs: '0', sm: '5', md: '6' }}></Box>
                                        <Box flex='2' marginBottom='1em' borderTop='5px solid #424242'>
                                            <Box display='flex' justifyContent={'space-between'} marginTop='1em'>
                                                <Typography fontWeight={'bold'} fontFamily={'Roboto'}>Total Price:</Typography>
                                                <Typography fontWeight={'bold'} fontFamily={'Roboto'}>${totalPrice}</Typography>
                                            </Box>
                                            <Box display='flex' justifyContent={{ xs: 'center', sm: 'flex-end' }} marginTop='1rem'>
                                                <Button variant="contained" color='error'
                                                    sx={{
                                                        textTransform: 'none',
                                                        fontSize: 15,
                                                        width: '100%',
                                                        borderRadius: '20px',
                                                        transition: 'all 0.5s ease-in-out',

                                                    }}
                                                    disabled={videosPrice === 0 || daysprice === 0 ? true : false}
                                                    onClick={() => setopenAdd(true)}
                                                >Buy Package</Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </motion.div>
            </Box>

            {openCheckout && (
                <Elements stripe={stripePromise}>
                    <Checkout openCheckout={openCheckout} setopenCheckout={setopenCheckout} price={totalPrice} totalDays={totalDays} packageName={'custom'} quantity={quantity} customPackage={customPackage} />
                </Elements>
            )}

            {/* Adding Custom Name */}

            <Dialog
                open={openAdd}
                onClose={() => setopenAdd(false)}
                aria-labelledby="responsive-dialog-title"
                PaperProps={{
                    sx: {
                        width: { xs: '94%', sm: '400px' },
                        borderRadius: '20px'
                    }
                }}

            >
                <Box padding='25px' >

                    <Box display={'flex'} justifyContent='flex-end' >
                        <IconButton className='cancel-icon' onClick={() => setopenAdd(false)}>
                            <Cancel />
                        </IconButton>
                    </Box>
                    <Box marginTop='20px'
                        sx={{
                            backgroundColor: '#ef5350',
                            borderRadius: '10px',
                            padding: '7px 0'
                        }}>
                        <Typography fontWeight={'500'} fontSize='20px' color='white' textAlign='center'>
                            Custom Package Name
                        </Typography>
                    </Box>

                    <Box component={'form'} onSubmit={(e) => {
                        e.preventDefault()
                        setopenCheckout(true)
                        setopenAdd(false)

                    }}
                        width='90%' margin='0 auto'>
                        <Box display='flex' flexDirection={'column'} gap='20px' margin='10px 0' marginTop='30px'>
                            <TextField
                                placeholder='Enter Custom Name'
                                id='Email'
                                required
                                fullWidth
                                onChange={(e) => setcustomPackage(e.target.value)}
                                InputProps={{
                                    disableUnderline: true,
                                    style: {
                                        fontSize: 15,
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <TitleOutlined sx={{ fontSize: '25px' }} />
                                        </InputAdornment>
                                    )
                                }}
                                sx={{

                                    '& .MuiInputBase-root': {
                                        border: '1px solid #e0e0e0',
                                        padding: '7px 10px',
                                        borderRadius: '10px',
                                        transition: 'all 0.4s ease-in-out',
                                        backgroundColor: '#f5f5f5',
                                    },
                                    '& .Mui-focused': {
                                        backgroundColor: 'black !important',
                                        color: 'white',
                                        '& .MuiSvgIcon-root': {
                                            color: 'white'
                                        },
                                        '& ::placeholder': {
                                            color: 'white',
                                            opacity: '0.7'
                                        }
                                    },

                                }}
                                variant="standard"
                            />

                        </Box>
                        <Box display='flex' gap='10px' justifyContent={'flex-end'}>

                            <Button variant='contained'

                                sx={{
                                    textTransform: 'none',
                                    fontSize: 15,
                                    borderRadius: '10px',
                                    transition: 'all 0.5s ease-in-out',
                                    marginTop: '20px',
                                    marginBottom: '10px',
                                    color: 'black',
                                    backgroundColor: '#e0e0e0',
                                    '&:hover': {
                                        backgroundColor: '#e0e0e0',
                                        color: 'black',

                                    }
                                }}

                                onClick={() => {
                                    setopenCheckout(true)
                                    setopenAdd(false)
                                }}

                            >
                                Skip
                            </Button>
                            <Button variant='contained' color='error'

                                sx={{
                                    textTransform: 'none',
                                    fontSize: 15,
                                    borderRadius: '10px',
                                    transition: 'all 0.5s ease-in-out',
                                    marginTop: '20px',
                                    marginBottom: '10px'
                                }}
                                type='submit'

                            >
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </Box>

            </Dialog>



        </StyledMakePlans >
    )
}

export default MakePlans