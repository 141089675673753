import { Alert, Backdrop, Box, IconButton, Typography } from "@mui/material";
import { motion } from 'framer-motion';
import { Menu, Settings } from "@mui/icons-material";
import Sidebar from '../Sidebar/Sidebar'
import { RegisteredUserCon, StyledUser } from "../Users/style";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useServerDetailQuery, useServerUserQuery } from "../../../services/userApi";
import Loader from '../../../Components/Loader'
import { NoProductHeading } from "../Users/PackageHistory/style";


const ServerUsers = () => {

    const [open, setopen] = useState(false)
    const [openAlert, setOpenAlert] = useState(false);

    const { state } = useLocation()

    const navigate = useNavigate()

    const { data, isFetching } = useServerUserQuery(state)

    return (
        <StyledUser>


            <Box display={{ xs: 'none', md: 'flex' }} >
                <Sidebar />
            </Box>
            <Box display={{ xs: 'flex', md: 'none' }} sx={{ position: 'fixed', top: '10px', left: '10px', zIndex: '1' }}>
                <IconButton onClick={() => setopen(!open)} >
                    <Menu />
                </IconButton>
            </Box>
            <Backdrop open={open} onClick={() => setopen(false)} />

            <Box>
                {open && (
                    <Box>
                        <motion.div

                            animate={{ x: [-300, 0] }}
                            transition={{ duration: 0.85, ease: 'easeOut' }}
                            className='myMenu'

                        >

                            <Sidebar open={open} setopen={setopen} />
                        </motion.div>

                    </Box>
                )}

            </Box>

            <Box marginLeft={{ md: '310px' }} className="user-con">
                <Box bgcolor={'white'} borderRadius='35px' padding='1rem' minHeight={{ md: '80vh', lg: '88vh' }}>
                    <Box>
                        <Typography fontWeight={'bold'} fontSize={{ xs: '25px', md: '35px' }} >Server Users</Typography>

                    </Box>
                    <Box>
                        {isFetching ? <Loader height={'75vh'} /> : (
                            <Box>
                                <Box display='flex' flexDirection={'column'} gap='1rem' marginTop={'10px'} >
                                    {data.user.length > 0 && data.user.map((item, i) => {

                                        let myDate = new Date(item.createdAt).toDateString()

                                        return <RegisteredUserCon key={i} bgcolor={item.status === 'Warning' ? 'rgba(255,0,0,0.2)' : '#F1EBEB'}>
                                            <Box display='flex' alignItems={'center'} gap={{ xs: '20px', sm: '30px' }}>
                                                <Box component={'img'} src={item.avatar.url} height={{ xs: '70px', sm: '90px' }} width={{ xs: '70px', sm: '90px' }} borderRadius={'50px'} sx={{ objectFit: 'cover' }} />
                                                <Box display='flex' flexDirection='column'>
                                                    <Box>
                                                        <Typography fontWeight={'bold'} fontSize={{ xs: '20px', sm: '25px' }}>{item.name}</Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography fontWeight={'bold'} fontSize={{ xs: '15px', sm: '20px' }}>{item.email}</Typography>
                                                    </Box>
                                                    <Box display={'flex'} gap='10px'>
                                                        <Typography fontWeight={'bold'} fontSize={{ xs: '15px', sm: '20px' }}>Status:</Typography>
                                                        <Typography fontWeight={'bold'} fontSize={{ xs: '15px', sm: '20px' }} color={item.status === 'Warning' ? 'yellow' : item.status === 'Pending' || item.status === 'Processing' ? '#AA336A' : 'green'}
                                                        >{item.status === 'Processing' ? 'Updating' : item.status}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <IconButton onClick={() => navigate(`/UserProfile/${item._id}`)}>
                                                    <Settings />
                                                </IconButton>
                                            </Box>
                                        </RegisteredUserCon>

                                    })}
                                </Box>

                            </Box>
                        )}

                        {!isFetching && data && data.user.length === 0 && (
                            <Box display={'flex'} justifyContent='center' marginTop={{ md: '5em' }}>
                                <NoProductHeading>No User Found</NoProductHeading>
                            </Box>
                        )}

                    </Box>
                </Box>
            </Box>

        </StyledUser >
    )
}
export default ServerUsers;