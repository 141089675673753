import { Alert, Backdrop, Box, Button, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select, Snackbar, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { motion } from 'framer-motion';
import Sidebar from '../Sidebar/Sidebar';
import { StyledNavbar } from '../Dashboard/style';
import { Menu } from '@mui/icons-material';
import StatesContext from '../../../context/StatesContext';
import { SettingsContainer } from './style';
import { Country } from 'country-state-city'
import { useUpdatePasswordMutation, useUpdateUserMutation } from '../../../services/userApi';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: '250px',
            position: 'absolute',
            bottom: '0',
            right: '-100%',

        },
    },
    disableScrollLock: true,
    disableUnderline: true,

};

const Settings = () => {

    const context = useContext(StatesContext)
    const { setUserInfo, userInfo } = context

    const [open, setopen] = useState(false)
    const [largeOpen, setlargeOpen] = useState(true)
    const [animation, setanimation] = useState([])
    const [sidebarAnimation, setsidebarAnimation] = useState([])

    const [name, setname] = useState('')
    const [country, setcountry] = useState('')
    const [avatarPreview, setavatarPreview] = useState('')
    const [avatar, setavatar] = useState('')
    const [newPassword, setnewPassword] = useState()
    const [phone, setphone] = useState()
    const [email, setemail] = useState('')

    const [openAlert, setOpenAlert] = useState(false);
    const [message, setmessage] = useState('')

    const [updateUser, response] = useUpdateUserMutation()
    const [updatePassword, responseInfo] = useUpdatePasswordMutation()

    const handleSubmit = (e) => {

        e.preventDefault()

        updateUser({ name, country, avatar, phone })

    }

    const handlePasswordUpdate = (e) => {
        e.preventDefault()
        updatePassword({ newPassword })
    }

    const handleClose = (e) => {
        if (e === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };

    const handleImgChange = (e) => {

        const selectedFile = e.target.files[0];

        if (selectedFile.type === 'image/png' || selectedFile.type === 'image/svg' || selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/gif' || selectedFile.type === 'image/tiff' || selectedFile.type === 'image/jpeg') {

            const reader = new FileReader()
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setavatarPreview(reader.result)
                    setavatar(reader.result)
                }
            }
            reader.readAsDataURL(e.target.files[0])
        } else {
            setmessage('Please Select An Image!')
            setOpenAlert(true);
        }


    }

    useEffect(() => {

        if (userInfo) {
            setname(userInfo.name && userInfo.name)
            setcountry(userInfo.country && userInfo.country)
            setphone(userInfo.phone && userInfo.phone)
            setemail(userInfo.email && userInfo.email)
            setavatarPreview(userInfo.avatar.url && userInfo.avatar.url)
            setemail(userInfo.email && userInfo.email)
        }

    }, [userInfo])


    useEffect(() => {

        if (response.status === 'fulfilled') {
            setUserInfo(response.data.user)
            setmessage('User profile has been updated successfully!')
            setOpenAlert(true);
        }

        if (responseInfo.status === 'fulfilled') {
            setnewPassword('')
            setmessage('User password has been updated successfully!')
            setOpenAlert(true);

        }

    }, [response, responseInfo])
    return (
        <StyledNavbar padding={largeOpen && '1.5rem'}>

            <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center', }}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }} >
                    {message}
                </Alert>
            </Snackbar>

            <Backdrop open={open} onClick={() => setopen(false)} sx={{ zIndex: '1' }} />

            <Box display={{ xs: 'flex', md: 'none', position: 'fixed', top: '0', left: '0' }} sx={{ padding: '15px' }}>
                <IconButton onClick={() => setopen(!open)} sx={{ color: 'black', zIndex: '1' }}>
                    <Menu />
                </IconButton>
            </Box>

            <Box>
                {open && (
                    <Box>
                        <motion.div

                            animate={{ x: [-300, 0] }}
                            transition={{ duration: 0.85, ease: 'easeOut' }}
                            className='myMenu'
                        >
                            <Sidebar open={open} setopen={setopen} />
                        </motion.div>

                    </Box>
                )}

            </Box>

            <Box display={{ xs: 'none', md: 'flex' }} >
                <motion.div
                    animate={{ x: sidebarAnimation }}
                    transition={{ duration: 0.85, ease: 'easeOut' }}
                    className='myMenu'
                >
                    <Sidebar largeOpen={largeOpen} setlargeOpen={setlargeOpen} setanimation={setanimation} setsidebarAnimation={setsidebarAnimation} />
                </motion.div>
            </Box>

            <Box marginLeft={{ md: largeOpen ? '300px' : '0' }}>
                <motion.div
                    animate={{ x: animation }}
                    transition={{ duration: 0.85, ease: 'easeOut' }}
                >
                    {!largeOpen && (
                        <Box display={{ xs: 'none', md: 'flex' }} paddingTop='3px' paddingLeft={'3px'}>
                            <IconButton onClick={() => {
                                setlargeOpen(true)
                                setanimation([-300, 0])
                                setsidebarAnimation([-300, 0])
                            }}>
                                <Menu />
                            </IconButton>
                        </Box>
                    )}

                    <SettingsContainer padding={!largeOpen && '0.4rem 1.5rem'}>

                        <Box bgcolor={'white'} width='100%' borderRadius={'25px'} marginTop={{ xs: '40px', sm: '10px' }} padding='45px 40px'>
                            <Box display='flex' justifyContent={'space-between'} gap='10px' alignItems='center'>
                                <Typography fontWeight={'500'} fontSize='30px'>My Profile</Typography>
                                <Button variant="contained" color='error' onClick={handleSubmit}
                                    sx={{
                                        textTransform: 'none',
                                        borderRadius: '10px',
                                        fontSize: '17px'
                                    }}>
                                    {response.isLoading ? <CircularProgress size={30} sx={{ color: 'white' }} /> : 'Save'}
                                </Button>
                            </Box>
                            <Box>
                                <Box component='form' marginTop='40px'>
                                    <Box display={'flex'} flexDirection='column' gap='20px'>
                                        <Box display={'flex'} flexDirection={{ xs: 'column', lg: 'row' }} gap='20px' justifyContent='space-between' alignItems={'center'}>
                                            <Box display={'flex'} alignItems='center'>
                                                <Typography component={'label'} htmlFor="Email" display={{ xs: 'none', sm: 'unset' }} width={{ xs: '103px', lg: '70px' }} fontWeight={'500'} fontSize={18}>
                                                    Email
                                                </Typography>
                                                <TextField
                                                    placeholder='Email'
                                                    className='feild'
                                                    id='Email'
                                                    type={'email'}
                                                    disabled
                                                    value={email}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {
                                                            fontSize: 15,
                                                        }
                                                    }}
                                                    variant="standard"
                                                />
                                            </Box>

                                            <Box display={'flex'} alignItems='center' >
                                                <Typography component={'label'} htmlFor="Country" display={{ xs: 'none', sm: 'unset' }} width='103px' fontWeight={'500'} fontSize={18}>
                                                    Country
                                                </Typography>
                                                <FormControl focused={false}>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select-label"
                                                        required
                                                        placeholder='Country'
                                                        name='country'
                                                        value={country}
                                                        onChange={(e) => setcountry(e.target.value)}
                                                        MenuProps={MenuProps}

                                                    >

                                                        {Country && Country.getAllCountries().map((name) => (

                                                            <MenuItem
                                                                key={name.isoCode}
                                                                value={name.name}
                                                            >
                                                                {name.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Box>
                                        <Box display={'flex'} flexDirection={{ xs: 'column', lg: 'row' }} gap='20px' justifyContent='space-between' alignItems={'center'}>
                                            <Box display={'flex'} alignItems='center' >
                                                <Typography component={'label'} htmlFor="Phone" display={{ xs: 'none', sm: 'unset' }} width={{ xs: '103px', lg: '70px' }} fontWeight={'500'} fontSize={18}>
                                                    Phone
                                                </Typography>
                                                <TextField
                                                    placeholder={'Phone'}
                                                    className='feild'
                                                    id='Phone'
                                                    value={phone}
                                                    onChange={(e) => setphone(e.target.value)}
                                                    type={'number'}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {
                                                            fontSize: 15,
                                                        }
                                                    }}
                                                    variant="standard"
                                                />
                                            </Box>

                                            <Box display={'flex'} alignItems='center' >
                                                <Typography component={'label'} htmlFor="WeChat" display={{ xs: 'none', sm: 'unset' }} width='103px' fontWeight={'500'} fontSize={18}>
                                                    Name
                                                </Typography>
                                                <TextField
                                                    placeholder={'Name'}
                                                    className='feild'
                                                    value={name}
                                                    onChange={(e) => setname(e.target.value)}
                                                    id='WeChat'
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {
                                                            fontSize: 15,
                                                        }
                                                    }}
                                                    variant="standard"
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>

                            </Box>
                        </Box>

                        <Box marginTop={'40px'} paddingBottom='30px' display='flex' gap='1rem' justifyContent={{ xs: 'center' }} flexWrap='wrap'>

                            <Box bgcolor={'white'} width={{ xs: '100%', sm: '280px' }} height='300px' borderRadius={'25px'} padding='25px 20px'>

                                <Typography fontWeight={'500'} fontSize='22px'>Update Password</Typography>
                                <Box marginTop={'35px'} >
                                    <Typography component={'label'} htmlFor="password" fontWeight={'500'} fontSize={18}>
                                        New Password
                                    </Typography>
                                    <TextField
                                        placeholder='*****'
                                        className='feild'
                                        value={newPassword}
                                        onChange={(e) => setnewPassword(e.target.value)}
                                        sx={{ width: '100% !important', marginTop: '10px' }}
                                        type='password'
                                        id='password'
                                        InputProps={{
                                            disableUnderline: true,
                                            style: {
                                                fontSize: 15,

                                            }
                                        }}
                                        variant="standard"
                                    />
                                    <Box display='flex' justifyContent='center'>
                                        <Button variant="contained"
                                            color='error'
                                            onClick={handlePasswordUpdate}
                                            sx={{
                                                textTransform: 'none',
                                                marginTop: '44px',
                                                textTransform: 'none',
                                                borderRadius: '10px',
                                                fontSize: '17px',
                                                width: '100%'
                                            }}>Update</Button>
                                    </Box>
                                </Box>
                            </Box>
                            <Box bgcolor={'white'} width={{ xs: '100%', sm: '280px' }} height='300px' borderRadius={'25px'} padding='25px 20px'>

                                <Typography fontWeight={'500'} fontSize='22px'>Profile Photo</Typography>

                                <Box display='flex' justifyContent={'center'} marginTop='20px'>
                                    <Box component='img' src={avatarPreview} height='140px' width='140px' sx={{ borderRadius: '50%', objectFit: 'cover' }} />
                                </Box>
                                <Button variant="contained" color='error' component="label" sx={{
                                    textTransform: 'none', fontSize: '18px', fontWeight: '500', marginTop: '10px', textTransform: 'none',
                                    borderRadius: '10px',
                                    fontSize: '17px',
                                    width: '100%'
                                }}>
                                    Upload New
                                    <input hidden onChange={handleImgChange} accept="image/*" type="file" />
                                </Button>

                            </Box>
                        </Box>
                    </SettingsContainer>
                </motion.div>
            </Box>


        </StyledNavbar>
    )
}

export default Settings