import { KeyboardArrowUp, AttachMoneyOutlined, Circle, DoneAllOutlined, ExpandMore, GroupAdd, LightbulbOutlined, LinkOutlined, Settings, VideoLabel, TryRounded, TryOutlined } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Typography, useMediaQuery, Slide, Step, Stepper, StepLabel, styled, StepConnector, stepConnectorClasses, IconButton, Link } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Navbar } from '../../../Pages'
import Footer from '../Footer/Footer';
import img from '../../../assets/img.svg'
import img2 from '../../../assets/section-featured.svg'
import { motion } from 'framer-motion';
import YouGet from './YouGet';
import Plans from '../Plans/Plans';
import ChooseUs from './ChooseUs';
import ContactForm from './ContactForm';
import { FaLightbulb, FaRegLightbulb } from 'react-icons/fa'
import myGif from '../../../assets/WhatsApp Video 2022-12-10 at 12.20.05 AM.mp4'
import { useLocation } from 'react-router-dom';


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: 'black'
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: 'black'
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
    transition: 'all 1.6s ease-in-out',
    '&:hover': {
        transform: 'rotateY(360deg)',
    }
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <GroupAdd />,
        2: <AttachMoneyOutlined />,
        3: <LinkOutlined />,
        4: <DoneAllOutlined />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

const steps = ['Create an account', 'Buy Package', 'Give stream Key & Live Video Url', 'You are ready to stream'];

const Home = () => {

    const [none, setnone] = useState(false)
    const [bulb, setbulb] = useState()
    const [Play, setPlay] = useState(true)

    const smallerthen600 = useMediaQuery('(max-width:600px)')

    const questions = [
        {
            question: 'Zema looped streaming vs regular live streaming',
            answer: 'For live streaming, you create a video using a camera or screen capture and simultaneously stream it in real-time. For streaming with Zema, you choose a pre-recorded video to stream on a loop 24/7 until you terminate it.'
        },
        {
            question: 'Should my computer always be on while streaming?',
            answer: 'No, the Zema service creates a dedicated server for each client with a separate IP. The video is being streamed to your channel from that server, so there is no need to keep your computer turned on and connected to the Internet.'
        },
        {
            question: 'Will streams work for my channel if it is relatively small?',
            answer: 'Yes, of course. Since streams are promoted better by YouTube algorithms, you will generate watch time and subscribers faster, which is important for your channel growth and enabling monetization.'
        },
        {
            question: 'What type of content is best to use for streams or looped streams?',
            answer: 'Any content that follows the platform guidelines is acceptable for streaming. One of the best ways to prepare for an hour-long video stream is to compile existing videos on the same stream slot to keep viewers engaged.'
        },
        {
            question: 'How long should a stream last?',
            answer: 'Our Live service allows you to stream in a loop for 24 hours a day, 7 days a week!'
        },
        {
            question: 'Will there be any limit for streaming videos?',
            answer: 'Yes, you can stream a maximum of 6 videos in a loop.'
        },
        {
            question: 'What are the simple three steps to start Live Streaming?',
            answer: `Our easy-to-use dashboard makes it very easier for everyone to stream live; see the steps below 1. Upload Your Video to Our Dashboard from your account. 2. Set Your Stream Key3. Click “START STREAM”, and your video is Live now.`
        },
        {
            question: 'Do I have to give access to my YouTube channel or Facebook page?',
            answer: `Absolutely not; we don’t need any access. You have your own control`
        },
        {
            question: 'Is streaming considered duplicate content?',
            answer: `When you stream your existing content on a loop, you are not violating the platforms' guidelines on duplicate content. You do not re-upload the videos to the channel but only stream them continuously. When the stream stops, the videos do not remain on the channel.`
        },
        {
            question: 'Do I need a more powerful computer for multiple simultaneous streams?',
            answer: `With Zema, you won’t need a more powerful computer to start multiple streams — the service is cloud-based and uses dedicated servers for every single stream. You will only need the Internet to upload your videos to the cloud server and start streaming. Neither your equipment nor your involvement is necessary after the stream is created.`
        },

        {
            question: 'What video quality is supported by Zema?',
            answer: `We stream the quality of your original video file. Zema streams all formats up to 8К.`
        },
        {
            question: 'Can I stream to different channels from the Zema server?',
            answer: `Yes, you can. With the Zema Taster package, you can run 1 video; with the Runner package, you can run 2 simultaneous streams to 2 different channels or 2 simultaneous streams to one channel simultaneously. With the Zema Champion package, 3 streams, respectively.`
        },
        {
            question: 'Why do streams generate more revenue than regular videos?',
            answer: `Streams have a higher audience retention rate, and advertising is always more expensive on such videos. So, streams bring in more revenue than regular videos.`
        },
        {
            question: 'What content is best to use for streams?',
            answer: `Any content that does not violate the platform guidelines is acceptable for streaming. An excellent way to prepare to stream an hour-long video is to compile existing videos on the same topic to keep viewers engaged.`
        },
        {
            question: 'How long should streams last?',
            answer: `Our Zema service allows you to stream 24/7, 365 days a year. We’ve analyzed the video metrics and determined that streams get maximum views in the first 3 days. Therefore, we recommend restarting streams every 3 days.`
        },

    ]

    const location = useLocation()

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.pageYOffset > 300) {
                setnone(false)
            } else {
                setnone(true)
            }
        });

        if (location.hash) {
            let x = location.hash.split('#')[1]
            const releventDiv = document.getElementById(x);
            releventDiv.scrollIntoView({ behavior: "smooth" });
        }

    }, []);




    return (
        <Box bgcolor={'#f2f7fb'} minHeight='100vh'>
            <Navbar />
            <Container >

                <Box component={'div'} id='works' paddingTop={'110px'} display='flex' flexDirection={'column'} alignItems='center'>
                    <Typography fontFamily={'roboto'} fontWeight='bold' fontSize={{ xs: '30px', sm: '35px' }}>
                        In this video you will learn
                    </Typography>
                    <Box display='flex' alignItems={'center'} gap='10px'>
                        <Typography fontFamily={'roboto'} fontWeight='bold' fontSize={{ xs: '30px', sm: '35px' }}>
                            how this website works
                        </Typography>
                        <Box>
                            {!bulb ? (

                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.75 }}
                                >
                                    < FaRegLightbulb style={{ fontSize: '40px', color: 'black', display: bulb && 'none' }} onMouseEnter={() => setbulb(true)} />
                                </motion.div>
                            ) : (
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.75 }}
                                >
                                    <FaLightbulb style={{ fontSize: '40px', color: 'rgb(243, 225, 107)' }} onMouseOut={() => setbulb(false)} />
                                </motion.div>
                            )}
                        </Box>
                    </Box>
                </Box>
                <Box marginTop='40px'>
                    <video src='https://res.cloudinary.com/doytf8ce3/video/upload/v1688466807/IMG_202307041529090_ybngew.mov' controls width={'100%'} style={{ border: '1px solid #e0e0e0', objectFit: 'cover', borderRadius: '30px' }} height={smallerthen600 ? '400px' : '460px'} />
                </Box>

                <Box padding='40px 0' >

                    <Box margin={{ xs: '60px 0', md: '80px 0' }}>
                        <Box marginBottom='50px'>
                            <Box display={'flex'} alignItems='center' gap='7px' justifyContent='center'>

                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 1.75, yoyo: Infinity }}
                                >
                                    <Typography color='#ef5350' fontWeight={'bold'} fontSize={{ xs: '20px', sm: '30px' }}>YouTube</Typography>
                                </motion.div>

                                <Typography fontSize={{ xs: '20px', sm: '30px' }}>Loop Live Stream</Typography>
                            </Box>
                            <Typography textAlign={'center'} fontSize={{ xs: '20px', sm: '30px' }}>Service</Typography>
                        </Box>
                        <Stepper alternativeLabel activeStep={3} connector={<ColorlibConnector />}>
                            {steps.map((label) => (
                                <Step key={label}
                                    href={`${label === 'Buy Package' ? '#plans' : '/login'}`}
                                    component={Link}
                                    to='/login'
                                    sx={{ textDecoration: 'none' }}
                                >
                                    <StepLabel
                                        StepIconComponent={ColorlibStepIcon}
                                        sx={{
                                            '.MuiStepLabel-label': {
                                                fontSize: { xs: '10px', sm: '13px', md: '15px' },
                                                fontWeight: 'bold'
                                            }
                                        }}
                                    >{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>

                    </Box>

                    <Box>
                        <Box display='flex' flexDirection={'column'} marginTop='30px' alignItems='center'>
                            <Typography fontFamily={'roboto'} textAlign='center' fontWeight='bold' fontSize={{ xs: '30px', sm: '35px' }}>
                                Want to know the benefits?
                            </Typography>
                            <Box display='flex' alignItems={'center'} gap='10px'>
                                <Typography fontFamily={'roboto'} color='#757575' fontSize={{ xs: '17px' }}>
                                    Look at the benefits below
                                </Typography>
                            </Box>
                        </Box>
                        <Box display={'flex'} gap='30px' flexDirection={{ xs: 'column', md: 'row' }}>
                            <Box flex='1'>
                                <motion.div
                                    whileInView={{ x: [-300, 0], opacity: [0, 1] }}
                                    transition={{ duration: 1.3 }}
                                    viewport={{ once: true }}
                                >
                                    <Box component='img' src={img} width='100%' height={{ xs: '400px', sm: '500px', md: '100%' }} />
                                </motion.div>
                            </Box>
                            <Box flex='1' display='flex' flexDirection={'column'} justifyContent='center'>
                                <motion.div
                                    whileInView={{ x: [300, 0], opacity: [0, 1] }}
                                    transition={{ duration: 1.3, ease: 'easeOut' }}
                                    viewport={{ once: true }}
                                >
                                    <Box display='flex' flexDirection={'column'} gap='20px'>
                                        <Box>
                                            <Box display='flex' alignItems={'center'} gap='10px'>
                                                <Circle sx={{ color: '#ef5350', fontSize: '15px' }} />
                                                <Typography fontWeight={'bold'} fontSize={{ xs: '14px', sm: '17px' }}>Live Streams Generate More Revenue Than Regular Videos</Typography>
                                            </Box>
                                            <Typography fontSize={{ xs: '10px', sm: '13px' }} color='#757575' marginTop={'5px'}>
                                                Streams have a higher audience retention rate, and advertising is always more expensive on such videos. So, streams bring in more revenue than regular videos.                                        </Typography>
                                        </Box>
                                        <Box>
                                            <Box display='flex' alignItems={'center'} gap='10px'>
                                                <Circle sx={{ color: '#ef5350', fontSize: '15px' }} />
                                                <Typography fontWeight={'bold'} fontSize={{ xs: '14px', sm: '17px' }}>Continuous Streams For Audience And Income Growth</Typography>
                                            </Box>
                                            <Typography fontSize={{ xs: '10px', sm: '13px' }} color='#757575' marginTop={'5px'}>
                                                Drive new traffic, monetize your old videos, and stay in favor of YouTube’s algorithms. Continuous streams attract traffic many times faster. Streams gain views faster than regular videos. <br /><br /> Our Zema service allows you to stream 24/7, 365 days a year. We’ve analyzed the video metrics and determined that streams get maximum views in the first 3 days. Therefore, we recommend restarting streams every 3 days.                                        </Typography>
                                        </Box>
                                        <Box>
                                            <Box display='flex' alignItems={'center'} gap='10px'>
                                                <Circle sx={{ color: '#ef5350', fontSize: '15px' }} />
                                                <Typography fontWeight={'bold'} fontSize={{ xs: '14px', sm: '17px' }}>Enables Multiple Simultaneous Streams</Typography>
                                            </Box>
                                            <Typography fontSize={{ xs: '10px', sm: '13px' }} color='#757575' marginTop={'5px'}>
                                                With Zema, you won’t need a more powerful computer to start multiple streams — the service is cloud-based and uses dedicated servers for every single stream. You will only need the Internet to upload your videos to the cloud server and start streaming. Neither your equipment nor your involvement is necessary after the stream is created.                                        </Typography>
                                        </Box>
                                    </Box>
                                </motion.div>
                            </Box>
                        </Box>
                    </Box>

                    <Box>
                        <Box display='flex' flexDirection={'column'} marginTop='50px' alignItems='center'>
                            <Typography fontFamily={'roboto'} textAlign='center' fontWeight='bold' fontSize={{ xs: '30px', sm: '35px' }}>
                                Why should I use?
                            </Typography>
                            <Box display='flex' alignItems={'center'} gap='10px'>
                                <Typography fontFamily={'roboto'} color='#757575' fontSize={{ xs: '17px' }}>
                                    Look at the points below
                                </Typography>
                            </Box>
                        </Box>
                        <Box display={'flex'} gap='30px' flexDirection={{ xs: 'column', md: 'row' }} marginTop='70px' marginBottom={'30px'}>
                            <Box flex='1'>
                                <motion.div
                                    whileInView={{ x: [-300, 0], opacity: [0, 1] }}
                                    transition={{ duration: 1.3 }}
                                    viewport={{ once: true }}
                                >
                                    <Box component='img' src={img2} width='100%' height={{ xs: '350px', sm: '400px', md: '100%' }} />
                                </motion.div>
                            </Box>
                            <Box flex='1' display='flex' flexDirection={'column'} justifyContent='center'>
                                <motion.div
                                    whileInView={{ x: [300, 0], opacity: [0, 1] }}
                                    transition={{ duration: 1.3, ease: 'easeOut' }}
                                    viewport={{ once: true }}
                                >
                                    <Box display='flex' flexDirection={'column'} gap='20px'>
                                        <Box>
                                            <Box display='flex' alignItems={'center'} gap='10px'>
                                                <Typography fontWeight={'bold'} fontSize={{ xs: '17px', sm: '20px' }}>How does it work?</Typography>
                                            </Box>
                                            <Typography fontSize={{ xs: '11px', sm: '13px' }} color='#757575' marginTop={'5px'}>
                                                You upload the video in any platform and get its live url. After uploading give its url and stream key to us. After processed by our team, your stream will start; this creates an endless stream that will play on the channel and stream 24/7.
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Box display='flex' alignItems={'center'} gap='10px'>
                                                <Typography fontWeight={'bold'} fontSize={{ xs: '17px', sm: '20px' }}>
                                                    So what?</Typography>
                                            </Box>
                                            <Typography fontSize={{ xs: '11px', sm: '13px' }} color='#757575' marginTop={'5px'}>
                                                You’ve probably already heard that YouTube & Facebook favors live streaming; They get more expensive ad space and new traffic. So don’t miss your chance and start earning more.                                        </Typography>
                                        </Box>
                                        <Box>
                                            <Box display='flex' alignItems={'center'} gap='10px'>
                                                <Typography fontWeight={'bold'} fontSize={{ xs: '17px', sm: '20px' }}>What does YouTube say?</Typography>
                                            </Box>
                                            <Typography fontSize={{ xs: '11px', sm: '13px' }} color='#757575' marginTop={'5px'}>
                                                Zema Entertainment works within the YouTube Guidelines, and all functionality is provided by the platform.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </motion.div>
                            </Box>
                        </Box>
                    </Box>


                </Box>

            </Container>

            <Box component='div' bgcolor={'#FA1C53'} onLoad={() => setPlay(true)} onMouseOut={() => setPlay(false)} padding={{ xs: '70px 0', sm: '100px 0' }}>
                <Container >
                    <Box display='flex' flexDirection={{ xs: 'column', sm: 'row' }} gap='40px'>
                        <Box flex='1' >
                            <motion.div
                                whileInView={{ x: [-300, 0], opacity: [0, 1] }}
                                transition={{ duration: 1.3 }}
                                viewport={{ once: true }}
                            >
                                <Box component='video' src={myGif} autoPlay muted loop sx={{ borderRadius: '20px', width: '100%', height: '100%' }} />

                            </motion.div>
                        </Box>
                        <Box flex='1'>
                            <motion.div
                                whileInView={{ x: [300, 0], opacity: [0, 1] }}
                                transition={{ duration: 1.3, ease: 'easeOut' }}
                                viewport={{ once: true }}
                            >
                                <Typography color='white' fontSize={{ xs: '27px', sm: '35px' }} fontWeight={'bold'}>
                                    We have done some great
                                </Typography>
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 1.45, yoyo: Infinity }}
                                >
                                    <Typography color='white' fontSize={{ xs: '27px', sm: '35px' }} fontWeight={'bold'}>
                                        Work.
                                    </Typography>
                                </motion.div>
                                <Typography color='white' fontSize={{ xs: '13px', sm: '15px' }} marginTop='20px'>
                                    We accomplish this by continuously developing server technology, giving expert assistance, and ensuring a flawless streaming experience.
                                </Typography>
                            </motion.div>
                        </Box>
                    </Box>
                </Container>
            </Box >

            <YouGet />

            <Plans />

            <ChooseUs />
            <Container>
                <Box component={'div'} id='FAQ' display='flex' flexDirection={'column'} paddingTop={{ xs: '60ox', sm: '70px', md: '80px' }} alignItems='center'>
                    <Typography fontFamily={'roboto'} fontWeight='bold' fontSize={{ xs: '30px', sm: '35px' }}>
                        Have questions?
                    </Typography>
                    <Box display='flex' alignItems={'center'} gap='10px'>
                        <Typography fontFamily={'roboto'} color='#757575' fontSize={{ xs: '17px' }}>
                            Look for the answers below
                        </Typography>
                    </Box>
                </Box>
                <Box margin={'50px 0'}>
                    {questions.map((item, i) => (

                        <Accordion key={i} sx={{
                            borderRadius: '5px', backgroundColor: '#f2f7fb', boxShadow: 'none', borderBottom: '1px solid black',

                            '& .MuiAccordionSummary-expandIconWrapper': {
                                transition: 'all 0.7s ease-in-out'
                            }
                        }}

                        >
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{
                                    '&:hover': {
                                        backgroundColor: '#eeeeee'
                                    },

                                }}
                            >
                                <Typography fontWeight={'bold'} fontSize={{ xs: '15px', sm: '18px' }}>{item.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {item.question === 'What are the simple three steps to start Live Streaming?' ? (
                                    <Typography color='#757575' fontSize={{ xs: '13px', sm: '16px' }}>
                                        Our easy-to-use dashboard makes it very easier for everyone to stream live; see the steps below <br />
                                        1. Upload Your Video to Our Dashboard from your account.
                                        <br />  2. Set Your Stream Key
                                        <br /> 3. Click “START STREAM”, and your video is Live now.
                                    </Typography>
                                ) : (
                                    <Typography color='#757575' fontSize={{ xs: '13px', sm: '16px' }}>
                                        {item.answer}
                                    </Typography>
                                )}
                            </AccordionDetails>
                        </Accordion>

                    ))}
                </Box>
            </Container>

            <ContactForm />

            <Footer />

            <IconButton
                sx={{
                    position: 'fixed',
                    zIndex : '1',
                    right: '20px',
                    bottom: '20px',
                    backgroundColor: 'rgba(128,0,128,0.7)',
                    color: 'white',
                    display: none && 'none',
                    transition: 'all 0.6s ease-in-out',
                    '&:hover': {
                        backgroundColor: 'rgba(128,0,128,0.9)',
                    }
                }}

                onClick={() => window.scrollTo(0, 0)}
            >
                <KeyboardArrowUp />
            </IconButton>

        </Box >
    )
}

export default Home