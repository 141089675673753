import { AppBar, Box, styled, Typography } from "@mui/material";
import Images from '../../../assets/bgWhite.png'

const MyAppBar = styled(AppBar)(({ theme }) => ({
  position: 'fixed',
  backgroundColor: 'transparent',
  boxShadow: '0 0 5px rgba(0,0,0,0.1)',
  borderRadius: '50px',
  top: '4px',
  left: '0',
  right: '0',

  '.myMenu': {
    backgroundColor: 'white',
    position: 'fixed',
    top: '0',
    right: '0',
    Bottom: '0',
    height: '100vh',
    width: '60%',
    zIndex: '5',
    backgroundImage: `url(${Images})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'repeat',
    [theme.breakpoints.down('sm')]: {
      width: '72.5%'
    }
  },

  '.unactiveLink': {
    color: '#757575',
    fontSize: '19px',
    fontFamily: 'roboto',
    transition: 'all 0.5s ease-in-out',
    cursor: 'pointer',
    fontWeight: 'bold',
    textDecoration: 'none',
    padding: '8px 12px',
    border: '1px solid transparent',
    borderRadius: '10px',
    textAlign : 'center',

    '&:hover': {
      color: 'black',
      border: '1px solid #757575',

    }
  },
  '.activeLink': {
    color: 'black',
    fontWeight: 'bold',
    fontFamily: 'roboto',
    cursor: 'pointer',
    fontSize: '19px',
    color: '#d32f2f',
    textDecoration: 'none',
    padding: '8px 12px',
    border: '1px solid #d32f2f',
    borderRadius: '10px',
    backgroundColor : '#eeeeee',
    textAlign : 'center'
  }


}))

const Logo = styled(Typography)(({ theme }) => ({

  fontFamily: 'Macondo',
  fontWeight: 800,
  letterSpacing: '.1rem',
  color: 'black',
  textDecoration: 'none',
  cursor: 'pointer',


}))

export {
  Logo,
  MyAppBar,


}