/* eslint-disable react-hooks/exhaustive-deps */

import { Box, CssBaseline } from '@mui/material'
import { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Loader } from './Components';
import StatesContext from './context/StatesContext';
import Notifications from './Pages/Admin/Notifications/Notifications';
import UserCredentails from './Pages/Admin/Notifications/UserCredentials/UserCredentials';
import ServerDetail from './Pages/Admin/Servers/ServerDetail';
import Servers from './Pages/Admin/Servers/Servers';
import ServerUsers from './Pages/Admin/Servers/ServerUsers';
import AdminSettings from './Pages/Admin/Settings/AdminSettings';
import ActivePackagesAdmin from './Pages/Admin/Users/ActivePackages/ActivePackages';
import PackageDetailsAdmin from './Pages/Admin/Users/PackageDetails/PackageDetails';
import AdminUserHIstory from './Pages/Admin/Users/PackageHistory/PackageHistory';
import UserDetails from './Pages/Admin/Users/UserDetails/UserDetails';
import Warnings from './Pages/Admin/Warnings/Warnings';
import { Forgot, Home, Login, Register, Reset, Verification, Dashboard, Pricing, MyChannel, History, Settings, MakePlans, PackageDetails, Users } from './Pages/index'
import Plans from './Pages/Static/Plans/Plans';
import ActivePackages from './Pages/User/ActivePackages/ActivePackages';
import UploadVideo from './Pages/User/UploadVideo/UploadVideo';
import AuthRoutes from './routes/AuthRoutes';
import PrivateRoute from './routes/PrivateRoutes';
import ProtectedRoute from './routes/ProtectedRoutes';
import { useUserDetailsQuery } from './services/userApi';

const App = () => {

  const { data, isFetching, refetch } = useUserDetailsQuery()

  const context = useContext(StatesContext)
  const { setUserInfo, setisUserUpdate, isUserUpdate } = context

  useEffect(() => {

    if (data) {

      setUserInfo(data.user)

    }

  }, [data])


  useEffect(() => {
    if (isUserUpdate) {
      refetch()
      setisUserUpdate(false)
    }
  }, [isUserUpdate])

  return (
    <>
      {isFetching ? <Loader height={'100vh'} /> : (

        <Box maxWidth={'100vw'} overflow='hidden'>
          <CssBaseline />
          <Routes>

            <Route element={<AuthRoutes data={data} />}>
              <Route path='/login' element={<Login />} />
              <Route path='/register' element={<Register />} />
              <Route path='/password/reset/:token' element={<Reset />} />
              <Route path='/forgot/password' element={<Forgot />} />
              <Route path='/verification/:id' element={<Verification />} />
              <Route path='/' element={<Home />} />
            </Route>

            <Route element={<ProtectedRoute data={data} />}>
              <Route path='/dashboard' element={<Dashboard />} />
              <Route path='/pricing' element={<Pricing />} />
              <Route path='/mychannel' element={<MyChannel />} />
              <Route path='/history' element={<History />} />
              <Route path='/settings' element={<Settings />} />
              <Route path='/plans/create' element={<MakePlans />} />
              <Route path='/package/:id' element={<PackageDetails />} />
              <Route path='/video/upload' element={<UploadVideo />} />
              <Route path='/packages/active' element={<ActivePackages />} />
            </Route>

            <Route element={<PrivateRoute data={data} />}>
              <Route path="/admin/users" element={<Users />} />
              <Route path="/UserProfile/:id" element={<UserDetails />} />
              <Route path="/admin/user/packages/:id" element={<AdminUserHIstory />} />
              <Route path="/admin/user/package/:id/:packageId" element={<PackageDetailsAdmin />} />
              <Route path="/admin/user/active-packages/:id" element={<ActivePackagesAdmin />} />
              <Route path="/admin/user/credentials" element={<Notifications />} />
              <Route path="/admin/user/credentials/:id" element={<UserCredentails />} />
              <Route path="/admin/server" element={<Servers />} />
              <Route path="/admin/server/detail" element={<ServerDetail />} />
              <Route path="/admin/server/users" element={<ServerUsers />} />
              <Route path="/admin/settings" element={<AdminSettings />} />
              <Route path="/admin/warnings" element={<Warnings />} />
            </Route>
          </Routes>


        </Box>

      )}
    </>
  )
}

export default App