import React, { useState } from "react";
import StatesContext from "./StatesContext";

const OverAllStates = (props) => {

    const [userInfo, setUserInfo] = useState('')
    const [useremail, setUseremail] = useState('')
    const [userFound, setuserFound] = useState('')
    const [isUserUpdate, setisUserUpdate] = useState(false)
    const [isVideoAdded, setisVideoAdded] = useState(false)

    const [timerDays, setTimerDays] = useState(0);
    const [timerHours, setTimerHours] = useState(0);
    const [timerMinutes, setTimerMinutes] = useState(0);
    const [timerSeconds, setTimerSeconds] = useState(0);
    const [months, setmonths] = useState(0)

    const [ispkgbuyed, setispkgbuyed] = useState(false)
    const [expiryTime, setexpiryTime] = useState(0)

    const [uploading, setuploading] = useState(false)
    const [progress, setprogress] = useState(0)

    const [isTimeUpdated, setisTimeUpdated] = useState(false)


    return (
        <StatesContext.Provider
            value={{

                userInfo, setUserInfo,
                useremail, setUseremail,
                userFound, setuserFound,
                isUserUpdate, setisUserUpdate,
                isVideoAdded, setisVideoAdded,

                timerDays, setTimerDays,
                timerHours, setTimerHours,
                timerMinutes, setTimerMinutes,
                timerSeconds, setTimerSeconds,
                ispkgbuyed, setispkgbuyed,
                expiryTime, setexpiryTime,
                months, setmonths,
                
                uploading, setuploading,
                progress, setprogress,
                isTimeUpdated, setisTimeUpdated

            }}>
            {props.children}
        </StatesContext.Provider>
    )
}
export default OverAllStates;
