import { Alert, Backdrop, Box, Button, Grid, IconButton, InputAdornment, Snackbar, TextField, Typography } from "@mui/material";
import { motion } from 'framer-motion';
import { ContentCopyOutlined, LinkOutlined, LockOutlined, MailOutlineOutlined, Menu } from "@mui/icons-material";
import Sidebar from '../Sidebar/Sidebar'
import { StyledUser } from "../Users/style";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useServerDetailQuery } from "../../../services/userApi";
import Loader from '../../../Components/Loader'


const ServerDetail = () => {

    const [open, setopen] = useState(false)
    const [openAlert, setOpenAlert] = useState(false);

    const { state } = useLocation()
    const { data, isFetching } = useServerDetailQuery(state.data)

    const navigate = useNavigate()

    const handleEmailClick = () => {
        if (state.data === 'Server 1') {

            navigator.clipboard.writeText('faizanjamil656@gmail.com')
        }
        else if (state.data === 'Server 2') {
            navigator.clipboard.writeText('codezone546@gmail.com')
        }
        else if (state.data === 'Server 3') {
            navigator.clipboard.writeText('codezone@gmail.com')
        }
        else if (state.data === 'Server 4') {
            navigator.clipboard.writeText('codezone548@gmail.com')
        }
        else if (state.data === 'Server 5') {
            navigator.clipboard.writeText('codezone549@gmail.com')
        }
        setOpenAlert(true)
    }
    const handlePassClick = () => {
        if (state.data === 'Server 1') {

            navigator.clipboard.writeText('maliksab123')
        } else if (state.data === 'Server 3') {

            navigator.clipboard.writeText('codezone')
        } else {
            navigator.clipboard.writeText('codezone123')
        }
        setOpenAlert(true)
    }

    const handleClose = (e) => {
        if (e === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };


    return (
        <StyledUser>

            <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center', }}>

                <Alert onClose={handleClose} severity={'success'} sx={{ width: '100%' }} >
                    Copied to clipboard successfully!
                </Alert>

            </Snackbar>

            <Box display={{ xs: 'none', md: 'flex' }} >
                <Sidebar />
            </Box>
            <Box display={{ xs: 'flex', md: 'none' }} sx={{ position: 'fixed', top: '10px', left: '10px', zIndex: '1' }}>
                <IconButton onClick={() => setopen(!open)} >
                    <Menu />
                </IconButton>
            </Box>
            <Backdrop open={open} onClick={() => setopen(false)} />

            <Box>
                {open && (
                    <Box>
                        <motion.div

                            animate={{ x: [-300, 0] }}
                            transition={{ duration: 0.85, ease: 'easeOut' }}
                            className='myMenu'

                        >

                            <Sidebar open={open} setopen={setopen} />
                        </motion.div>

                    </Box>
                )}

            </Box>

            <Box marginLeft={{ md: '310px' }} className="user-con">
                {isFetching ? <Loader height='83vh' /> : (
                    <Grid container spacing={5} sx={{ display: 'flex', alignItems: 'center', minHeight: { md: '85vh' } }}>
                        <Grid item xs={12} sm={12} lg={6}>
                            <Box padding='50px 20px' bgcolor='white' borderRadius={'10px'}

                            >
                                <Typography color='black' fontSize={'30px'} textAlign={'center'} fontWeight={'bold'}>{state.data} Credentials</Typography>
                                <Box display={'flex'} marginTop='30px' gap='20px' justifyContent={'center'}>

                                    <Box>
                                        <Typography padding='7px 0' fontWeight={'bold'} fontSize='18px' textAlign={'center'}>Email</Typography>
                                        <Typography padding='7px 0' marginTop={'29px'} fontWeight={'bold'} fontSize='18px' textAlign={'center'}>Password</Typography>
                                    </Box>
                                    <Box>
                                        <Box display='flex' alignItems={'center'} gap='20px'>
                                            <TextField
                                                fullWidth
                                                disabled
                                                value={state.data === 'Server 1' ? '********@gmail.com' : state.data === 'Server 2' ? 'codezone546@gmail.com' : state.data === 'Server 3' ? 'codezone547@gmail.com' : state.data === 'Server 4' ? 'codezone548@gmail.com' : 'codezone549@gmail.com'}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    style: {
                                                        fontSize: 15,
                                                    },
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <MailOutlineOutlined sx={{ fontSize: '25px' }} />
                                                        </InputAdornment>
                                                    )
                                                }}
                                                sx={{

                                                    '& .MuiInputBase-root': {
                                                        border: '1px solid #e0e0e0',
                                                        padding: '7px 17px',
                                                        borderRadius: '10px',
                                                        transition: 'all 0.4s ease-in-out',
                                                        backgroundColor: '#f5f5f5',
                                                    },
                                                    '& .Mui-focused': {
                                                        backgroundColor: 'black !important',
                                                        color: 'white',
                                                        '& .MuiSvgIcon-root': {
                                                            color: 'white'
                                                        },
                                                        '& ::placeholder': {
                                                            color: 'white',
                                                            opacity: '0.7'
                                                        }
                                                    },

                                                }}
                                                variant="standard"
                                            />
                                            <IconButton onClick={handleEmailClick}>
                                                <ContentCopyOutlined sx={{ fontSize: "23px" }} />
                                            </IconButton>
                                        </Box>
                                        <Box display='flex' alignItems={'center'} gap='20px' marginTop={'20px'}>
                                            <TextField
                                                fullWidth
                                                disabled
                                                value={'faizanjamil6'}
                                                type='password'
                                                InputProps={{
                                                    disableUnderline: true,
                                                    style: {
                                                        fontSize: 15,
                                                    },
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <LockOutlined sx={{ fontSize: '25px' }} />
                                                        </InputAdornment>
                                                    )
                                                }}
                                                sx={{

                                                    '& .MuiInputBase-root': {
                                                        border: '1px solid #e0e0e0',
                                                        padding: '7px 17px',
                                                        borderRadius: '10px',
                                                        transition: 'all 0.4s ease-in-out',
                                                        backgroundColor: '#f5f5f5',
                                                    },
                                                    '& .Mui-focused': {
                                                        backgroundColor: 'black !important',
                                                        color: 'white',
                                                        '& .MuiSvgIcon-root': {
                                                            color: 'white'
                                                        },
                                                        '& ::placeholder': {
                                                            color: 'white',
                                                            opacity: '0.7'
                                                        }
                                                    },

                                                }}
                                                variant="standard"
                                            />
                                            <IconButton onClick={handlePassClick}>
                                                <ContentCopyOutlined sx={{ fontSize: "23px" }} />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box display='flex' justifyContent={'flex-end'} marginTop='30px'>
                                    <Button variant="contained" color='error'
                                        sx={{
                                            textTransform: 'none',
                                            borderRadius: '10px',
                                            fontSize: '17px',
                                        }}
                                        href={`${state.data === 'Server 1' ? 'http://129.151.213.132:5080/#/pages/login' : state.data === 'Server 2' ? 'http://132.145.26.93:5080/#/pages/login' : state.data === 'Server 3' ? 'http://207.127.91.236:5080/#/pages/login' : state.data === 'Server 4' ? 'http://207.127.91.104:5080/#/pages/login' : 'http://141.147.119.53:5080/#/pages/login'}`}
                                        target='_blank'
                                    >
                                        Go to server
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6}>
                            <Box
                                display='flex' justifyContent={'center'} alignItems='center'

                            >
                                <Box height='300px' width='300px' padding='30px'

                                    component='div'
                                    onClick={() => navigate('/admin/server/users', { state: state.data })}
                                    sx={{
                                        cursor: 'pointer',
                                        borderStyle: 'outset', borderWidth: '6px', borderBottomColor: '#EDB79F', borderLeftColor: '#EDB79F', borderRightColor: '#63E2C6', borderTopColor: '#63E2C6',
                                        padding: '10px !important',
                                        borderRadius: '50%'
                                    }}
                                >
                                    <Box height='100%' width='100%' bgcolor={data.server[0].users.length > 15 ? '#ef5350' : 'green'} borderRadius={'50%'} display='flex' justifyContent={'center'} alignItems='center' flexDirection={'column'}>


                                        <Typography color='white' fontSize={'30px'} fontWeight={'bold'}>User Counts</Typography>
                                        <Typography color='white' fontSize={'30px'} fontWeight={'bold'}>{data.server[0].users.length}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>



                    </Grid>
                )}
            </Box>

        </StyledUser >
    )
}
export default ServerDetail;