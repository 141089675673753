import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import StatesContext from '../context/StatesContext'


const AuthRoutes = ({ data }) => {

    const context = useContext(StatesContext)
    const { userInfo } = context

    if (userInfo && (userInfo.role === 'user')) {

        return <Navigate to={'/dashboard'} />
    } else if (userInfo && (userInfo.role === 'admin')) {

        return <Navigate to={'/admin/users'} />
    }
    else {
        return <Outlet />
    }

}

export default AuthRoutes