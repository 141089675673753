import { Face, MailOutlineOutlined, SendOutlined, SubjectOutlined, TramRounded } from '@mui/icons-material'
import { Alert, Box, Button, Container, Grid, IconButton, InputAdornment, Snackbar, TextField, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import { motion } from 'framer-motion';
import emailjs from '@emailjs/browser'


const ContactForm = () => {

    const [openAlert, setOpenAlert] = useState(false);
    const handleClose = (e) => {
        if (e === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        setOpenAlert(true)
        emailjs.sendForm('service_4vlf0qt', 'template_9rqbwah', form.current, '2qYiW7fGSTktgZlQR')
        e.target.reset();
    };

   

    return (
        <Container>
            <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center', }}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }} >
                    Your response has been submitted successfully
                </Alert>
            </Snackbar>
            <Box component={'div'} id='contactUs' padding='100px 0' paddingBottom={{ xs: '40px', sm: '100px' }}>
                <Grid container spacing={7}>
                    <Grid item xs={12} sm={12} md={8}>
                        <motion.div
                            whileInView={{ x: [-300, 0], opacity: [0, 1] }}
                            transition={{ duration: 1.3 }}
                            viewport={{ once: true }}
                        >
                            <Typography fontWeight={'bold'} fontSize='35px'>
                                Get In Touch
                            </Typography>
                            <Box component='form' ref={form} onSubmit={(e) => sendEmail(e)} marginTop='30px'>

                                <Box display='flex' flexDirection={{ xs: 'column', sm: 'row' }} gap='30px' >
                                    <Box flex='1' display='flex' flexDirection={'column'} gap='25px'>
                                        <TextField
                                            placeholder='Enter Your Name'
                                            name='name'
                                            required
                                            fullWidth
                                            InputProps={{
                                                disableUnderline: true,
                                                style: {
                                                    fontSize: 15,
                                                },
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Face sx={{ fontSize: '25px' }} />
                                                    </InputAdornment>
                                                )
                                            }}
                                            sx={{

                                                '& .MuiInputBase-root': {
                                                    border: '1px solid #e0e0e0',
                                                    padding: '7px 10px',
                                                    width: '100%',
                                                    borderRadius: '10px',
                                                    transition: 'all 0.4s ease-in-out',
                                                    backgroundColor: 'white',
                                                },
                                                '& .Mui-focused': {
                                                    backgroundColor: 'black !important',
                                                    color: 'white',
                                                    '& .MuiSvgIcon-root': {
                                                        color: 'white'
                                                    },
                                                    '& ::placeholder': {
                                                        color: 'white',
                                                        opacity: '0.7'
                                                    }
                                                },

                                            }}
                                            variant="standard"
                                        />
                                        <TextField
                                            placeholder='Enter Your Email'
                                            name='email'
                                            type={'email'}
                                            required
                                            fullWidth
                                            InputProps={{
                                                disableUnderline: true,
                                                style: {
                                                    fontSize: 15,
                                                },
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <MailOutlineOutlined sx={{ fontSize: '25px' }} />
                                                    </InputAdornment>
                                                )
                                            }}
                                            sx={{

                                                '& .MuiInputBase-root': {
                                                    border: '1px solid #e0e0e0',
                                                    padding: '7px 10px',
                                                    width: '100%',
                                                    borderRadius: '10px',
                                                    transition: 'all 0.4s ease-in-out',
                                                    backgroundColor: 'white',
                                                },
                                                '& .Mui-focused': {
                                                    backgroundColor: 'black !important',
                                                    color: 'white',
                                                    '& .MuiSvgIcon-root': {
                                                        color: 'white'
                                                    },
                                                    '& ::placeholder': {
                                                        color: 'white',
                                                        opacity: '0.7'
                                                    }
                                                },

                                            }}
                                            variant="standard"
                                        />
                                        <TextField
                                            placeholder='Enter Your Subject'
                                            name='message'
                                            required
                                            fullWidth
                                            InputProps={{
                                                disableUnderline: true,
                                                style: {
                                                    fontSize: 15,
                                                },
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SubjectOutlined sx={{ fontSize: '25px' }} />
                                                    </InputAdornment>
                                                )
                                            }}
                                            sx={{

                                                '& .MuiInputBase-root': {
                                                    border: '1px solid #e0e0e0',
                                                    padding: '7px 10px',
                                                    width: '100%',
                                                    borderRadius: '10px',
                                                    transition: 'all 0.4s ease-in-out',
                                                    backgroundColor: 'white',
                                                },
                                                '& .Mui-focused': {
                                                    backgroundColor: 'black !important',
                                                    color: 'white',
                                                    '& .MuiSvgIcon-root': {
                                                        color: 'white'
                                                    },
                                                    '& ::placeholder': {
                                                        color: 'white',
                                                        opacity: '0.7'
                                                    }
                                                },

                                            }}
                                            variant="standard"
                                        />
                                    </Box>
                                    <Box flex='1'>
                                        <TextField
                                            placeholder='Enter Your Message'
                                            name='subject'
                                            required
                                            multiline
                                            minRows={7}
                                            fullWidth
                                            InputProps={{
                                                disableUnderline: true,
                                                style: {
                                                    fontSize: 15,
                                                },

                                            }}
                                            sx={{

                                                '& .MuiInputBase-root': {
                                                    border: '1px solid #e0e0e0',
                                                    padding: '15px 21px',
                                                    width: '100%',
                                                    borderRadius: '10px',
                                                    transition: 'all 0.4s ease-in-out',
                                                    backgroundColor: 'white',
                                                },
                                                '& .Mui-focused': {
                                                    backgroundColor: 'black !important',
                                                    color: 'white',
                                                    '& .MuiSvgIcon-root': {
                                                        color: 'white'
                                                    },
                                                    '& ::placeholder': {
                                                        color: 'white',
                                                        opacity: '0.7'
                                                    }
                                                },

                                            }}
                                            variant="standard"
                                        />
                                    </Box>
                                </Box>
                                <Button
                                    type='submit'
                                    variant='contained'
                                    className='blob-btn'
                                    size='small'
                                    sx={{
                                        borderRadius: '30px',
                                        fontSize: '16px',
                                        backgroundColor: 'black !important',
                                        marginTop: '20px',
                                        textTransform: 'none',
                                        color: 'white !important',
                                        width: '100%',
                                        padding: '10px 0 !important'
                                    }}
                                >
                                    Send Message
                                    <span class="blob-btn__inner">
                                        <span class="blob-btn__blobs">
                                            <span class="blob-btn__blob"></span>
                                            <span class="blob-btn__blob"></span>
                                            <span class="blob-btn__blob"></span>
                                            <span class="blob-btn__blob"></span>
                                        </span>
                                    </span>

                                </Button>
                            </Box>
                        </motion.div>

                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <motion.div
                            whileInView={{ x: [300, 0], opacity: [0, 1] }}
                            transition={{ duration: 1.3, ease: 'easeOut' }}
                            viewport={{ once: true }}
                        >
                            <Typography fontWeight={'bold'} fontSize='35px'>
                                Quick Support
                            </Typography>
                            <Typography fontSize={'14px'} color='#757575' marginTop='15px'>
                                We’re here for you, waiting to answer your queries, our team is happy to help you.
                            </Typography>
                            <Box display='flex' alignItems={'center'} gap='10px' marginTop={'30px'}>
                                <IconButton disableRipple href='mailto:zemaa.entertainment@gmail.com' target={'_blank'}>
                                    <SendOutlined sx={{ fontSize: '29px', transition: 'all 0.5s ease-in-out', '&:hover': { color: 'red' } }} />
                                </IconButton>
                                <Typography component='a' href='mailto:zemaa.entertainment@gmail.com' target='_blank'
                                    sx={{
                                        textDecoration: 'none',
                                        fontWeight: 'bold',
                                        color: 'black',
                                        fontSize: '15px',
                                        transition: 'all 0.5s ease-in-out',
                                        '&:hover': { color: 'red' }
                                    }}
                                >
                                    zemaa.entertainment@gmail.com
                                </Typography>
                            </Box>
                        </motion.div>
                    </Grid>
                </Grid>
            </Box>
        </Container >
    )
}

export default ContactForm