import { Box, Pagination, styled } from "@mui/material";


const StyledNavbar = styled(Box)(({ theme }) => ({

    backgroundColor: '#f2f7fb',
    minHeight: '100vh',

    [theme.breakpoints.down('md')]: {
        paddingBottom: '40px'
    },
    '.myMenu': {
        backgroundColor: 'white',
        position: 'fixed',
        top: '0',
        left: '0',
        Bottom: '0',
        height: '100vh',
        width: '300px',
        zIndex: '5',
        [theme.breakpoints.down('sm')]: {
            width: '45%'
        }

    },
    '.cancel-icon': {
        color: 'black',
        '&:hover': {
            backgroundColor: 'white',
            boxShadow: '0 0 20px rgba(0,0,0,0.1)'
        }
    },
    '.email-feild': {

        border: '1px solid #e0e0e0',
        padding: '7px 15px',
        borderRadius: '10px',
        transition: 'all 0.4s ease-in-out',

        [theme.breakpoints.down('sm')]: {
            width: '100%',

        }

    },

    '.unactiveButton': {
        color: 'black',
        textTransform: 'unset',
        borderRadius: '25px',
        fontSize: '17px',

    },
    '.activeButton': {
        color: 'white',
        textTransform: 'unset',
        backgroundColor: theme.palette.error.main,
        borderRadius: '25px',
        fontSize: '17px',
        '&:hover': {
            backgroundColor: theme.palette.error.main,
            color: 'white',
        }

    },
    '.vortex-wrapper':{
        borderRadius : '2px !important',
    }

}))

const StyledPagination = styled(Pagination)({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2.5em',
    marginBottom:'3em'
  })



export {
    StyledNavbar,
    StyledPagination
}