
import { Navigate, Outlet } from 'react-router-dom'

const ProtectedRoute = ({ data }) => {


    if (data && (data.user.role === 'user')) {

        return <Outlet />
    } else if (data && (data.user.role === 'admin')) {
        return <Navigate to={'/admin/users'} />
    } else {
        return <Navigate to={'/login'} />

    }

}

export default ProtectedRoute