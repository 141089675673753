import { Backdrop, Box, Grid, IconButton, Typography } from "@mui/material";
import { motion } from 'framer-motion';
import { Menu } from "@mui/icons-material";
import Sidebar from '../Sidebar/Sidebar'
import { StyledUser } from "../Users/style";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Servers = () => {

    const [open, setopen] = useState(false)
    const navigate = useNavigate()

    const handleClick = (item) => {
        navigate('/admin/server/detail', { state: { data: item } })
    }

    return (
        <StyledUser>

            <Box display={{ xs: 'none', md: 'flex' }} >
                <Sidebar />
            </Box>
            <Box display={{ xs: 'flex', md: 'none' }} sx={{ position: 'fixed', top: '10px', left: '10px', zIndex: '1' }}>
                <IconButton onClick={() => setopen(!open)} >
                    <Menu />
                </IconButton>
            </Box>
            <Backdrop open={open} onClick={() => setopen(false)} />

            <Box>
                {open && (
                    <Box>
                        <motion.div

                            animate={{ x: [-300, 0] }}
                            transition={{ duration: 0.85, ease: 'easeOut' }}
                            className='myMenu'

                        >

                            <Sidebar open={open} setopen={setopen} />
                        </motion.div>

                    </Box>
                )}

            </Box>

            <Box marginLeft={{ md: '310px' }} className="user-con">
                <Box display='flex' flexDirection={'column'} alignItems='center'>
                    <Box>
                        <Typography marginTop='20px' fontWeight={'bold'} textAlign={'center'} fontSize='35px'>Streaming Servers</Typography>
                    </Box>


                    <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', zIndex: '1', marginTop: '20px' }}>
                        <Grid item xs={12} sm={6} lg={4}>
                            <Box component={'div'} onClick={() => handleClick('Server 1')} padding='15px 0' display='flex' bgcolor='#ef5350' borderRadius={'10px'} justifyContent={'center'} alignItems='center'
                                sx={{
                                    cursor: 'pointer'
                                }}
                            >
                                <Typography color='white' fontWeight={'bold'}>Server 1</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <Box component={'div'} onClick={() => handleClick('Server 2')} padding='15px 0' display='flex' bgcolor='#ef5350' borderRadius={'10px'} justifyContent={'center'} alignItems='center'
                                sx={{
                                    cursor: 'pointer'
                                }}
                            >
                                <Typography color='white' fontWeight={'bold'}>Server 2</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <Box component={'div'} onClick={() => handleClick('Server 3')} padding='15px 0' display='flex' bgcolor='#ef5350' borderRadius={'10px'} justifyContent={'center'} alignItems='center'
                                sx={{
                                    cursor: 'pointer'
                                }}
                            >
                                <Typography color='white' fontWeight={'bold'}>Server 3</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <Box component={'div'} onClick={() => handleClick('Server 4')} padding='15px 0' display='flex' bgcolor='#ef5350' borderRadius={'10px'} justifyContent={'center'} alignItems='center'
                                sx={{
                                    cursor: 'pointer'
                                }}
                            >
                                <Typography color='white' fontWeight={'bold'}>Server 4</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <Box component={'div'} onClick={() => handleClick('Server 5')} padding='15px 0' display='flex' bgcolor='#ef5350' borderRadius={'10px'} justifyContent={'center'} alignItems='center'
                                sx={{
                                    cursor: 'pointer'
                                }}
                            >
                                <Typography color='white' fontWeight={'bold'}>Server 5</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

        </StyledUser>
    )
}
export default Servers;