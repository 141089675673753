import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


// const baseUrl = 'http://localhost:4000/api';
const baseUrl = 'https://myserver.zemas.live/api';

export const awsApi = createApi({
    reducerPath: 'awsApi',
    baseQuery: fetchBaseQuery({ baseUrl }),
    endpoints: (builder) => ({

        GetAwsUrl: builder.mutation({
            query: () => {
                return {
                    url: '/aws/url',
                    method: 'Get',
                    credentials: 'include'
                }
            },

        }),
        DeleteVideo: builder.mutation({
            query: (videoId) => {
                return {
                    url: '/video/delete',
                    method: 'Put',
                    body: { videoId },
                    credentials: 'include'
                }
            },

        }),

    })

});

export const {

    useGetAwsUrlMutation,
    useDeleteVideoMutation


} = awsApi;

