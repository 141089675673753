import { Backdrop, Box, FormControl, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { motion } from 'framer-motion';
import Sidebar from '../Sidebar/Sidebar';
import { StyledNavbar } from '../Dashboard/style';
import { Menu, ReadMoreOutlined, Settings } from '@mui/icons-material';
import StatesContext from '../../../context/StatesContext';
import { usePackageHistoryQuery } from '../../../services/userApi';
import Loader from '../../../Components/Loader';
import { NoProductHeading, RegisteredUserCon, StyledBox, StyledPagination } from './style';
import { useNavigate } from 'react-router-dom';

const History = () => {

    const [open, setopen] = useState(false)
    const [currentPage, setcurrentPage] = useState(1)

    const [largeOpen, setlargeOpen] = useState(true)
    const [animation, setanimation] = useState([])
    const [sidebarAnimation, setsidebarAnimation] = useState([])
    
    const [status, setStatus] = useState()
    const [selectedValue, setselectedValue] = useState('all')

    const { data, isFetching } = usePackageHistoryQuery({ status, currentPage })

    const navigate = useNavigate()

    const handleChange = (e) => {

        setselectedValue(e.target.value)


        if (e.target.value === 'all') {

            setStatus('')
            setcurrentPage(1)


        } else {
            setStatus(e.target.value);
            setcurrentPage(1)
        }


    };


    return (
        <StyledNavbar padding={largeOpen && { xs: '1.5rem 0.5rem', sm: '1.5rem' }}>
            <Backdrop open={open} onClick={() => setopen(false)} sx={{ zIndex: '1' }} />

            <Box display={{ xs: 'flex', md: 'none', position: 'fixed', top: '0', left: '0',zIndex: '1' }} sx={{ padding: '15px' }}>
                <IconButton onClick={() => setopen(!open)} sx={{ color: 'black', zIndex: '1' }}>
                    <Menu />
                </IconButton>
            </Box>

            <Box>
                {open && (
                    <Box>
                        <motion.div

                            animate={{ x: [-300, 0] }}
                            transition={{ duration: 0.85, ease: 'easeOut' }}
                            className='myMenu'
                        >
                            <Sidebar open={open} setopen={setopen} />
                        </motion.div>

                    </Box>
                )}

            </Box>

            <Box display={{ xs: 'none', md: 'flex' }} >
                <motion.div
                    animate={{ x: sidebarAnimation }}
                    transition={{ duration: 0.85, ease: 'easeOut' }}
                    className='myMenu'
                >
                    <Sidebar largeOpen={largeOpen} setlargeOpen={setlargeOpen} setanimation={setanimation} setsidebarAnimation={setsidebarAnimation} />
                </motion.div>
            </Box>

            <Box marginLeft={{ md: largeOpen ? '300px' : '0' }}>
                <motion.div
                    animate={{ x: animation }}
                    transition={{ duration: 0.85, ease: 'easeOut' }}
                >
                    {!largeOpen && (
                        <Box display={{ xs: 'none', md: 'flex' }} paddingTop='3px' paddingLeft={'3px'}>
                            <IconButton onClick={() => {
                                setlargeOpen(true)
                                setanimation([-300, 0])
                                setsidebarAnimation([-300, 0])
                            }}>
                                <Menu />
                            </IconButton>
                        </Box>
                    )}

                    <StyledBox padding={!largeOpen && '0.4rem 1.5rem'}>
                        <Box bgcolor={'white'} borderRadius='35px' padding='1rem' minHeight={{ md: '80vh', lg: '88vh' }}>
                            <Box display='flex' justifyContent={'space-between'} alignItems='center' gap='5px'>
                                <Typography fontWeight={'bold'} fontSize='30px'>Packages History</Typography>
                                <FormControl focused={false} sx={{ minWidth: 115,borderRadius : '20px !important' }}>
                                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        required
                                        name='Status'
                                        value={selectedValue}
                                        label="Status"
                                        onChange={handleChange}
                                        InputProps={{
                                            disableUnderline: true,
                                            style : {
                                                borderRadius : '20px'
                                            }
                                        }}
                                        MenuProps={{ disableScrollLock: true }}
                                    >
                                        <MenuItem value={'active'}>Active</MenuItem>
                                        <MenuItem value={'expired'}>Expired</MenuItem>
                                        <MenuItem value={'all'}>All</MenuItem>

                                    </Select>
                                </FormControl>
                            </Box>
                            <Box>
                                {isFetching ? <Loader height={'75vh'} /> : (
                                    <Box>
                                        <Box display='flex' flexDirection={'column'} gap='0.7rem' marginTop={'30px'} >
                                            {data.allPackages.length > 0 && data.allPackages.map((item, i) => (

                                                <RegisteredUserCon key={i}>
                                                    <Box>
                                                        <Box display={'flex'} gap='10px' alignItems={'center'}>
                                                            <Typography fontWeight='bold' fontSize={'17px'} >Name:</Typography>
                                                            <Typography fontSize={'16px'} fontWeight='bold'>{item.name}</Typography>
                                                        </Box>
                                                        <Box display={'flex'} gap='10px' alignItems={'center'}>
                                                            <Typography fontWeight='bold' fontSize={'17px'} >Status:</Typography>
                                                            <Typography fontSize={'16px'} fontWeight='bold' style={{ color: item.status === 'Active' ? 'green' : 'red' }}>{item.status}</Typography>
                                                        </Box>
                                                        <Box display={'flex'} gap='10px' alignItems={'center'}>
                                                            <Typography fontWeight='bold' fontSize={'17px'} >Ctreated At:</Typography>
                                                            <Typography fontSize={'16px'} fontWeight='bold' >{new Date(item.createdAt).toDateString()}</Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box>
                                                        <IconButton onClick={() => navigate(`/package/${item._id}`)}>
                                                            <ReadMoreOutlined sx={{ fontSize: '32px' }} />
                                                        </IconButton>
                                                    </Box>

                                                </RegisteredUserCon>

                                            ))}
                                        </Box>

                                    </Box>
                                )}

                                {!isFetching && data && data.allPackages.length === 0 && (
                                    <Box display={'flex'} justifyContent='center' marginTop={{ md: '5em' }}>

                                        <NoProductHeading>No Packages history Found</NoProductHeading>
                                    </Box>
                                )}

                                {data && data.resultPerPage < data.filteredPackagesCount && (

                                    <StyledPagination color='primary' size='large'
                                        count={Math.ceil(data.filteredPackagesCount / data.resultPerPage)}
                                        page={currentPage}
                                        shape='rounded'
                                        onChange={(event, value) => setcurrentPage(value)}
                                        showLastButton showFirstButton hideNextButton hidePrevButton
                                        onClick={() => window.scrollTo(0, 0)}

                                    />
                                )}

                            </Box>
                        </Box>
                    </StyledBox>
                </motion.div>
            </Box>

        </StyledNavbar>
    )
}

export default History