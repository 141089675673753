import React, { useContext, useState } from 'react';
import { AssuredWorkload, Close, ContactPage, ContactPageOutlined, Home, Info, KeyboardArrowDown, Login, QuizOutlined, Work } from '@mui/icons-material'
import { Logo, MyAppBar } from './style'
import { motion } from 'framer-motion';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, Backdrop, Button, Container, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material';
import logoImg from '../../../assets/logo.jpeg'
import { Link, NavLink } from 'react-router-dom'

const Navbar = () => {

    const [open, setopen] = useState(false)

    const smallerthen950 = useMediaQuery('(max-width:950px)')

    const [pagesActive, setpagesActive] = useState({
        avtiveObject: null,
        pages: [
            {
                name: 'How it works', route: '#works',

            },
            {
                name: 'FAQ', route: '#FAQ',

            },
            {
                name: 'Pricing', route: '#plans',
            },
            {
                name: 'Contact Us', route: '#contactUs',
            },
            {
                name: 'Login', route: '/login',

            }
        ],

    })

    const [mobilemenu, setmobilemenu] = useState({
        avtiveObject: null,
        pages: [
            { title: 'How it works', icon: <Home />, route: '#works' },
            { title: 'FAQ', icon: <QuizOutlined />, route: '#FAQ' },
            { title: 'Pricing', icon: <Info />, route: '#plans' },
            { title: 'Contact Us', icon: <ContactPageOutlined />, route: '#contactUs' },
            { title: 'Login', icon: <Login />, route: '/login' },

        ]
    }
    )

    const handleOpenNavMenu = (e) => {

        setopen(!open)
    };

    return (
        <>
            <Backdrop open={open} onClick={() => setopen(false)} sx={{ zIndex: '9' }} />
            <MyAppBar  >
                <Box sx={{
                    backgroundColor: 'rgba(255,192,203,1)',
                    boxShadow: '0 0 5px rgba(0,0,0,0.1)',
                    borderRadius: '50px',
                    margin: '0 5px',
                    opacity : '1'
                }}>
                    <Container maxWidth='xl'>
                        <Toolbar disableGutters >
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '0 10px' }}>
                                <Box height='45px' onClick={() => window.scrollTo(0, 0)} sx={{ cursor: 'pointer', borderRadius: '50px' }}>
                                    <Box
                                        component='img'
                                        src={logoImg}
                                        height='45px'
                                        borderRadius={'50px'}
                                        sx={{
                                            objectFit: 'cover',
                                            transition: 'all 1.6s ease-in-out',
                                            '&:hover': {
                                                transform: 'rotateY(360deg)',
                                            }
                                        }}
                                    />
                                </Box>



                                <Box sx={{ display: smallerthen950 ? 'none' : 'flex' }}  >
                                    <Box display='flex' >

                                        {pagesActive.pages.map((item, i) => {

                                            return <Box
                                                style={{
                                                    paddingRight: !smallerthen950 && item.name === 'How it works' || item.name === 'Contact Us' || item.name === 'FAQ' ? '30px' : item.name === 'Pricing' && '30px',
                                                    paddingLeft: !smallerthen950 && item.name === 'Pricing' || item.name === 'Contact Us' || item.name === 'FAQ' ? '30px' : item.name === 'Login' && '30px',
                                                    borderLeft: !smallerthen950 && item.name === 'Pricing' || item.name === 'Contact Us' || item.name === 'FAQ' ? '1px solid #9e9e9e' : item.name === 'Login' && '1px solid #9e9e9e',
                                                    marginTop: '9px',
                                                    marginBottom: '9px',
                                                }}

                                            >
                                                <Typography component={item.name === 'Login' ? Link : 'a'} key={i}
                                                    href={`${item.route}`}
                                                    to={`${item.name === 'Login' && '/login'}`}
                                                    className='unactiveLink'
                                                >
                                                    {item.name}
                                                </Typography>
                                            </Box>
                                        })}

                                    </Box>
                                </Box>

                            </Box>
                            <Box

                                sx={{ flexGrow: 1, display: smallerthen950 ? 'flex' : 'none', justifyContent: 'flex-end' }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}

                                    sx={{ color: 'black' }}
                                >
                                    <MenuIcon />
                                </IconButton>

                            </Box>
                            <Box  >
                                {open && (
                                    <motion.div

                                        animate={{ x: [300, 0] }}
                                        transition={{ duration: 0.85, ease: 'easeOut' }}
                                        className='myMenu'
                                    >
                                        <Box display='flex' justifyContent='flex-end' marginTop='30px' marginRight={'20px'} marginBottom='15px' >
                                            <IconButton onClick={() => setopen(false)}>
                                                <Close sx={{ color: 'black' }} />
                                            </IconButton>
                                        </Box>

                                        <List
                                            sx={{ display: 'flex', flexDirection: 'column', paddingTop: '70px' }}
                                        >
                                            {mobilemenu.pages.map((value, i) => (
                                                <ListItem disablePadding key={i}>
                                                    <Button disableRipple component={value.name === 'Login' ? Link : 'a'} to={`${value.title === 'Login' && '/login'}`} onClick={() => setopen(false)}
                                                        sx={{ textDecoration: 'none', width: '100%' }}
                                                        href={`${value.route}`}
                                                    >
                                                        <ListItemButton disableRipple>
                                                            <ListItemIcon className='unactiveLink'>
                                                                {value.icon}
                                                            </ListItemIcon>
                                                            <ListItemText primary={value.title} className='unactiveLink' />
                                                        </ListItemButton>
                                                    </Button>
                                                </ListItem>
                                            ))}

                                        </List>
                                    </motion.div>
                                )}

                            </Box>

                        </Toolbar>
                    </Container>
                </Box>
            </MyAppBar>


        </>
    );
};
export default Navbar;
