import { Backdrop, Box, Button, CircularProgress, Grid, IconButton, Typography, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { motion } from 'framer-motion';
import Sidebar from '../Sidebar/Sidebar';
import { StyledNavbar, StyledPagination } from './style';
import { Menu } from '@mui/icons-material';
import Header from '../Header/Header';
import { useStreamListQuery, useUpcomingstreamListQuery } from '../../../services/userApi';
import ReactPlayer from 'react-player'
import StatesContext from '../../../context/StatesContext';
import { Link } from 'react-router-dom';

const Dashboard = () => {

  const [activebutton, setActivebutton] = useState('live')

  const context = useContext(StatesContext)
  const { userFound, isVideoAdded, setisVideoAdded } = context

  const smallerthen600 = useMediaQuery('(max-width:600px)')

  const [open, setopen] = useState(false)
  const [largeOpen, setlargeOpen] = useState(true)
  const [videoLink, setvideoLink] = useState('')
  const [animation, setanimation] = useState([])
  const [sidebarAnimation, setsidebarAnimation] = useState([])
  const [allVideos, setallVideos] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [postPerPage, setPostPerPage] = useState(6)

  const { data } = useStreamListQuery()
  const { data: upcomingVideo, isFetching, refetch } = useUpcomingstreamListQuery()

  useEffect(() => {
    if (data) {
      if (data.data.items.length === 1) {
        console.log(data.data.items[0].contentDetails.monitorStream.embedHtml)
        let x = data.data.items[0].contentDetails.monitorStream.embedHtml.toString()
        x = x.split('src="')[1]
        x = x.split('?')[0]
        setvideoLink(x)
      }
      console.log(data)
    }
  }, [data])


  useEffect(() => {

    if (isVideoAdded) {
      refetch()
      setisVideoAdded(false)
    }

  }, [isVideoAdded])

  useEffect(() => {

    if (upcomingVideo) {

      setallVideos(prev => {
        return [... new Set([...prev, ...upcomingVideo.data.items])]
      })
    }

  }, [upcomingVideo])

  // Get current posts
  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPosts = allVideos.slice(indexOfFirstPost, indexOfLastPost);


  return (
    <StyledNavbar padding={largeOpen && '1.5rem'}>
      <Backdrop open={open} onClick={() => setopen(false)} sx={{ zIndex: '1' }} />

      <Box display={{ xs: 'flex', md: 'none', position: 'fixed', top: '0', left: '0',zIndex: '1' }} sx={{ padding: '15px' }}>
        <IconButton onClick={() => setopen(!open)} sx={{ color: 'black',  }}>
          <Menu />
        </IconButton>
      </Box>

      <Box>
        {open && (
          <Box>
            <motion.div

              animate={{ x: [-300, 0] }}
              transition={{ duration: 0.85, ease: 'easeOut' }}
              className='myMenu'
            >
              <Sidebar open={open} setopen={setopen} />
            </motion.div>

          </Box>
        )}

      </Box>

      <Box display={{ xs: 'none', md: 'flex' }} >
        <motion.div
          animate={{ x: sidebarAnimation }}
          transition={{ duration: 0.85, ease: 'easeOut' }}
          className='myMenu'
        >
          <Sidebar largeOpen={largeOpen} setlargeOpen={setlargeOpen} setanimation={setanimation} setsidebarAnimation={setsidebarAnimation} />
        </motion.div>
      </Box>

      <Box marginLeft={{ md: largeOpen ? '300px' : '0' }}>
        <motion.div
          animate={{ x: animation }}
          transition={{ duration: 0.85, ease: 'easeOut' }}
        >
          {!largeOpen && (
            <Box display={{ xs: 'none', md: 'flex' }} paddingTop='3px' paddingLeft={'3px'}>
              <IconButton onClick={() => {
                setlargeOpen(true)
                setanimation([-300, 0])
                setsidebarAnimation([-300, 0])
              }}>
                <Menu />
              </IconButton>
            </Box>
          )}

          <Box padding={!largeOpen && '0.4rem 1.5rem'}>
            <Header />
            {userFound ? (
              <>

                <Box marginTop={'30px'}>
                  <Typography fontWeight={'bold'} textAlign={{ xs: 'center', sm: 'unset' }} fontSize={{ xs: '30px', sm: '35px' }}>YouTube Streaming</Typography>
                  <Box display='flex' gap='10px' justifyContent={'center'} marginTop='30px'>
                    <Button
                      onClick={(e) => {
                        setActivebutton('live')
                      }}
                      className={activebutton === 'live' ? 'activeButton' : 'unactiveButton'}>
                      Live
                    </Button>
                    <Button
                      onClick={(e) => {
                        setActivebutton('upcoming')
                      }}
                      className={activebutton === 'upcoming' ? 'activeButton' : 'unactiveButton'}
                      disableRipple
                    >
                      Upcoming
                    </Button>
                  </Box>
                  {activebutton === 'live' ? (
                    <Box margin='30px 0'>
                      <Box display='flex' flexWrap={'wrap'} gap='20px'>
                        {data && data.data.items.length > 1 && data.data.items.map((item, i) => {

                          let x = item.contentDetails.monitorStream.embedHtml.toString()
                          x = x.split('src="')[1]
                          x = x.split('?')[0]

                          return <Button variant='outlined' key={i} onClick={() => setvideoLink(x)}
                            sx={{
                              textTransform: 'none',
                              borderRadius: '28px',
                              borderColor: 'black',
                              borderWidth: '2px',
                              padding: '5px 27px',
                              fontSize: { xs: '18px', sm: '20px' },
                              margin: { xs: '0 50px', sm: '0' },
                              color: 'black',
                              backgroundColor: '#eeeeee',
                              transition: 'all 0.6s ease-in-out',
                              '&:hover': {
                                backgroundColor: 'black',
                                color: 'white',
                                borderWidth: '2px',
                              }
                            }}
                          >
                            Live Streaming {i + 1}
                          </Button>
                        })}

                      </Box>
                      {data && videoLink && (
                        <Box>
                          <ReactPlayer url={videoLink} width='100%' height='400px' style={{ border: '2px solid #e0e0e0', borderRadius: '20px', objectFit: 'cover' }} controls playing={true} />
                        </Box>
                      )}
                      {data && data.data.items.length === 0 && (
                        <Box display='flex' alignItems={'center'} justifyContent='center' height={{ xs: '20vh', md: '30vh' }}>
                          <Typography color='#757575' fontSize={'15px'}>No currently live streaming found in your channel.</Typography>
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <>
                      {isFetching ? (
                        <Box display='flex' alignItems='center' justifyContent='center' height='45vh'>
                          <CircularProgress size={60} />
                        </Box>
                      ) : (
                        <>
                          {allVideos && allVideos.length === 0 && (
                            <Box display='flex' alignItems={'center'} justifyContent='center' height={{ xs: '20vh', md: '37vh' }}>
                              <Typography color='#757575' fontSize={'15px'}>No upcoming streaming found in your channel.</Typography>
                            </Box>
                          )}

                          <Grid container spacing={4} sx={{ marginTop: '15px', display: 'flex', justifyContent: 'center', padding: { xs: '0 30px', sm: '0', lg: '0 50px' } }}>
                            {allVideos && allVideos.length > 0 && currentPosts.map((item, i) => (
                              <Grid item xs={12} sm={6} md={4} key={i}>
                                <Box
                                  sx={{
                                    backgroundColor: 'white',
                                    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                                    borderRadius: '12px',
                                    padding: '15px',
                                    paddingBottom: '0',
                                    transition: 'all 0.6s ease-in-out',
                                    minHeight: '350px',
                                    textDecoration : 'none',
                                    '&:hover': {
                                      transform: 'scale(1.1)',
                                      cusror: 'pointer',
                                    }
                                  }}
                              
                                onClick={() => window.open("http://youtube.com")}
                                >
                                  <Box src={item.snippet.thumbnails.high.url} component='img' width='100%' height='200px' sx={{ objectFit: 'cover', borderRadius: '12px', }} />
                                  <Box padding='4px'>
                                    <Typography fontSize='20px' fontWeight='bold'>
                                      {item.snippet.title}
                                    </Typography>
                                    <Typography fontSize='15px' color='#757575' margin='4px 0'>
                                      {item.snippet.description.length > 100
                                        ? `${item.snippet.description.substring(0, 100)}...`
                                        : item.snippet.description}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            ))}
                          </Grid>
                          {postPerPage < allVideos.length && (

                            <StyledPagination color='primary' size='large'
                              count={Math.ceil(allVideos.length / postPerPage)}
                              page={currentPage}
                              shape='rounded'
                              onChange={(event, value) => setCurrentPage(value)}
                              showLastButton showFirstButton hideNextButton hidePrevButton

                            />
                          )}
                        </>
                      )
                      }
                    </>
                  )}
                </Box>
              </>
            ) : (
              <Box display='flex' gap='6px' flexWrap={'wrap'} alignItems={'center'} justifyContent='center' marginTop={{ xs: '60px', sm: '0' }} height={{ sm: '70vh' }}>
                <Typography color='#757575' textAlign={'center'} fontSize={'15px'}>Please</Typography>
                <Typography component={Link} to='/mychannel' sx={{ textDecoration: 'none' }} color='#ef5350' textAlign={'center'} fontSize={'15px'}>connect</Typography>
                <Typography color='#757575' textAlign={'center'} fontSize={'15px'}>your youtube channel in order to continue.</Typography>
              </Box>
            )}
          </Box>

        </motion.div>
      </Box>
    </StyledNavbar>
  )
}

export default Dashboard