import { Box, Button, CircularProgress, Step, StepConnector, stepConnectorClasses, StepLabel, Stepper, styled, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import logo from '../../../assets/logo.jpeg'
import { StyledAuth } from '../style'
import { useResetPasswordMutation } from '../../../services/userApi'
import { AttachMoneyOutlined, DoneAllOutlined, GroupAdd, LinkOutlined } from '@mui/icons-material'

const Reset = () => {

    const [password, setpassword] = useState('')
    const [confirmPassword, setconfirmpass] = useState('')

    const [resetUserPass, response] = useResetPasswordMutation()

    const navigate = useNavigate();
    const { token } = useParams()

    const handleSubmit = (e) => {

        e.preventDefault();
        const myForm = new FormData();

        myForm.set("password", password);
        myForm.set("confirmPassword", confirmPassword);

        resetUserPass({ token, password, confirmPassword });

    };

    useEffect(() => {

        if (response.status === 'fulfilled') {

            navigate('/login')
        }

    }, [response])


    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 40,
            [theme.breakpoints.down('md')]: {
                top: 22
            }
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundColor: 'white'
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundColor: 'white'
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 4,
            border: 0,
            backgroundColor:
                theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderRadius: 1,
            [theme.breakpoints.down('md')]: {
                height: 3
            }
        },
    }));

    const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 80,
        height: 80,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
            backgroundImage:
                'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
            backgroundImage:
                'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        }),
        transition: 'all 1.6s ease-in-out',
        '&:hover': {
            transform: 'rotateY(360deg)',
        },
        [theme.breakpoints.down('md')]: {
            width: 50,
            height: 50,
        },

    }));

    function ColorlibStepIcon(props) {
        const { active, completed, className } = props;

        const icons = {
            1: <GroupAdd sx={{ fontSize: { md: '40px' } }} />,
            2: <AttachMoneyOutlined sx={{ fontSize: { md: '40px' } }} />,
            3: <LinkOutlined sx={{ fontSize: { md: '40px' } }} />,
            4: <DoneAllOutlined sx={{ fontSize: { md: '40px' } }} />,
        };

        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    const steps = ['Create an account', 'Buy Package', 'Give stream Key & Live Video Url', 'You are ready to stream'];



    return (
        <StyledAuth>
            <Box flex={{ md: '0.55', lg: '0.35' }}
                bgcolor='white'
                borderRadius={'13px'}
                boxShadow='0 0 10px rgba(0,0,0,0.1)'
                display='flex'
                justifyContent={'center'}
                alignItems='center'
                padding='40px 0'
                marginTop={{ xs: '30px', md: '0' }}
            >
                <Box display='flex' gap='10px' flexDirection={'column'} width='100%' alignItems='center'>
                    <Box component={Link} to='/' sx={{ cursor: 'pointer', width: '100px', borderRadius: '50px' }}>
                        <Box component='img' src={logo} width='100px' borderRadius={'50px'} sx={{
                            objectFit: 'center',
                            transition: 'all 1.6s ease-in-out',
                            '&:hover': {
                                transform: 'rotateY(360deg)',
                            }
                        }} />
                    </Box>
                    <Box>
                        <Typography fontWeight={'bold'} marginTop={'15px'} fontSize='22px'>Welcome</Typography>
                    </Box>
                    <Box component='form' onSubmit={handleSubmit} width='86%' display='flex' gap='10px' flexDirection={'column'} alignItems='center'>

                        <Box sx={{ position: 'relative', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <TextField
                                placeholder='Enter Your Password'
                                className='email-feild'
                                id='Email'
                                required
                                fullWidth
                                type={'password'}
                                onChange={(e) => setpassword(e.target.value)}
                                InputProps={{
                                    disableUnderline: true,
                                    style: {
                                        fontSize: 15,
                                    }
                                }}
                                variant="standard"
                            />

                        </Box>
                        <Box sx={{ position: 'relative', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <TextField
                                placeholder='Confirm Your Password'
                                className='email-feild'
                                id='Email'
                                required
                                fullWidth
                                type={'password'}
                                onChange={(e) => setconfirmpass(e.target.value)}
                                InputProps={{
                                    disableUnderline: true,
                                    style: {
                                        fontSize: 15,
                                    }
                                }}
                                variant="standard"
                            />

                        </Box>
                        {response.error && (

                            <Box display='flex' justifyContent={'flex-start'} width='100%'>
                                <Typography color='red' fontSize='15px' fontWeight={'500'}>
                                    {response.error.data.message}
                                </Typography>
                            </Box>

                        )}
                        <Button type='submit' variant='contained' sx={{ width: '100%', textTransform: 'none', marginTop: '15px', borderRadius: '10px' }}>
                            {response.isLoading ? <CircularProgress sx={{ color: 'white' }} /> : 'Save'}
                        </Button>
                    </Box>
                </Box>
            </Box>



            <Box flex={'1'}
                display='flex'
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems='center'
                padding={{ md: '0 2em' }}
            >
                <Typography fontWeight={'bold'} color='white' textAlign='center' fontSize={{ xs: '20px', sm: '30px', md: '35px' }}>Unlock the new Technique of Growing <br /> Subscribers & Earn 13x More</Typography>
                <Box marginTop={{ xs: '30px', sm: '40px', md: '60px' }}>
                    <Stepper alternativeLabel activeStep={3} connector={<ColorlibConnector />}>
                        {steps.map((label) => {

                            let x
                            if (label === 'Create an account') {
                                x = '/register'
                            } else if (label === 'Buy Package') {
                                x = '/#plans'
                            } else {
                                x = ''
                            }

                            return <Step key={label}
                                component={x && Link}
                                to={x}

                                sx={{ textDecoration: 'none' }}
                            >
                                <StepLabel
                                    StepIconComponent={ColorlibStepIcon}
                                    sx={{
                                        '.MuiStepLabel-label': {
                                            fontSize: { xs: '10px', sm: '17px' },
                                            fontWeight: 'bold !important',
                                            color : 'white !important'

                                        }
                                    }}
                                >{label}</StepLabel>
                            </Step>
                        })}
                    </Stepper>
                </Box>
            </Box>
        </StyledAuth>
    )
}

export default Reset