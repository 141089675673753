import { Box, styled } from "@mui/material";


const SettingsContainer = styled(Box)(({ theme }) => ({


    '.feild': {

        width: '290px',
        border: '1px solid #e0e0e0',
        padding: '7px 20px',
        borderRadius: '25px',
        backgroundColor: '#F2F1F1',
        transition: 'all 0.4s ease-in-out',
        [theme.breakpoints.down('lg')]: {
            width: '220px',

        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',

        }

    },

    "& .MuiOutlinedInput-input": {
        fontFamily: 'Roboto',
        width: '240px',
        fontSize: 15,
        borderRadius: '25px',
        backgroundColor: '#F2F1F1',
        padding: '7px 20px',
        [theme.breakpoints.down('lg')]: {
            width: '170px',

        },
        [theme.breakpoints.down('sm')]: {
            width: '182px',

        }

    },
    "& .MuiInputLabel-root": {
        color: theme.palette.grey[500],
        fontSize: 15,
        borderColor: "transparent",
        borderRadius: '25px',

    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
        color: theme.palette.grey[500],
        borderRadius: '25px',

    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
        borderRadius: '25px',
    },
    "&:focus .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
        borderRadius: '25px',
    },
    '& .Mui-focused': {
        borderColor: "transparent",
        borderRadius: '25px',
    },



}))

const RegisteredUserCon = styled(Box)(({ theme }) => ({

    backgroundColor: '#F1EBEB',
    width: '100%',
    borderRadius: '20px',
    padding: '23px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'auto'

}))

export {
    SettingsContainer,
    RegisteredUserCon
}