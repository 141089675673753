import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


// const baseUrl = 'http://localhost:4000/api';
const baseUrl = 'https://myserver.zemas.live/api';

export const userApi = createApi({
    reducerPath: 'userApi',
    tagTypes: ['User'],
    baseQuery: fetchBaseQuery({ baseUrl }),
    endpoints: (builder) => ({

        LoginUserme: builder.mutation({
            query: ({ email, password }) => {
                return {
                    url: '/login',
                    method: 'Post',
                    body: { email, password },
                    credentials: 'include'
                }
            },

        }),

        RegisterUser: builder.mutation({
            query: ({ email, password, name }) => {
                return {
                    url: '/register',
                    method: 'Post',
                    body: { email, password, name },
                    credentials: 'include'
                }
            },

        }),

        VerifyOtp: builder.mutation({
            query: ({ id, otp }) => {
                return {
                    url: '/verifyOTP',
                    method: 'Post',
                    body: {
                        userId: id,
                        otp
                    },
                    credentials: 'include'
                }
            },

        }),
        resendOtp: builder.mutation({
            query: ({ id, useremail }) => {
                return {
                    url: '/otp/resesnd',
                    method: 'Post',
                    body: {
                        id, useremail
                    },
                    credentials: 'include'
                }
            },

        }),

        UserDetails: builder.query({
            query: () => {

                return {
                    url: `/user/me`,
                    method: 'GET',
                    credentials: 'include'
                }
            },
        }),
        activePackagesList: builder.query({
            query: () => {

                return {
                    url: `/activePackages`,
                    method: 'GET',
                    credentials: 'include'
                }
            },
        }),
        logout: builder.mutation({
            query: () => {
                return {
                    url: '/logout',
                    method: 'Post',
                    credentials: 'include'
                }
            },

        }),
        forgotPassword: builder.mutation({
            query: (email) => {
                return {
                    url: '/forgot',
                    method: 'Post',
                    body: { email },
                }
            },
        }),
        liveUrl: builder.mutation({
            query: (liveUrl) => {
                return {
                    url: '/live/url',
                    method: 'Put',
                    body: { liveUrl },
                    credentials: 'include'
                }
            },
        }),

        resetPassword: builder.mutation({
            query: ({ token, password, confirmPassword }) => {
                return {
                    url: `/password/reset/${token}`,
                    method: 'Put',
                    body: { password, confirmPassword },

                }

            },
        }),

        updateUser: builder.mutation({
            query: ({ name, country, avatar, phone }) => {
                return {
                    url: '/user/update',
                    method: 'Put',
                    body: { name, country, avatar, phone },
                    credentials: 'include'
                }
            },

        }),
        updatePassword: builder.mutation({
            query: ({ newPassword }) => {
                return {
                    url: '/user/password-update',
                    method: 'Put',
                    body: { newPassword },
                    credentials: 'include'
                }
            },

        }),

        LoginUser: builder.mutation({
            query: () => {
                return {
                    url: '/login',
                    method: 'Get',
                    credentials: 'include'
                }
            },

        }),
        packageHistory: builder.query({
            query: ({ status, currentPage }) => {
                return {
                    url: status ? `/package/history?status=${status}&page=${currentPage}` : `/package/history?page=${currentPage}`,
                    method: 'Get',
                    credentials: 'include'
                }
            },

        }),
        packageDeatils: builder.query({
            query: (id) => {
                return {
                    url: `/package/${id}`,
                    method: 'Get',
                    credentials: 'include'
                }
            },

        }),
        AuthUser: builder.mutation({
            query: (code) => {
                return {
                    url: `/google/callback?code=${code}`,
                    method: 'Get',
                }
            },

        }),
        VideoUpload: builder.mutation({
            query: (videoData) => {
                return {
                    url: `/upload`,
                    method: 'Post',
                    body: videoData
                }
            },

        }),
        UserInfo: builder.query({
            query: () => {
                return {
                    url: `/info`,
                    method: 'Get',
                    credentials: 'include'
                }
            },
            providesTags: ['User']
        }),

        myVideo: builder.query({
            query: () => {
                return {
                    url: '/hehe',
                    method: 'Get',
                }
            },

        }),
        addStream: builder.mutation({
            query: ({ title, desc }) => {
                return {
                    url: `/livestream`,
                    method: 'Post',
                    body: { title, desc },
                    credentials: 'include'
                }
            },

        }),
        saveStreamKey: builder.mutation({
            query: ({ streamKey, myVideoUrl, streamUrl }) => {
                return {
                    url: `/save-stream`,
                    method: 'Post',
                    body: { streamKey, videoUrls: myVideoUrl, streamUrl },
                    credentials: 'include'
                }
            },
            invalidatesTags: ['User']
        }),
        streamList: builder.query({
            query: () => {
                return {
                    url: `/stream-list`,
                    method: 'Get',
                    credentials: 'include'
                }
            },

        }),
        upcomingstreamList: builder.query({
            query: () => {
                return {
                    url: `/upcoming/stream-list`,
                    method: 'Get',
                    credentials: 'include'
                }
            },

        }),
        videoUrls: builder.query({
            query: () => {
                return {
                    url: `/videourls`,
                    method: 'Get',
                    credentials: 'include'
                }
            },

        }),
        UpdatevideoUrls: builder.mutation({
            query: (videoUrl) => {
                return {
                    url: `/update/videourls`,
                    method: 'Put',
                    body: { videoUrl },
                    credentials: 'include'
                }
            },

        }),
        basicPackage: builder.mutation({
            query: () => {
                return {
                    url: `/basic-package`,
                    method: 'Post',
                    credentials: 'include'
                }
            },

        }),
        customPackage: builder.mutation({
            query: ({ price, totalDays, quantity, customPackage }) => {
                return {
                    url: `/custom-package`,
                    method: 'Post',
                    body: { price, totalDays, quantity, customPackage },
                    credentials: 'include'
                }
            },

        }),
        standardPackage: builder.mutation({
            query: () => {
                return {
                    url: `/standard-package`,
                    method: 'Post',
                    credentials: 'include'
                }
            },

        }),
        diamondPackage: builder.mutation({
            query: () => {
                return {
                    url: `/diamond-package`,
                    method: 'Post',
                    credentials: 'include'
                }
            },

        }),
        disconnect: builder.mutation({
            query: () => {
                return {
                    url: `/disconnect`,
                    method: 'Post',
                    credentials: 'include'
                }
            },
            invalidatesTags: ['User']
        }),
        uploadVideos: builder.mutation({
            query: ({ videoUrl, videoId }) => {
                return {
                    url: `/video/upload`,
                    method: 'Put',
                    body: { videoUrl, videoId },
                    credentials: 'include'
                }
            },
        }),

        //Admin

        Allusers: builder.query({
            query: ({ status, currentPage, email }) => {

                return {
                    url: status && !email ? `/admin/users?status=${status}&page=${currentPage}` :
                        !status && email ? `/admin/users?email=${email}&page=${currentPage}` :
                            status && email ? `/admin/users?email=${email}&status=${status}&page=${currentPage}` :
                                `/admin/users?page=${currentPage}`,
                    method: 'GET',
                    credentials: 'include'
                }
            },
            providesTags: ['User']
        }),
        AllusersNotifications: builder.query({
            query: ({ status, currentPage, email }) => {

                return {
                    url: status && !email ? `/admin/notifications?status=${status}&page=${currentPage}` :
                        !status && email ? `/admin/notifications?email=${email}&page=${currentPage}` :
                            status && email ? `/admin/notifications?email=${email}&status=${status}&page=${currentPage}` :
                                `/admin/notifications?page=${currentPage}`,
                    method: 'GET',
                    credentials: 'include'
                }
            },
            providesTags: ['User']
        }),

        getSingleUser: builder.query({
            query: (id) => {

                return {
                    url: `/admin/${id}`,
                    method: 'GET',
                    credentials: 'include'
                }
            },
        }),
        updateUserAdmin: builder.mutation({
            query: ({ name, country, avatar, phone, id }) => {
                return {
                    url: `/admin/user-update/${id}`,
                    method: 'Put',
                    body: { name, country, avatar, phone },
                    credentials: 'include'
                }
            },

        }),

        updateUserPasswordAdmin: builder.mutation({
            query: ({ newPassword, id }) => {
                return {
                    url: `/admin/user-password/${id}`,
                    method: 'Put',
                    body: { newPassword },
                    credentials: 'include'
                }
            },

        }),

        AdminUserInfo: builder.query({
            query: (id) => {
                return {
                    url: `/admin/user/channel-info/${id}`,
                    method: 'Get',
                    credentials: 'include'
                }
            },
        }),
        AdminUserActivePackages: builder.query({
            query: (id) => {
                return {
                    url: `/admin/user/packages/${id}`,
                    method: 'Get',
                    credentials: 'include'
                }
            },
        }),
        AdminpackageHistory: builder.query({
            query: ({ id, status, currentPage }) => {
                return {
                    url: status ? `/admin/user/packages/history/${id}?status=${status}&page=${currentPage}` : `/admin/user/packages/history/${id}?page=${currentPage}`,
                    method: 'Get',
                    credentials: 'include'
                }
            },

        }),
        AdminvideoUrls: builder.query({
            query: (id) => {
                return {
                    url: `/admin/videourls/${id}`,
                    method: 'Get',
                    credentials: 'include',
                }
            },

        }),
        AdminStartTimer: builder.mutation({
            query: ({ id, serverName }) => {
                return {
                    url: `/admin/timer/${id}`,
                    method: 'Put',
                    body: {
                        serverName
                    },
                    credentials: 'include'
                }
            },
            invalidatesTags: ['User']
        }),
        AdminStartWarnings: builder.mutation({
            query: (id) => {
                return {
                    url: `/admin/user/warning/${id}`,
                    method: 'Put',
                    credentials: 'include'
                }
            },
        }),
        serverDetail: builder.query({
            query: (name) => {
                return {
                    url: `/admin/server/details?serverName=${name}`,
                    method: 'Get',
                    credentials: 'include'
                }
            },
        }),
        serverUser: builder.query({
            query: (name) => {
                return {
                    url: `/admin/server/users?serverName=${name}`,
                    method: 'Get',
                    credentials: 'include'
                }
            },
        }),
        adminAddTime: builder.mutation({
            query: ({ myId, time, quantity }) => {
                return {
                    url: `/admin/add-time/${myId}`,
                    method: 'Put',
                    body: { time, quantity },
                    credentials: 'include'
                }
            },
        }),
    })
});

export const {

    //auth
    useLoginUsermeMutation,
    useRegisterUserMutation,
    useUserDetailsQuery,
    useLogoutMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
    useVerifyOtpMutation,
    useUpdateUserMutation,
    useUpdatePasswordMutation,
    useActivePackagesListQuery,
    usePackageHistoryQuery,
    usePackageDeatilsQuery,
    useResendOtpMutation,
    useLiveUrlMutation,
    //

    useLoginUserMutation,
    useAuthUserMutation,
    useVideoUploadMutation,
    useUserInfoQuery,
    useMyVideoQuery,
    useAddStreamMutation,
    useDisconnectMutation,
    useSaveStreamKeyMutation,
    useStreamListQuery,
    useVideoUrlsQuery,
    useUpdatevideoUrlsMutation,
    useBasicPackageMutation,
    useStandardPackageMutation,
    useDiamondPackageMutation,
    useUpcomingstreamListQuery,
    useCustomPackageMutation,

    // Admin
    useAllusersQuery,
    useAllusersNotificationsQuery,
    useGetSingleUserQuery,
    useUpdateUserAdminMutation,
    useUpdateUserPasswordAdminMutation,
    useAdminUserInfoQuery,
    useAdminUserActivePackagesQuery,
    useAdminpackageHistoryQuery,
    useAdminvideoUrlsQuery,
    useAdminStartTimerMutation,
    useAdminStartWarningsMutation,
    useServerDetailQuery,
    useServerUserQuery,
    useUploadVideosMutation,
    useAdminAddTimeMutation


} = userApi;

