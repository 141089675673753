import { Cancel, ContentCopySharp } from '@mui/icons-material'
import { Box, Button, CircularProgress, Dialog, IconButton, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAdminStartTimerMutation, useAdminStartWarningsMutation } from '../../../../services/userApi'

const Server5 = ({ setOpenAlert, openServer5, setopenServer5, setmxg, refetch, setisDisabled ,userRefetch}) => {

    const { id } = useParams()

    const [startTimer, res] = useAdminStartTimerMutation()
    const [warning, resp] = useAdminStartWarningsMutation()

    const handleEmailClick = () => {
        navigator.clipboard.writeText('codezone549@gmail.com')
        setmxg('Copied to clipboard successfully!')
        setOpenAlert(true)
    }
    const handlePassClick = () => {
        navigator.clipboard.writeText('codezone123')
        setmxg('Copied to clipboard successfully!')
        setOpenAlert(true)
    }

    const handleClick = () => {

        let serverName = 'Server 5'

        startTimer({id , serverName})
    }


    useEffect(() => {
        if (res.status === 'fulfilled') {
            setOpenAlert(true)
            setmxg('User streaming has been started on server 5')
            setopenServer5(false)
            refetch()
            setisDisabled(true)
            userRefetch()
        }
    }, [res])

    return (
        <Dialog
            open={openServer5}
            onClose={() => setopenServer5(false)}
            aria-labelledby="responsive-dialog-title"
            PaperProps={{
                sx: {
                    width: { xs: '94%', sm: '400px' },
                    borderRadius: '20px'
                }
            }}

        >
            <Box padding='25px' >

                <Box display={'flex'} justifyContent='flex-end' >
                    <IconButton className='cancel-icon' onClick={() => setopenServer5(false)}>
                        <Cancel />
                    </IconButton>
                </Box>
                <Box marginTop='20px'
                    sx={{
                        backgroundColor: '#ef5350',
                        borderRadius: '10px',
                        padding: '7px 0'
                    }}>
                    <Typography fontWeight={'500'} fontSize='20px' color='white' textAlign='center'>
                        Server 5 Credentials
                    </Typography>
                </Box>

                <Box margin='20px 0' marginTop='40px'>
                    <Box display='flex' gap='5px' alignItems={'center'}>
                        <Typography fontWeight='bold' fontSize='15px'>Email:</Typography>
                        <Typography color='#757575' fontSize='15px'>codezone549@gmail.com</Typography>
                        <IconButton onClick={handleEmailClick}>
                            < ContentCopySharp sx={{ fontSize: '15px' }} />
                        </IconButton>
                    </Box>
                    <Box display='flex' gap='5px' alignItems={'center'}>
                        <Typography fontWeight='bold' fontSize='15px'>Password:</Typography>
                        <Typography color='#757575' fontSize='15px'>•••••••</Typography>
                        <IconButton onClick={handlePassClick}>
                            < ContentCopySharp sx={{ fontSize: '15px' }} />
                        </IconButton>
                    </Box>
                </Box>

                <Box display='flex' justifyContent={'flex-end'}>
                    <Button variant="contained" sx={{
                        textTransform: 'none',
                        fontSize: 15,
                        borderRadius: '10px',
                        transition: 'all 0.5s ease-in-out',
                        backgroundColor: '#eeeeee',
                        color: 'black',
                        marginTop: '10px',
                        '&:hover': {
                            transform: 'scale(1.1)',
                            backgroundColor: '#e0e0e0',

                        },
                    }}
                        href='http://141.147.119.53:5080/#/pages/login'
                        target='_blank'
                        onClick={() => warning(id)}

                    >Go to Server</Button>
                </Box>

                <Button variant="contained" color='error' sx={{
                    textTransform: 'none',
                    fontSize: 15,
                    width: '100%',
                    borderRadius: '10px',
                    transition: 'all 0.5s ease-in-out',
                    marginTop: '20px',

                }}
                    onClick={handleClick}

                >
                    {res.isLoading ? <CircularProgress sx={{ color: 'white' }} size={25} /> : 'Start Streaming'}
                </Button>
            </Box>

        </Dialog>
    )
}

export default Server5