import { Box, styled } from "@mui/material";


const SidebarConatiner = styled(Box)(({ theme }) => ({

    position: 'fixed',
    top: '0',
    left: '0',
    bottom: '0',
    height: '100vh',
    backgroundColor: 'white',
    width: '300px',
    overflow : 'auto',
    zIndex: '2',
    [theme.breakpoints.down('md')]:{
       top : '0',
       paddingTop:'60px',
       
    },

    '.active-link': {
        display: 'flex',
        textDecoration: 'none',
        color: '#00DEA3',
        gap: '18px',
        color: 'white',
        backgroundColor: '#ef5350',
        alignItems : 'center',
        padding: '5px',
        borderRadius: '8px',
    },

    '.unactive-link': {
        display: 'flex',
        textDecoration: 'none',
        alignItems : 'center',
        gap: '18px',
        padding: '5px',
        color: theme.palette.grey[700],
        transition: 'all 0.4s ease-in-out',
        cursor : 'pointer',
        '&:hover': {
            color: theme.palette.grey[900],

        }
    }

}))

export {
    SidebarConatiner,

}