import { Add, Cancel, Remove } from '@mui/icons-material'
import { Alert, Box, Button, Dialog, IconButton, Snackbar, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import StatesContext from '../../../../context/StatesContext'
import { useAdminAddTimeMutation } from '../../../../services/userApi'
import { StyledButtonCart, StyledInputCart } from '../../../User/MakePlans/style'

const AddTime = ({ openAdd, setopenAdd, myId }) => {


  const context = useContext(StatesContext)
  const { setisTimeUpdated } = context

  const [months, setmonths] = useState()
  const [days, setdays] = useState()
  const [minutes, setminutes] = useState()
  const [seconds, setseconds] = useState()
  const [hours, sethours] = useState()
  const [openAlert, setOpenAlert] = useState(false);
  const [mxg, setmxg] = useState('')
  const [quantity, setquantity] = useState(0)


  const [AddTime, res] = useAdminAddTimeMutation()

  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };

  const handleClick = () => {
    let time = 0
    if (months) {
      time = time + (months * (30 * 86400000))
    }
    if (days) {
      time = time + (days * 86400000)
    }
    if (hours) {
      time = time + (hours * 3600000)
    }
    if (minutes) {
      time = time + (minutes * 60000)
    }
    if (seconds) {
      time = time + (seconds * 1000)
    }

    AddTime({ myId, time, quantity })
  }

  const increaseVideoQuantity = () => {

    if (days && !months || months < 1) {
      if (days < 8 && quantity === 2) {
        setmxg(`The maximum limit of videos in ${days} days is 2`)
        setOpenAlert(true)
      } else if (days < 15 && quantity === 4) {
        setmxg(`The maximum limit of videos in ${days} days is 4`)
        setOpenAlert(true)
      } else if (days < 22 && quantity === 6) {
        setmxg(`The maximum limit of videos in ${days} days is 6`)
        setOpenAlert(true)
      } else if (days > 29 && quantity === 8 || quantity === 8) {
        setmxg(`The maximum limit of videos is 8`)
        setOpenAlert(true)
      } else if (quantity < 8) {
        setquantity(quantity + 1)
      }
    } else {
      if (quantity === 8) {
        setmxg(`The maximum limit of videos is 8`)
        setOpenAlert(true)
      } else {
        if (days || months) {
          setquantity(quantity + 1)
        }
      }
    }

    if (months && months > 0) {
      if (quantity === 8) {
        setmxg(`The maximum limit of videos is 8`)
        setOpenAlert(true)
      } else {
        setquantity(quantity + 1)
      }
    }

  }


  const decreaseVideoQuantity = () => {

    if (quantity <= 0) return;
    setquantity(quantity - 1)

  }

  const handleClose = (e) => {
    if (e === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  useEffect(() => {

    setquantity(0)

  }, [months, days])

  useEffect(() => {

    if (res.status === 'fulfilled') {
      setopenAdd(false)
      setisTimeUpdated(true)
    }

  }, [res])


  return (
    <>
      <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center', }}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }} >
          {mxg}
        </Alert>
      </Snackbar>
      <Dialog
        open={openAdd}
        onClose={() => setopenAdd(false)}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: {
            width: { xs: '94%', sm: '400px' },
            borderRadius: '20px'
          }
        }}

      >
        <Box padding='25px' >

          <Box display={'flex'} justifyContent='flex-end' >
            <IconButton onClick={() => setopenAdd(false)}>
              <Cancel />
            </IconButton>
          </Box>
          <Box marginTop='20px'
            sx={{
              backgroundColor: '#ef5350',
              borderRadius: '10px',
              padding: '7px 0'
            }}>
            <Typography fontWeight={'500'} fontSize='20px' color='white' textAlign='center'>
              Time Addition
            </Typography>
          </Box>

          <Box marginTop={'30px'} display='flex' gap='5px' justifyContent={'center'} alignItems='center'>

            <TextField
              onKeyDown={preventMinus}
              type="number"
              id='number'
              placeholder='00'
              value={months}
              onChange={(e) => setmonths(e.target.value)}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontSize: 23,
                  width: '40px',
                  maxWidth: 'auto'
                },
              }}
              sx={{

                '& .MuiInputBase-input': {
                  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                  },
                },
                '& .MuiInputBase-root': {
                  border: '1px solid #e0e0e0',
                  padding: '0 5px',
                  height: '40px',
                  borderRadius: '5px',
                  transition: 'all 0.4s ease-in-out',
                  backgroundColor: '#f5f5f5',

                },
                '& .Mui-focused': {
                  backgroundColor: 'black !important',
                  color: 'white',
                  '& .MuiSvgIcon-root': {
                    color: 'white'
                  },
                  '& ::placeholder': {
                    color: 'white',
                    opacity: '0.7'
                  }
                },

              }}
              variant="standard"
            />
            <Typography fontWeight='bold'>:</Typography>
            <TextField
              onKeyDown={preventMinus}
              type="number"
              id='number'
              placeholder='00'
              value={days}
              onChange={(e) => setdays(e.target.value)}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontSize: 23,
                  width: '40px',
                  maxWidth: 'auto'
                },
              }}
              sx={{

                '& .MuiInputBase-input': {
                  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                  },
                },
                '& .MuiInputBase-root': {
                  border: '1px solid #e0e0e0',
                  padding: '0 5px',
                  height: '40px',
                  borderRadius: '5px',
                  transition: 'all 0.4s ease-in-out',
                  backgroundColor: '#f5f5f5',

                },
                '& .Mui-focused': {
                  backgroundColor: 'black !important',
                  color: 'white',
                  '& .MuiSvgIcon-root': {
                    color: 'white'
                  },
                  '& ::placeholder': {
                    color: 'white',
                    opacity: '0.7'
                  }
                },

              }}
              variant="standard"
            />
            <Typography fontWeight='bold'>:</Typography>
            <TextField
              onKeyDown={preventMinus}
              type="number"
              id='number'
              placeholder='00'
              value={hours}
              onChange={(e) => sethours(e.target.value)}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontSize: 23,
                  width: '40px',
                  maxWidth: 'auto'
                },
              }}
              sx={{

                '& .MuiInputBase-input': {
                  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                  },
                },
                '& .MuiInputBase-root': {
                  border: '1px solid #e0e0e0',
                  padding: '0 5px',
                  height: '40px',
                  borderRadius: '5px',
                  transition: 'all 0.4s ease-in-out',
                  backgroundColor: '#f5f5f5',

                },
                '& .Mui-focused': {
                  backgroundColor: 'black !important',
                  color: 'white',
                  '& .MuiSvgIcon-root': {
                    color: 'white'
                  },
                  '& ::placeholder': {
                    color: 'white',
                    opacity: '0.7'
                  }
                },

              }}
              variant="standard"
            />
            <Typography fontWeight='bold'>:</Typography>
            <TextField
              onKeyDown={preventMinus}
              type="number"
              id='number'
              placeholder='00'
              value={minutes}
              onChange={(e) => setminutes(e.target.value)}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontSize: 23,
                  width: '40px',
                  maxWidth: 'auto'
                },
              }}
              sx={{

                '& .MuiInputBase-input': {
                  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                  },
                },
                '& .MuiInputBase-root': {
                  border: '1px solid #e0e0e0',
                  padding: '0 5px',
                  height: '40px',
                  borderRadius: '5px',
                  transition: 'all 0.4s ease-in-out',
                  backgroundColor: '#f5f5f5',

                },
                '& .Mui-focused': {
                  backgroundColor: 'black !important',
                  color: 'white',
                  '& .MuiSvgIcon-root': {
                    color: 'white'
                  },
                  '& ::placeholder': {
                    color: 'white',
                    opacity: '0.7'
                  }
                },

              }}
              variant="standard"
            />
            <Typography fontWeight='bold'>:</Typography>
            <TextField
              onKeyDown={preventMinus}
              type="number"
              id='number'
              value={seconds}
              onChange={(e) => setseconds(e.target.value)}
              placeholder='00'
              InputProps={{
                disableUnderline: true,
                style: {
                  fontSize: 23,
                  width: '40px',
                  maxWidth: 'auto'
                },
              }}
              sx={{

                '& .MuiInputBase-input': {
                  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                  },
                },
                '& .MuiInputBase-root': {
                  border: '1px solid #e0e0e0',
                  padding: '0 5px',
                  height: '40px',
                  borderRadius: '5px',
                  transition: 'all 0.4s ease-in-out',
                  backgroundColor: '#f5f5f5',

                },
                '& .Mui-focused': {
                  backgroundColor: 'black !important',
                  color: 'white',
                  '& .MuiSvgIcon-root': {
                    color: 'white'
                  },
                  '& ::placeholder': {
                    color: 'white',
                    opacity: '0.7'
                  }
                },

              }}
              variant="standard"
            />
          </Box>

          <Box display='flex' flexDirection={'column'} gap='10px' alignItems={'center'} marginTop={'20px'} >

            <Box display='flex'>
              <Typography fontWeight='bold' fontSize={{ xs: '12px', sm: '20px' }}>Number of Videos</Typography>
            </Box>
            <Box display='flex' justifyContent={'flex-start'} alignItems='center'>
              <Box display={'flex'} alignItems='center'>
                <StyledButtonCart
                  sx={{
                    borderTopRightRadius: '20px',
                    borderBottomRightRadius: '20px',
                  }}
                  onClick={decreaseVideoQuantity}
                ><Remove sx={{ fontSize: '20px' }} /></StyledButtonCart>

                <StyledInputCart ><Typography fontWeight={'bold'}>{quantity}</Typography></StyledInputCart>
                <StyledButtonCart onClick={increaseVideoQuantity}
                  sx={{
                    borderTopLeftRadius: '20px',
                    borderBottomLeftRadius: '20px'
                  }}
                ><Add sx={{ fontSize: '20px' }} /></StyledButtonCart>
              </Box>
            </Box>
          </Box>

          <Button variant="contained" color='error' component="label" sx={{
            textTransform: 'none', fontSize: '18px', fontWeight: '500', marginTop: '30px', textTransform: 'none',
            borderRadius: '10px',
            fontSize: '17px',
            width: '100%'
          }}
            onClick={handleClick}
          >
            Add
          </Button>
        </Box>

      </Dialog>
    </>
  )
}

export default AddTime