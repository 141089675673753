import { Backdrop, Box, Button, Grid, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import { motion } from 'framer-motion';
import Sidebar from '../Sidebar/Sidebar';
import { StyledNavbar } from '../Dashboard/style';
import { Circle, Menu } from '@mui/icons-material';
import Header from '../Header/Header';
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Checkout from './Checkout/Checkout';

const Pricing = () => {

    const [open, setopen] = useState(false)
    const [price, setprice] = useState('')
    const [packageName, setpackageName] = useState('')
    const [openCheckout, setopenCheckout] = useState(false)
    const [largeOpen, setlargeOpen] = useState(true)
    const [animation, setanimation] = useState([])
    const [sidebarAnimation, setsidebarAnimation] = useState([])

    const datakey = 'pk_live_51M6id0A40t8dD6c6AOi1EmVw6mfcOt4uzbKDk4XoI2YYGwMArT72MCFftNEp2KDe2Wsh0BYNyeJLksThZjVMT4k600XPmmczia'
    const [stripePromise, setStripePromise] = useState(() => loadStripe(datakey))

    const handleClick = (value, name) => {

        setprice(value)
        setpackageName(name)
        setopenCheckout(true)
    }

    return (
        <StyledNavbar padding={largeOpen && '1.5rem'}>
            <Backdrop open={open} onClick={() => setopen(false)} sx={{ zIndex: '1' }} />

            <Box display={{ xs: 'flex', md: 'none', position: 'fixed', top: '0', left: '0', zIndex: '1' }} sx={{ padding: '15px' }}>
                <IconButton onClick={() => setopen(!open)} sx={{ color: 'black' }}>
                    <Menu />
                </IconButton>
            </Box>

            <Box>
                {open && (
                    <Box>
                        <motion.div

                            animate={{ x: [-300, 0] }}
                            transition={{ duration: 0.85, ease: 'easeOut' }}
                            className='myMenu'
                        >
                            <Sidebar open={open} setopen={setopen} />
                        </motion.div>

                    </Box>
                )}

            </Box>

            <Box display={{ xs: 'none', md: 'flex' }} >
                <motion.div
                    animate={{ x: sidebarAnimation }}
                    transition={{ duration: 0.85, ease: 'easeOut' }}
                    className='myMenu'
                >
                    <Sidebar largeOpen={largeOpen} setlargeOpen={setlargeOpen} setanimation={setanimation} setsidebarAnimation={setsidebarAnimation} />
                </motion.div>
            </Box>

            <Box marginLeft={{ md: largeOpen ? '300px' : '0' }}>
                <motion.div
                    animate={{ x: animation }}
                    transition={{ duration: 0.85, ease: 'easeOut' }}
                >
                    {!largeOpen && (
                        <Box display={{ xs: 'none', md: 'flex' }} paddingTop='3px' paddingLeft={'3px'}>
                            <IconButton onClick={() => {
                                setlargeOpen(true)
                                setanimation([-300, 0])
                                setsidebarAnimation([-300, 0])
                            }}>
                                <Menu />
                            </IconButton>
                        </Box>
                    )}

                    <Box padding={!largeOpen && '0.4rem 1.5rem'}>


                        <Header />

                        <Box marginTop={{ xs: '40px' }}>
                            <Typography fontFamily={'roboto'} textAlign={'center'} fontWeight='bold' fontSize='35px'>
                                Are you ready to try?
                            </Typography>
                            <Typography color='#757575' textAlign={'center'} fontFamily={'roboto'} fontWeight='bold' fontSize={{ xs: '14px', sm: '18px' }} >Or run 8 loop videos with the Champion Package.</Typography>
                            <Typography color='#757575' textAlign={'center'} fontFamily={'roboto'} fontWeight='bold' fontSize={{ xs: '14px', sm: '18px' }} >You choose.</Typography>
                        </Box>

                        <Grid container spacing={4} sx={{ marginTop: '15px', display: 'flex', justifyContent: 'center', padding: { xs: '0 30px', sm: '0', lg: '0 50px' } }}>

                            <Grid item xs={12} sm={6} md={4} >
                                <Box
                                    sx={{
                                        backgroundColor: 'white',
                                        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                                        padding: '22px 22px',
                                        borderRadius: '12px',
                                        transition: 'all 0.6s ease-in-out',
                                        '&:hover': {
                                            transform: 'scale(1.1)'
                                        }
                                    }}
                                >
                                    <Box display='flex' justifyContent={'space-between'} gap='20px' alignItems={'baseline'}>
                                        <Typography fontWeight='bold' color='#1F51FF' fontSize='30px'>Taster</Typography>
                                        <Box display='flex' alignItems={'baseline'}>
                                            <Typography fontSize={'16px'} color='#1F51FF'>$</Typography>
                                            <Typography fontSize='25px' color='#1F51FF' fontWeight={'bold'}>01.</Typography>
                                            <Typography fontSize={'19px'} color='#1F51FF' fontWeight={'bold'}>00</Typography>
                                        </Box>
                                    </Box>
                                    <Typography color='#757575' textAlign={'center'} fontSize={'13px'} paddingTop='10px' paddingBottom={'25px'} borderBottom={'1px solid #bdbdbd'}>The perfect solution to try it out</Typography>
                                    <Box padding={'16px 5px'} borderBottom={'1px solid #bdbdbd'}>

                                        <Box display={'flex'} justifyContent='space-between' alignItems='baseline'>
                                            <Typography fontWeight={'bold'} color='#616161' fontSize='13px'>Number of days</Typography>
                                            <Typography fontWeight={'bold'} fontSize='15px'>1</Typography>
                                        </Box>
                                        <Box display={'flex'} justifyContent='space-between' alignItems='baseline'>
                                            <Typography fontWeight={'bold'} color='#616161' fontSize='13px'>Number of videos</Typography>
                                            <Typography fontWeight={'bold'} fontSize='15px'>1</Typography>
                                        </Box>
                                        <Box display={'flex'} justifyContent='space-between' alignItems='baseline'>
                                            <Typography fontWeight={'bold'} color='#616161' fontSize='13px'>Stream duration </Typography>
                                            <Typography fontWeight={'bold'} fontSize='15px'>24/7</Typography>
                                        </Box>
                                    </Box>

                                    <Box marginTop='15px'>
                                        <Box display='flex' alignItems='center' gap='15px'>
                                            <Circle sx={{ fontSize: '10px', color: '#d32f2f' }} />
                                            <Typography color='#9e9e9e' fontWeight={'bold'} fontSize={'13px'}>Support service in your online account</Typography>
                                        </Box>
                                        <Box display='flex' alignItems='center' gap='15px' marginTop='5px'>
                                            <Circle sx={{ fontSize: '10px', color: '#d32f2f' }} />
                                            <Typography color='#9e9e9e' fontWeight={'bold'} fontSize={'13px'}>Video tutorial for a successful start</Typography>
                                        </Box>
                                    </Box>
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            textTransform: 'none',
                                            width: '100%',
                                            borderColor: 'black',
                                            borderRadius: '24px',
                                            marginTop: '30px',
                                            marginBottom: '10px',
                                            color: 'black',
                                            backgroundColor: '#f5f5f5',
                                            fontSize: '17px',
                                            transition: 'all 0.5s ease-in-out',
                                            '&:hover': {
                                                backgroundColor: '#1F51FF',
                                                color: 'white'
                                            }
                                        }}
                                        onClick={() => handleClick(1, 'basic')}

                                    >Buy now
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box
                                    sx={{
                                        backgroundColor: 'white',
                                        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                                        padding: '22px 22px',
                                        borderRadius: '12px',
                                        transition: 'all 0.6s ease-in-out',
                                        '&:hover': {
                                            transform: 'scale(1.1)'
                                        }
                                    }}
                                >
                                    <Box display='flex' justifyContent={'space-between'} gap='20px' alignItems={'baseline'}>
                                        <Typography fontWeight='bold' color='green' fontSize='30px'>Runner</Typography>
                                        <Box display='flex' alignItems={'baseline'}>
                                            <Typography fontSize={'16px'} color='green'>$</Typography>
                                            <Typography fontSize='25px' color='green' fontWeight={'bold'}>06.</Typography>
                                            <Typography fontSize={'19px'} color='green' fontWeight={'bold'}>00</Typography>
                                        </Box>
                                    </Box>
                                    <Typography color='#757575' textAlign={'center'} fontSize={'13px'} paddingTop='10px' paddingBottom={'25px'} borderBottom={'1px solid #bdbdbd'}>The perfect solution to try it out</Typography>
                                    <Box padding={'16px 5px'} borderBottom={'1px solid #bdbdbd'}>

                                        <Box display={'flex'} justifyContent='space-between' alignItems='baseline'>
                                            <Typography fontWeight={'bold'} color='#616161' fontSize='13px'>Number of days</Typography>
                                            <Typography fontWeight={'bold'} fontSize='15px'>7</Typography>
                                        </Box>
                                        <Box display={'flex'} justifyContent='space-between' alignItems='baseline'>
                                            <Typography fontWeight={'bold'} color='#616161' fontSize='13px'>Number of videos</Typography>
                                            <Typography fontWeight={'bold'} fontSize='15px'>2</Typography>
                                        </Box>
                                        <Box display={'flex'} justifyContent='space-between' alignItems='baseline'>
                                            <Typography fontWeight={'bold'} color='#616161' fontSize='13px'>Stream duration </Typography>
                                            <Typography fontWeight={'bold'} fontSize='15px'>24/7</Typography>
                                        </Box>
                                    </Box>

                                    <Box marginTop='15px'>
                                        <Box display='flex' alignItems='center' gap='15px'>
                                            <Circle sx={{ fontSize: '10px', color: '#d32f2f' }} />
                                            <Typography color='#9e9e9e' fontWeight={'bold'} fontSize={'13px'}>Support service in your online account</Typography>
                                        </Box>
                                        <Box display='flex' alignItems='center' gap='15px' marginTop='5px'>
                                            <Circle sx={{ fontSize: '10px', color: '#d32f2f' }} />
                                            <Typography color='#9e9e9e' fontWeight={'bold'} fontSize={'13px'}>Video tutorial for a successful start</Typography>
                                        </Box>
                                    </Box>
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            textTransform: 'none',
                                            width: '100%',
                                            borderColor: 'black',
                                            borderRadius: '24px',
                                            marginTop: '30px',
                                            marginBottom: '10px',
                                            color: 'black',
                                            backgroundColor: '#f5f5f5',
                                            fontSize: '17px',
                                            transition: 'all 0.5s ease-in-out',
                                            '&:hover': {
                                                backgroundColor: 'green',
                                                color: 'white'
                                            }
                                        }}
                                        onClick={() => handleClick(6, 'standard')}

                                    >Buy now
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}  >
                                <Box
                                    sx={{
                                        backgroundColor: 'white',
                                        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                                        padding: '22px 22px',
                                        borderRadius: '12px',
                                        transition: 'all 0.6s ease-in-out',
                                        '&:hover': {
                                            transform: 'scale(1.1)'
                                        }
                                    }}
                                >
                                    <Box display='flex' justifyContent={'space-between'} gap='20px' alignItems={'baseline'}>
                                        <Typography fontWeight='bold' color='#1F51FF' fontSize='30px'>Champion</Typography>
                                        <Box display='flex' alignItems={'baseline'}>
                                            <Typography fontSize={'16px'} color='#1F51FF'>$</Typography>
                                            <Typography fontSize='25px' color='#1F51FF' fontWeight={'bold'}>29.</Typography>
                                            <Typography fontSize={'19px'} color='#1F51FF' fontWeight={'bold'}>00</Typography>
                                        </Box>
                                    </Box>
                                    <Typography color='#757575' textAlign={'center'} fontSize={'13px'} paddingTop='10px' paddingBottom={'25px'} borderBottom={'1px solid #bdbdbd'}>The perfect solution to try it out</Typography>
                                    <Box padding={'16px 5px'} borderBottom={'1px solid #bdbdbd'}>

                                        <Box display={'flex'} justifyContent='space-between' alignItems='baseline'>
                                            <Typography fontWeight={'bold'} color='#616161' fontSize='13px'>Number of days</Typography>
                                            <Typography fontWeight={'bold'} fontSize='15px'>30</Typography>
                                        </Box>
                                        <Box display={'flex'} justifyContent='space-between' alignItems='baseline'>
                                            <Typography fontWeight={'bold'} color='#616161' fontSize='13px'>Number of videos</Typography>
                                            <Typography fontWeight={'bold'} fontSize='15px'>8</Typography>
                                        </Box>
                                        <Box display={'flex'} justifyContent='space-between' alignItems='baseline'>
                                            <Typography fontWeight={'bold'} color='#616161' fontSize='13px'>Stream duration </Typography>
                                            <Typography fontWeight={'bold'} fontSize='15px'>24/7</Typography>
                                        </Box>
                                    </Box>

                                    <Box marginTop='15px'>
                                        <Box display='flex' alignItems='center' gap='15px'>
                                            <Circle sx={{ fontSize: '10px', color: '#d32f2f' }} />
                                            <Typography color='#9e9e9e' fontWeight={'bold'} fontSize={'13px'}>Support service in your online account</Typography>
                                        </Box>
                                        <Box display='flex' alignItems='center' gap='15px' marginTop='5px'>
                                            <Circle sx={{ fontSize: '10px', color: '#d32f2f' }} />
                                            <Typography color='#9e9e9e' fontWeight={'bold'} fontSize={'13px'}>Video tutorial for a successful start</Typography>
                                        </Box>
                                    </Box>
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            textTransform: 'none',
                                            width: '100%',
                                            borderColor: 'black',
                                            borderRadius: '24px',
                                            marginTop: '30px',
                                            marginBottom: '10px',
                                            color: 'black',
                                            backgroundColor: '#f5f5f5',
                                            fontSize: '17px',
                                            transition: 'all 0.5s ease-in-out',
                                            '&:hover': {
                                                backgroundColor: '#1F51FF',
                                                color: 'white'
                                            }
                                        }}
                                        onClick={() => handleClick(29, 'diamond')}

                                    >Buy now
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </motion.div>
            </Box>
            {openCheckout && (
                <Elements stripe={stripePromise}>
                    <Checkout openCheckout={openCheckout} setopenCheckout={setopenCheckout} price={price} packageName={packageName} />
                </Elements>
            )}

        </StyledNavbar>
    )
}

export default Pricing