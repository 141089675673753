import { Backdrop, Box, Grid, IconButton, Typography } from "@mui/material";
import { motion } from 'framer-motion';
import { Menu, ReadMoreOutlined, Settings } from "@mui/icons-material";
import Sidebar from '../Sidebar/Sidebar'
import { RegisteredUserCon, StyledUser } from "../Users/style";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { NoProductHeading, StyledPagination } from "../Users/PackageHistory/style";
import { useAllusersQuery } from "../../../services/userApi";
import { Loader } from "../../../Components";


const Warnings = () => {

    const [open, setopen] = useState(false)
    const navigate = useNavigate()

    const [currentPage, setcurrentPage] = useState(1)

    let status = 'Warning'

    const { data, isFetching } = useAllusersQuery({ status, currentPage })


    return (
        <StyledUser>

            <Box display={{ xs: 'none', md: 'flex' }} >
                <Sidebar />
            </Box>
            <Box display={{ xs: 'flex', md: 'none' }} sx={{ position: 'fixed', top: '10px', left: '10px', zIndex: '1' }}>
                <IconButton onClick={() => setopen(!open)} >
                    <Menu />
                </IconButton>
            </Box>
            <Backdrop open={open} onClick={() => setopen(false)} />

            <Box>
                {open && (
                    <Box>
                        <motion.div

                            animate={{ x: [-300, 0] }}
                            transition={{ duration: 0.85, ease: 'easeOut' }}
                            className='myMenu'

                        >

                            <Sidebar open={open} setopen={setopen} />
                        </motion.div>

                    </Box>
                )}

            </Box>

            <Box marginLeft={{ md: '310px' }} className="user-con">
                <Box bgcolor={'white'} borderRadius='35px' padding='1rem' minHeight={{ md: '80vh', lg: '88vh' }}>
                    <Box display='flex' justifyContent={'space-between'}>
                        <Typography fontWeight={'bold'} fontSize={{ xs: '25px', md: '35px' }} >Warnings</Typography>
                    </Box>
                    <Box>
                        {isFetching ? <Loader height={'75vh'} /> : (
                            <Box>
                                <Box display='flex' flexDirection={'column'} gap='1rem' marginTop={'10px'} >
                                    {data.allUsers.length > 0 && data.allUsers.map((item, i) => {

                                        let myDate = new Date(item.createdAt).toDateString()

                                        return <RegisteredUserCon key={i} bgcolor={item.status === 'Warning' ? 'rgba(255,0,0,0.2)' : '#F1EBEB'}>
                                            <Box display='flex' alignItems={'center'} gap={{ xs: '20px', sm: '30px' }}>
                                                <Box component={'img'} src={item.avatar.url} height={{ xs: '70px', sm: '90px' }} width={{ xs: '70px', sm: '90px' }} borderRadius={'50px'} sx={{ objectFit: 'cover' }} />
                                                <Box display='flex' flexDirection='column'>
                                                    <Box>
                                                        <Typography fontWeight={'bold'} fontSize={{ xs: '20px', sm: '25px' }}>{item.name}</Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography fontWeight={'bold'} fontSize={{ xs: '15px', sm: '20px' }}>{item.email}</Typography>
                                                    </Box>
                                                    <Box display={'flex'} gap='10px'>
                                                        <Typography fontWeight={'bold'} fontSize={{ xs: '15px', sm: '20px' }}>Status:</Typography>
                                                        <Typography fontWeight={'bold'} fontSize={{ xs: '15px', sm: '20px' }} color={item.status === 'Warning' ? 'yellow' : item.status === 'Pending' || item.status === 'Processing' ? '#AA336A' : 'green'}
                                                        >{item.status === 'Processing' ? 'Updating' : item.status}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <IconButton onClick={() => navigate(`/admin/user/credentials/${item._id}`, { state: { data: item } })}>
                                                    <ReadMoreOutlined />
                                                </IconButton>
                                            </Box>
                                        </RegisteredUserCon>

                                    })}
                                </Box>

                            </Box>
                        )}

                        {!isFetching && data && data.allUsers.length === 0 && (
                            <Box display={'flex'} justifyContent='center' marginTop={{ md: '5em' }}>
                                <NoProductHeading>No Warnings Found</NoProductHeading>
                            </Box>
                        )}

                        {data && data.resultPerPage < data.filteredUsersCount && (

                            <StyledPagination color='primary' size='large'
                                count={Math.ceil(data.filteredUsersCount / data.resultPerPage)}
                                page={currentPage}
                                shape='rounded'
                                onChange={(event, value) => setcurrentPage(value)}
                                showLastButton showFirstButton hideNextButton hidePrevButton
                                onClick={() => window.scrollTo(0, 0)}

                            />
                        )}

                    </Box>
                </Box>
            </Box>

        </StyledUser>
    )
}
export default Warnings;