import { Box, styled } from "@mui/material";


const StyledUser = styled(Box)(({ theme }) => ({

  '.myMenu': {
    backgroundColor: 'white',
    position: 'fixed',
    top: '0',
    left: '0',
    Bottom: '0',
    height: '100vh',
    width: '50%',
    zIndex: '5',
    [theme.breakpoints.down('sm')]: {
      width: '65%'
    }

  },

  '.user-con': {
    backgroundColor: '#f2f7fb',
    minHeight: '100vh',
    padding: '1.7rem 1rem',

    [theme.breakpoints.down('sm')]: {
      padding: '3rem 10px'
    }
  },


  '.feild': {

    width: '290px',
    border: '1px solid #e0e0e0',
    padding: '7px 20px',
    borderRadius: '25px',
    backgroundColor: '#F2F1F1',
    transition: 'all 0.4s ease-in-out',
    [theme.breakpoints.down('lg')]: {
      width: '220px',

    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',

    }

  },

  "& .MuiOutlinedInput-input": {
    fontFamily: 'Roboto',
    width: '240px',
    fontSize: 15,
    borderRadius: '25px',
    backgroundColor: '#F2F1F1',
    padding: '7px 20px',
    [theme.breakpoints.down('lg')]: {
      width: '170px',

    },
    [theme.breakpoints.down('sm')]: {
      width: '182px',

    }

  },
  "& .MuiInputLabel-root": {
    color: theme.palette.grey[500],
    fontSize: 15,
    borderColor: "transparent",
    borderRadius: '25px',

  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
    color: theme.palette.grey[500],
    borderRadius: '25px',

  },
  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
    borderRadius: '25px',
  },
  "&:focus .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
    borderRadius: '25px',
  },
  '& .Mui-focused': {
    borderColor: "transparent",
    borderRadius: '25px',
  },


}))


const RegisteredUserCon = styled(Box)(({ theme }) => ({

  width: '100%',
  borderRadius: '20px',
  padding: '23px',
  display: 'flex',
  justifyContent: 'space-between',
  gap:'20px',
  alignItems: 'center',
  overflow: 'auto'

}))

const StyledSelect = styled(Box)(({ theme }) => ({

  "& .MuiOutlinedInput-input": {
    color: theme.palette.grey[900],
    backgroundColor: '#f2f7fb',
    transition: 'all 0.4s ease-in-out',
    fontFamily: 'Roboto',
    borderRadius: '20px',
    fontSize: '17px',

    '&:hover': {
      boxShadow: '0 0 25px #f2f7fb'
    },

  },
  "& .MuiInputLabel-root": {
    color: theme.palette.grey[500],
    fontSize: '17px',
    paddingTop: '6px',
    borderRadius: '20px',


  },
  '& .MuiSvgIcon-root': {
    transition: 'all 0.7s ease-in-out'
  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
    borderRadius: '20px',
    color: theme.palette.grey[500]
  },
  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
    borderRadius: '20px',

  },

}))

export {
  StyledUser,
  RegisteredUserCon,
  StyledSelect
}