import { Alert, Backdrop, Box, Button, Avatar, Grid, IconButton, Snackbar, styled, Typography, TextField, InputAdornment, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Sidebar from '../Sidebar/Sidebar';
import { StyledNavbar } from '../Dashboard/style';
import { ContentCopyOutlined, Menu, Link } from '@mui/icons-material';
import Header from '../Header/Header';
import { motion } from 'framer-motion';
import img from '../../../assets/logo.jpeg'
import { Vortex } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom'

import { useDeleteVideoMutation, useGetAwsUrlMutation } from '../../../services/awsApi';
import axios from "axios";
import StatesContext from '../../../context/StatesContext';
import { useUploadVideosMutation } from '../../../services/userApi';



const bounceTransition = {
    y: {
        duration: 0.5,
        yoyo: Infinity,
        ease: "easeOut",
    },
    backgroundColor: {
        duration: 0,
        yoyo: Infinity,
        ease: "easeOut",
        repeatDelay: 0.8,
    },
}

const UploadVideo = () => {

    const context = useContext(StatesContext)
    const { uploading, setuploading, progress, setprogress, setUserInfo, userInfo } = context

    const [open, setopen] = useState(false)

    const [largeOpen, setlargeOpen] = useState(true)
    const [animation, setanimation] = useState([])
    const [sidebarAnimation, setsidebarAnimation] = useState([])

    const [mxg, setmxg] = useState('')
    const [openAlert, setOpenAlert] = useState(false);

    const [myVideo, setmyVideo] = useState('')
    const abortController = useRef(null);
    const navigate = useNavigate()

    const [link, res] = useGetAwsUrlMutation()
    const [saveVideo, saveRes] = useUploadVideosMutation()
    const [deleteVideo, delRes] = useDeleteVideoMutation()

    const smallerthen600 = useMediaQuery('(max-width:600px)')

    useEffect(() => {

        if (res.status === 'fulfilled') {

            setuploading(true)
            // post the image direclty to the s3 bucket

            async function fetchData() {

                abortController.current = new AbortController();
                setprogress(0)

                await axios({
                    signal: abortController.current.signal,
                    method: "PUT",
                    url: res.data.url,
                    data: myVideo,
                    headers: { "Content-Type": "multipart/form-data" },
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let percent = Math.floor((loaded * 100) / total)
                        setprogress(percent)
                    }

                })
                    .then(function (response) {

                        const imageUrl = response.config.url.split('?')[0]
                        setuploading(false)
                        setprogress(0)
                        setmyVideo('')
                        let videoUrl = imageUrl
                        let videoId = imageUrl.split('com/')[1]
                        saveVideo({ videoUrl, videoId })

                    }).catch(err => {
                        console.log(err);


                        setprogress(0)
                        setmyVideo('')

                    });
            }
            if (myVideo) {

                fetchData()
            }
        }

    }, [res])

    useEffect(() => {

        if (saveRes.status === 'fulfilled') {
            setUserInfo(saveRes.data.user)
            setOpenAlert(true)
            setmxg('Video has been uploaded successfully!')
            setmyVideo('')
        }


    }, [saveRes])

    useEffect(() => {

        if (delRes.status === 'fulfilled') {
            setUserInfo(delRes.data.user)
            setOpenAlert(true)
            setmxg('Video has been deleted successfully!')
        }


    }, [delRes])


    const handleChange = (e) => {

        setmyVideo(e.target.files[0])
        link()

    }

    const handleClose = (e) => {
        if (e === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };

    const handleSave = (value) => {

        navigator.clipboard.writeText(value)

        setmxg('Copied to clipboard successfully!')

    }

    const cancelRequest = () => {

        abortController.current && abortController.current.abort()
        setmxg('Uploading has been cancelled successfully!')
        setOpenAlert(true)
        setuploading(false)
    };

    return (
        <StyledNavbar padding={largeOpen && '1.5rem'}>

            <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center', }}>

                <Alert onClose={handleClose} severity={'success'} sx={{ width: '100%' }} >
                    {mxg}
                </Alert>

            </Snackbar>
            <Backdrop open={open} onClick={() => setopen(false)} sx={{ zIndex: '1' }} />

            <Box display={{ xs: 'flex', md: 'none', position: 'fixed', top: '0', left: '0', zIndex: '1' }} sx={{ padding: '15px' }}>
                <IconButton onClick={() => setopen(!open)} sx={{ color: 'black' }}>
                    <Menu />
                </IconButton>
            </Box>

            <Box>
                {open && (
                    <Box>
                        <motion.div

                            animate={{ x: [-300, 0] }}
                            transition={{ duration: 0.85, ease: 'easeOut' }}
                            className='myMenu'
                        >
                            <Sidebar open={open} setopen={setopen} />
                        </motion.div>

                    </Box>
                )}

            </Box>

            <Box display={{ xs: 'none', md: 'flex' }} >
                <motion.div
                    animate={{ x: sidebarAnimation }}
                    transition={{ duration: 0.85, ease: 'easeOut' }}
                    className='myMenu'
                >
                    <Sidebar largeOpen={largeOpen} setlargeOpen={setlargeOpen} setanimation={setanimation} setsidebarAnimation={setsidebarAnimation} />
                </motion.div>
            </Box>

            <Box marginLeft={{ md: largeOpen ? '300px' : '0' }}>
                <motion.div
                    animate={{ x: animation }}
                    transition={{ duration: 0.85, ease: 'easeOut' }}
                >
                    {!largeOpen && (
                        <Box display={{ xs: 'none', md: 'flex' }} paddingTop='3px' paddingLeft={'3px'}>
                            <IconButton onClick={() => {
                                setlargeOpen(true)
                                setanimation([-300, 0])
                                setsidebarAnimation([-300, 0])
                            }}>
                                <Menu />
                            </IconButton>
                        </Box>
                    )}

                    <Box padding={!largeOpen && '0.4rem 1.5rem'}>
                        <Header />

                        {userInfo && userInfo.activePackages.length ? (<>

                            <Box display='flex' flexDirection={{ xs: 'column', sm: 'row' }} marginTop='20px' justifyContent={'space-between'} alignItems='center'>
                                <Typography fontSize='35px' fontWeight={'bold'} textAlign={{ xs: 'center', sm: 'unset' }}>My Videos</Typography>
                                {userInfo && userInfo.videosCount < 9 && userInfo.videosCount <= userInfo.uploadVideos.length || userInfo && userInfo.uploadVideos.length < 8 && (
                                    <Box display='flex' gap='10px' marginTop={{ xs: '20px', sm: '0' }} flexDirection={{ xs: 'column', sm: 'row' }}>
                                        <Button variant='outlined'
                                            component="label"
                                            sx={{
                                                textTransform: 'none',
                                                borderRadius: '28px',
                                                padding: '5px 27px',
                                                fontSize: '20px',
                                                borderColor: 'black',
                                                borderWidth: '2px',
                                                color: 'black',
                                                backgroundColor: '#eeeeee',
                                                transition: 'all 0.6s ease-in-out',
                                                '&:hover': {
                                                    backgroundColor: 'black',
                                                    color: 'white',
                                                }
                                            }}
                                            disabled={uploading}
                                        >
                                            Upload Video
                                            <input hidden accept="video/mp4,video/x-m4v,video/*" onChange={(e) => handleChange(e)} type="file" />

                                        </Button>
                                        <Button variant='outlined'
                                            sx={{
                                                textTransform: 'none',
                                                borderRadius: '28px',
                                                padding: '5px 27px',
                                                fontSize: '20px',
                                                borderColor: 'black',
                                                borderWidth: '2px',
                                                color: 'black',
                                                backgroundColor: '#eeeeee',
                                                transition: 'all 0.6s ease-in-out',
                                                '&:hover': {
                                                    backgroundColor: 'black',
                                                    color: 'white',
                                                }
                                            }}
                                            href='mailto:zemaa.entertainment@gmail.com' target={'_blank'}
                                        >
                                            Upload Video Google Drive
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                            <Grid container spacing={2} sx={{ marginTop: '20px' }}>

                                {!uploading && userInfo && userInfo.uploadVideos.length > 0 && userInfo.uploadVideos.map((item, i) => {
                                    let x
                                    if (smallerthen600) {
                                        x = item.videoUrl.length > 20 ? `${item.videoUrl.substring(0, 22)}...` : item.videoUrl

                                    } else {
                                        x = item.videoUrl.length > 20 ? `${item.videoUrl.substring(0, 25)}...` : item.videoUrl

                                    }
                                    return <Grid item xs={12} sm={6} md={4} key={i} >
                                        <Box bgcolor='white' borderRadius={'15px'} padding='15px'>
                                            <Box component='video' src={item.videoUrl} controls sx={{ width: '100%', height: '300px', border: '1px solid #757575', borderRadius: '12px', objectFit: 'cover' }} />
                                            <Box display='flex' gap='7px' marginTop={'15px'} alignItems='center'>
                                                <Typography fontWeight={'bold'} fontSize='14px' width={'90px'} >Live Url:</Typography>
                                                <Box width='100%'
                                                    sx={{
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                    }}
                                                >

                                                    <TextField
                                                        placeholder='Enter Your Title'
                                                        id='Email'
                                                        required
                                                        aria-readonly
                                                        fullWidth
                                                        value={x}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            style: {
                                                                fontSize: 13,
                                                            },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Link sx={{ fontSize: '25px' }} />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        sx={{
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            '& .MuiInputBase-root': {
                                                                border: '1px solid #e0e0e0',
                                                                padding: '5px 10px',
                                                                borderRadius: '10px',
                                                                transition: 'all 0.4s ease-in-out',
                                                                backgroundColor: '#f5f5f5',

                                                            },
                                                            '& .Mui-focused': {
                                                                backgroundColor: 'black !important',
                                                                color: 'white',
                                                                '& .MuiSvgIcon-root': {
                                                                    color: 'white'
                                                                },
                                                                '& ::placeholder': {
                                                                    color: 'white',
                                                                    opacity: '0.7'
                                                                }
                                                            },

                                                        }}
                                                        variant="standard"
                                                    />
                                                </Box>
                                                <IconButton onClick={() => {
                                                    setOpenAlert(true)
                                                    handleSave(item.videoUrl)
                                                }}>
                                                    <ContentCopyOutlined sx={{ fontSize: '18px' }} />
                                                </IconButton>
                                            </Box>
                                            <Box display='flex' alignItems='center' gap='14px' marginTop={'10px'}>
                                                <Typography fontWeight={'bold'} fontSize='14px' textAlign={'center'} width='55px'>Status:</Typography>
                                                <Typography fontWeight={'bold'} fontSize='14px' color={item.status && item.status === 'Active' ? 'green' : '#AA336A'} >{item.status && item.status}</Typography>
                                            </Box>
                                            <Box display='flex' justifyContent={'flex-end'}>
                                                <Button variant="contained" color='error'
                                                    sx={{
                                                        textTransform: 'none',
                                                        borderRadius: '10px',
                                                        fontSize: '13px',
                                                        marginTop: '14px'
                                                    }}
                                                    onClick={() => deleteVideo(item.videoId)}
                                                >
                                                    Delete
                                                </Button>
                                            </Box>
                                        </Box>

                                    </Grid>
                                })}
                            </Grid>
                            {userInfo && userInfo.uploadVideos.length < 9 && (
                                <Box >
                                    {uploading && (
                                        <Box display='flex' flexDirection={'column'} alignItems={{ sm: 'center' }} height={{ sm: '57vh' }} marginTop={{ xs: '100px', sm: '0' }} justifyContent={'center'}>
                                            <Box display='flex' justifyContent={'center'} marginBottom='20px'>
                                                <motion.div
                                                    transition={bounceTransition}
                                                    animate={{
                                                        y: ["15%", "-15%"],
                                                    }}
                                                >
                                                    <Avatar src={img}
                                                        sx={{
                                                            width: { xs: '80px' },
                                                            border: '1px solid #e0e0e0',
                                                            height: { xs: '80px' },
                                                            borderRadius: '50px',

                                                        }}
                                                    />
                                                </motion.div>
                                            </Box>

                                            <Typography color='#ef5350' textAlign='center' fontWeight={'bold'} fontSize={{ xs: '20px', sm: '30px' }}>Uploading</Typography>

                                            <Box display='flex' justifyContent={'center'}>
                                                <Box

                                                    sx={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <Vortex
                                                        visible={true}
                                                        height="150"
                                                        width="150"
                                                        ariaLabel="vortex-loading"
                                                        wrapperStyle={{ backgroundColor: 'black !important' }}
                                                        wrapperClass="vortex-wrapper"
                                                        colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}

                                                    />
                                                    <Box sx={{
                                                        position: 'absolute',
                                                        top: '0',
                                                        bottom: '0',
                                                        right: '0',
                                                        left: '0',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}>

                                                        <Typography textAlign='center' fontSize='15px' color='#757575'>{progress}%</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box display='flex' justifyContent='center'>
                                                <Button variant='contained' size='small'
                                                    sx={{
                                                        textTransform: 'none',
                                                        borderRadius: '10px',
                                                        fontSize: '12px',
                                                        backgroundColor: '#e0e0e0',
                                                        color: 'black',
                                                        fontWeight: 'bold',
                                                        '&:hover': {
                                                            backgroundColor: '#e0e0e0',
                                                            color: 'black',
                                                        }
                                                    }}
                                                    onClick={() => cancelRequest()}                                        >
                                                    Cancel
                                                </Button>
                                            </Box>
                                        </Box>
                                    )}

                                </Box>
                            )}
                        </>) : (
                            <Box display='flex' gap='6px' alignItems={'center'} justifyContent='center' height={{ xs: '30vh', md: '50vh' }}>
                                <Typography color='#757575' textAlign={'center'} fontSize={'15px'}>You don't have any active package please buy an <Typography component={'li'} onClick={() => navigate('/pricing')} sx={{ textDecoration: 'none', listStyleType: 'none', cursor: 'pointer' }} color='#ef5350' textAlign={'center'} fontSize={'15px'}>package.</Typography>
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </motion.div>
            </Box >

        </StyledNavbar >
    )
}

export default UploadVideo