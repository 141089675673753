import { Box, IconButton, styled } from "@mui/material";


const StyledMakePlans = styled(Box)(({ theme }) => ({

    backgroundColor: '#f2f7fb',
    minHeight: '100vh',

    [theme.breakpoints.down('md')]: {
        paddingBottom: '40px'
    },
    '.myMenu': {
        backgroundColor: 'white',
        position: 'fixed',
        top: '0',
        left: '0',
        Bottom: '0',
        height: '100vh',
        width: '300px',
        zIndex: '5',
        [theme.breakpoints.down('sm')]: {
            width: '45%'
        }

    },
    '.cancel-icon': {
        color: 'black',
        '&:hover': {
            backgroundColor: 'white',
            boxShadow: '0 0 20px rgba(0,0,0,0.1)'
        }
    },
    '.email-feild': {

        border: '1px solid #e0e0e0',
        padding: '7px 15px',
        borderRadius: '10px',
        transition: 'all 0.4s ease-in-out',

        [theme.breakpoints.down('sm')]: {
            width: '100%',

        }

    },

    '.unactiveButton': {
        color: theme.palette.error.main,
        textTransform: 'unset',
        fontSize: '17px',
        border: `1px solid ${theme.palette.error.main}`,
        padding: '5px 16px',
    },
    '.activeButton': {
        color: 'white',
        textTransform: 'unset',
        backgroundColor: theme.palette.error.main,
        fontSize: '17px',
        border: `1px solid ${theme.palette.error.main}`,

        padding: '5px 16px',

        '&:hover': {
            backgroundColor: theme.palette.error.main,
            color: 'white',
        }
    },


}))

const StyledInputCart = styled(Box)(({ theme }) => ({

    height: '1.5em',
  
    display: 'flex',
    width : '50px',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
        padding: '0 0.3em',
        height: '20px',


    }
}))


const StyledButtonCart = styled(IconButton)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.error.main,
    color: 'white',
    border: 'none',
    width: '35px',
    height: '30px',
    borderRadius: '1px',
  
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.error.main,
        color: 'white',

    },
    [theme.breakpoints.down('sm')]: {
        width: '30px',
        height: '25px',

    }
}))

export {
    StyledMakePlans,
    StyledInputCart,
    StyledButtonCart
}