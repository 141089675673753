import { Backdrop, Box, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { motion } from 'framer-motion';
import { MailOutlineOutlined, Menu, Settings } from "@mui/icons-material";
import { useAllusersQuery } from "../../../services/userApi";
import Sidebar from '../Sidebar/Sidebar'
import { useNavigate } from "react-router-dom";
import { RegisteredUserCon, StyledSelect, StyledUser } from "./style";
import { Loader } from "../../../Components";
import { NoProductHeading } from "../../User/History/style";
import { StyledPagination } from "../../User/Dashboard/style";

const Users = () => {

    const [currentPage, setcurrentPage] = useState(1)
    const [open, setopen] = useState(false)
    const [status, setStatus] = useState()
    const [selectedValue, setselectedValue] = useState('all')
    const [email, setemail] = useState('')

    const { data, isFetching } = useAllusersQuery({ status, currentPage, email })

    const navigate = useNavigate()

    const handleChange = (e) => {

        setselectedValue(e.target.value)


        if (e.target.value === 'all') {

            setStatus('')
            setcurrentPage(1)


        } else {
            setStatus(e.target.value);
            setcurrentPage(1)
        }


    };



    return (
        <StyledUser>

            <Box display={{ xs: 'none', md: 'flex' }} >
                <Sidebar />
            </Box>
            <Box display={{ xs: 'flex', md: 'none' }} sx={{ position: 'fixed', top: '10px', left: '10px', zIndex: '1' }}>
                <IconButton onClick={() => setopen(!open)} >
                    <Menu />
                </IconButton>
            </Box>
            <Backdrop open={open} onClick={() => setopen(false)} />

            <Box>
                {open && (
                    <Box>
                        <motion.div

                            animate={{ x: [-300, 0] }}
                            transition={{ duration: 0.85, ease: 'easeOut' }}
                            className='myMenu'

                        >

                            <Sidebar open={open} setopen={setopen} />
                        </motion.div>

                    </Box>
                )}

            </Box>

            <Box >

                <Box marginLeft={{ md: '310px' }} className="user-con">
                    <Box bgcolor={'white'} borderRadius='35px' padding='1rem' minHeight={{ md: '80vh', lg: '88vh' }}>
                        <Box display='flex' flexDirection={{ xs: 'column', sm: 'row' }} gap='20px' justifyContent={'space-between'}>
                            <Typography fontWeight={'bold'} fontSize={{ xs: '25px', md: '35px' }} >Registered Users</Typography>
                            <TextField
                                placeholder={'Enter email'}
                                id='Email'
                                onChange={(e) => {
                                    setemail(e.target.value)
                                    setcurrentPage(1)
                                }}
                                InputProps={{
                                    disableUnderline: true,
                                    style: {
                                        fontSize: 15,
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MailOutlineOutlined sx={{ fontSize: '25px' }} />
                                        </InputAdornment>
                                    )
                                }}
                                sx={{

                                    '& .MuiInputBase-root': {
                                        border: '1px solid #e0e0e0',
                                        padding: '7px 17px',
                                        borderRadius: '10px',
                                        transition: 'all 0.4s ease-in-out',
                                        backgroundColor: '#f5f5f5',
                                    },
                                    '& .Mui-focused': {
                                        backgroundColor: 'black !important',
                                        color: 'white',
                                        '& .MuiSvgIcon-root': {
                                            color: 'white'
                                        },
                                        '& ::placeholder': {
                                            color: 'white',
                                            opacity: '0.7'
                                        }
                                    },

                                }}
                                variant="standard"
                            />
                        </Box>
                        <Box display='flex' justifyContent={'flex-end'} marginTop='15px'>
                            <StyledSelect>
                                <FormControl focused={false} sx={{ width: { xs: '115px', sm: '135px' }, borderRadius: '20px !important' }}>
                                    <InputLabel id="demo-simple-select-label">Stream</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        required
                                        name='Status'
                                        value={selectedValue}
                                        label="Status"
                                        onChange={handleChange}
                                        InputProps={{
                                            disableUnderline: true,
                                            style: {
                                                borderRadius: '20px'
                                            }
                                        }}
                                        MenuProps={{ disableScrollLock: true }}
                                    >
                                        <MenuItem value={'Active'}>Active</MenuItem>
                                        <MenuItem value={'Pending'}>Pending</MenuItem>
                                        <MenuItem value={'Processing'}>Updating</MenuItem>
                                        <MenuItem value={'Warning'}>Warning</MenuItem>
                                        <MenuItem value={'Finished'}>Finished</MenuItem>
                                        <MenuItem value={'Not Connected'}>Unconnected</MenuItem>
                                        <MenuItem value={'all'}>All</MenuItem>

                                    </Select>
                                </FormControl>
                            </StyledSelect>
                        </Box>
                        <Box>
                            {isFetching ? <Loader height={'70vh'} /> : (
                                <Box>
                                    <Box display='flex' flexDirection={'column'} gap='1rem' marginTop={'10px'} >
                                        {data.allUsers.length > 0 && data.allUsers.map((item, i) => {

                                            let myDate = new Date(item.createdAt).toDateString()

                                            return <RegisteredUserCon key={i} bgcolor={item.status === 'Warning' ? 'rgba(255,0,0,0.2)' : '#F1EBEB'}>
                                                <Box>
                                                    <Typography fontWeight={'500'}>{item.email}</Typography>
                                                    <Box display={'flex'} gap='10px'>
                                                        <Typography fontWeight={'bold'}>Registered on:</Typography>
                                                        <Typography>{myDate}</Typography>
                                                    </Box>
                                                    <Box display={'flex'} gap='10px'>
                                                        <Typography fontWeight={'bold'}>Status:</Typography>
                                                        <Typography fontWeight={'bold'} color={item.status === 'Warning' ? 'yellow' : item.status === 'Not Connected' || item.status === 'Finished'  ? 'red' : item.status === 'Pending' || item.status === 'Processing' ? '#AA336A' : 'green'}
                                                        >{item.status === 'Processing' ? 'Updating' : item.status}</Typography>
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <IconButton onClick={() => navigate(`/UserProfile/${item._id}`)}>
                                                        <Settings />
                                                    </IconButton>
                                                </Box>
                                            </RegisteredUserCon>

                                        })}
                                    </Box>

                                </Box>
                            )}

                            {!isFetching && data && data.allUsers.length === 0 && (
                                <Box display={'flex'} justifyContent='center' marginTop={{ md: '5em' }}>
                                    <NoProductHeading>No User Found</NoProductHeading>
                                </Box>
                            )}

                            {data && data.resultPerPage < data.filteredUsersCount && (

                                <StyledPagination color='primary' size='large'
                                    count={Math.ceil(data.filteredUsersCount / data.resultPerPage)}
                                    page={currentPage}
                                    shape='rounded'
                                    onChange={(event, value) => setcurrentPage(value)}
                                    showLastButton showFirstButton hideNextButton hidePrevButton
                                    onClick={() => window.scrollTo(0, 0)}

                                />
                            )}

                        </Box>
                    </Box>
                </Box>
            </Box>
        </StyledUser>
    )
}
export default Users;