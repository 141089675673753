import { Box, Button, Container, Typography } from '@mui/material'
import React from 'react'
import { motion } from 'framer-motion';
import { KeyboardArrowDown } from '@mui/icons-material';
import { StyledBox } from './style';
import './style.css'
import img1 from '../../../assets/img.jpeg'
import img2 from '../../../assets/WhatsApp-Image-2022-12-17-at-2.38.59-AM.webp'
import img3 from '../../../assets/Zema Live Background 3.jpeg'
import img4 from '../../../assets/youGet.webp'

const YouGet = () => {


    return (
        <Container >
            <StyledBox sx={{ paddingTop: '60px' }}>
                <Typography fontFamily={'roboto'} textAlign='center' fontWeight='bold' fontSize={{ xs: '30px', sm: '35px' }}>
                    What you will get?
                </Typography>

                <Box display='flex' marginTop={'50px'} flexDirection={{ xs: 'column', sm: 'row' }} gap='40px'>

                    <Box flex='1'>
                        <motion.div
                            whileInView={{ x: [-300, 0], opacity: [0, 1] }}
                            transition={{ duration: 1.3 }}
                            viewport={{ once: true }}
                        >
                            <Box display='flex' gap='7px'>
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 1.75, yoyo: Infinity }}
                               
                               >

                                    <Typography color='#ef5350' fontSize={{ xs: '27px', sm: '25px', md: '35px' }} fontWeight={'bold'}>
                                        YouTube
                                    </Typography>

                                </motion.div>
                                <Typography color='black' fontSize={{ xs: '27px', sm: '25px', md: '35px' }} fontWeight={'bold'}>
                                    Live Stream
                                </Typography>
                            </Box>
                            <Typography color='#757575' fontSize={{ xs: '13px', sm: '12px', md: '15px' }} marginTop='15px'>Live stream to YouTube & Facebook simultaneously!!! You don’t need to use any dedicated computer or internet for streaming Live.</Typography>

                            <Button endIcon={<KeyboardArrowDown />}
                                variant='contained' color='error'
                                className='blob-btn'
                                sx={{
                                    borderRadius: '30px',
                                    fontSize: '16px',
                                    backgroundColor: 'black !important',
                                    marginTop: '20px',
                                    textTransform: 'none'
                                }}
                                href='#form2'
                            >
                                More
                                <span class="blob-btn__inner">
                                    <span class="blob-btn__blobs">
                                        <span class="blob-btn__blob"></span>
                                        <span class="blob-btn__blob"></span>
                                        <span class="blob-btn__blob"></span>
                                        <span class="blob-btn__blob"></span>
                                    </span>
                                </span>

                            </Button>
                        </motion.div>
                    </Box>
                    <Box flex='1' >
                        <motion.div
                            whileInView={{ x: [300, 0], opacity: [0, 1] }}
                            transition={{ duration: 1.3, ease: 'easeOut' }}
                            viewport={{ once: true }}
                        >
                            <Box component='img' src={img1} height='340px' width='100%' sx={{ borderRadius: '20px' }} />
                        </motion.div>
                    </Box>
                </Box>

                <Box component={'div'} id='form2' display='flex' marginTop={'50px'} flexDirection={{ xs: 'column-reverse', sm: 'row' }} gap='40px'>
                    <Box flex='1' >
                        <motion.div
                            whileInView={{ x: [-300, 0], opacity: [0, 1] }}
                            transition={{ duration: 1.3 }}
                            viewport={{ once: true }}
                        >
                            <Box component='img' src={img4} height='340px' width='100%' sx={{ borderRadius: '20px' }} />
                        </motion.div>
                    </Box>
                    <Box flex='1'>
                        <motion.div
                            whileInView={{ x: [300, 0], opacity: [0, 1] }}
                            transition={{ duration: 1.3, ease: 'easeOut' }}
                            viewport={{ once: true }}
                        >
                            <Typography color='black' fontSize={{ xs: '27px', sm: '25px', md: '35px' }} fontWeight={'bold'}>
                                Pre-Recorded Video Live Stream
                            </Typography>
                            <Typography color='#757575' fontSize={{ xs: '13px', sm: '12px', md: '15px' }} marginTop='15px'>Live stream to YouTube & Facebook simultaneously!!! You don’t need to use any dedicated computer or internet for streaming Live.</Typography>
                            <Button endIcon={<KeyboardArrowDown />}
                                variant='contained' color='error'
                                className='blob-btn'
                                sx={{
                                    borderRadius: '30px',
                                    fontSize: '16px',
                                    backgroundColor: 'black !important',
                                    marginTop: '20px',
                                    textTransform: 'none'
                                }}
                                href='#form3'
                            >
                                More
                                <span class="blob-btn__inner">
                                    <span class="blob-btn__blobs">
                                        <span class="blob-btn__blob"></span>
                                        <span class="blob-btn__blob"></span>
                                        <span class="blob-btn__blob"></span>
                                        <span class="blob-btn__blob"></span>
                                    </span>
                                </span>

                            </Button>
                        </motion.div>
                    </Box>
                </Box>

                <Box component='form' id='form3' display='flex' marginTop={'50px'} flexDirection={{ xs: 'column', sm: 'row' }} gap='40px'>

                    <Box flex='1'>
                        <motion.div
                            whileInView={{ x: [-300, 0], opacity: [0, 1] }}
                            transition={{ duration: 1.3 }}
                            viewport={{ once: true }}
                        >
                            <Box display='flex' gap='7px'>
                                <Typography color='black' fontSize={{ xs: '27px', sm: '25px', md: '35px' }} fontWeight={'bold'}>
                                    Non-stop 24x7 loop live streaming
                                </Typography>
                            </Box>
                            <Typography color='#757575' fontSize={{ xs: '13px', sm: '12px', md: '15px' }} marginTop='15px'>Stream in a LOOP! This means a continuous stream that keeps the audience active and attracts new viewers. *Note* advertising on streams is often more expensive.</Typography>
                            <Button endIcon={<KeyboardArrowDown />}
                                variant='contained' color='error'
                                className='blob-btn'
                                sx={{
                                    borderRadius: '30px',
                                    fontSize: '16px',
                                    backgroundColor: 'black !important',
                                    marginTop: '20px',
                                    textTransform: 'none'
                                }}
                                href='#form4'
                            >
                                More
                                <span class="blob-btn__inner">
                                    <span class="blob-btn__blobs">
                                        <span class="blob-btn__blob"></span>
                                        <span class="blob-btn__blob"></span>
                                        <span class="blob-btn__blob"></span>
                                        <span class="blob-btn__blob"></span>
                                    </span>
                                </span>

                            </Button>
                        </motion.div>
                    </Box>
                    <Box flex='1' >
                        <motion.div
                            whileInView={{ x: [300, 0], opacity: [0, 1] }}
                            transition={{ duration: 1.3, ease: 'easeOut' }}
                            viewport={{ once: true }}
                        >
                            <Box component='img' src={img3} height='340px' width='100%' sx={{borderRadius : '20px'}}/>
                        </motion.div>
                    </Box>
                </Box>


                <Box component='form' id='form4' display='flex' marginTop={'50px'} flexDirection={{ xs: 'column-reverse', sm: 'row' }} gap='40px'>
                    <Box flex='1' >
                        <motion.div
                            whileInView={{ x: [-300, 0], opacity: [0, 1] }}
                            transition={{ duration: 1.3 }}
                            viewport={{ once: true }}
                        >
                            <Box component='img' src={img2} height='340px' width='100%' sx={{borderRadius : '20px'}}/>
                        </motion.div>
                    </Box>
                    <Box flex='1'>
                        <motion.div
                            whileInView={{ x: [300, 0], opacity: [0, 1] }}
                            transition={{ duration: 1.3, ease: 'easeOut' }}
                            viewport={{ once: true }}
                        >
                            <Typography color='black' fontSize={{ xs: '27px', sm: '25px', md: '35px' }} fontWeight={'bold'}>
                            Increase Your Revenue by 10x!
                            </Typography>
                            <Typography color='#757575' fontSize={{ xs: '13px', sm: '12px', md: '15px' }} marginTop='15px'>You’ve probably already heard that YouTube & Facebook favors live streaming; They get more expensive ad space and new traffic. So don’t let the chance miss, Start earning More.</Typography>
                            <Button endIcon={<KeyboardArrowDown />}
                                variant='contained' color='error'
                                className='blob-btn'
                                sx={{
                                    borderRadius: '30px',
                                    fontSize: '16px',
                                    backgroundColor: 'black !important',
                                    marginTop: '20px',
                                    textTransform: 'none !important',
                                    color: 'white !important'
                                }}
                                href='#plans'

                            >
                                More
                                <span class="blob-btn__inner">
                                    <span class="blob-btn__blobs">
                                        <span class="blob-btn__blob"></span>
                                        <span class="blob-btn__blob"></span>
                                        <span class="blob-btn__blob"></span>
                                        <span class="blob-btn__blob"></span>
                                    </span>
                                </span>

                            </Button>
                        </motion.div>
                    </Box>
                </Box>
            </StyledBox>
        </Container>
    )
}

export default YouGet