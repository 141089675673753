import React, { useEffect } from "react";
import { useState } from "react";
import { Backdrop, Box, Grid, IconButton, Typography } from "@mui/material";
import { useAdminUserActivePackagesQuery } from "../../../../services/userApi";
import { useParams } from "react-router-dom";
import { motion } from 'framer-motion';
import { Menu } from "@mui/icons-material";
import { StyledUser } from "../style";
import Sidebar from '../../Sidebar/Sidebar'
import { Loader } from "../../../../Components";
import Header from "../Header/Header";
import Logo from '../../../../assets/logo.jpeg'

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { NoProductHeading } from "../PackageHistory/style";



const ActivePackagesAdmin = () => {

    const [open, setopen] = useState(false)

    const { id } = useParams()
    let UserProfile = true

    const [totalDays, settotalDays] = useState(0)
    const [totalVideos, settotalVideos] = useState(0)
    const [totalPrice, settotalPrice] = useState(0)
    const [totalNames, settotalNames] = useState([])
    const [startedTime, setstartedTime] = useState('')
    const [endedTime, setendedTime] = useState('')
    const [percentage, setpercentage] = useState('')
    const [totalEndingDate, settotalEndingDate] = useState()


    const { data, isFetching } = useAdminUserActivePackagesQuery(id)

    useEffect(() => {

        if (data && data.activePackages.length) {

            let x = []
            let days = 0
            let price = 0
            let videos = 0
            let timeDiff = 0
            let mydate 

            data.activePackages.forEach((item) => {

                videos = videos + item.videos
                if (item.name === 'Trial') {
                    timeDiff = timeDiff + 43200000
                    price = price + 0
                    days = days + 0.5

                } else {
                    price = price + item.price
                    days = days + item.days
                    timeDiff = timeDiff + (days * 86400000)

                }
                x.push(item.name)

            })

            mydate = new Date(data.totalStartTime + timeDiff)
            settotalEndingDate(mydate)

            settotalNames(x)
            settotalDays(days)
            settotalPrice(price)
            if (videos > 8) {
                settotalVideos(8)
            } else {
                settotalVideos(videos)
            }

            if (data.totalStartTime > 0) {

                let fullTime = new Date(data.totalStartTime)
                fullTime = fullTime.toLocaleTimeString()

                let firstTime = fullTime.slice(0, 5)
                let zone = fullTime.slice(8, 11)
                setstartedTime(firstTime + zone)

            }

        if (data.totalStartTime > 0) {

                let fullTime = new Date(mydate)
                fullTime = fullTime.toLocaleTimeString()

                let firstTime = fullTime.slice(0, 5)
                let zone = fullTime.slice(8, 11)
                setendedTime(firstTime + zone)

            }

            if (data.isExpireTime > 0) {

                // setexpiryTime(list.isExpireTime)

                var startDate = data.totalStartTime;
                var endDate = new Date(mydate).getTime()
                var todayDate = new Date().getTime();

                // Get the total possible timestamp value
                var total = endDate - startDate;

                // Get the current value
                var current = todayDate - startDate;

                // Get the percentage
                let x = (current / total) * 100
                x = x.toString()
                x = x.split('.')[0]
                x = Number(x)
                if (x <= 100) {
                    setpercentage(x)
                } else {
                    setpercentage(100)
                }
            }

        }

    }, [data])


    return (
        <StyledUser >

            <Box display={{ xs: 'none', md: 'flex' }} >
                <Sidebar UserProfile={UserProfile} />
            </Box>
            <Box display={{ xs: 'flex', md: 'none' }} sx={{ position: 'fixed', top: '10px', left: '10px', zIndex: '1' }}>
                <IconButton onClick={() => setopen(!open)} >
                    <Menu />
                </IconButton>
            </Box>
            <Backdrop open={open} onClick={() => setopen(false)} sx={{ zIndex: '1' }} />

            <Box>
                {open && (
                    <Box>
                        <motion.div

                            animate={{ x: [-300, 0] }}
                            transition={{ duration: 0.85, ease: 'easeOut' }}
                            className='myMenu'

                        >

                            <Sidebar open={open} setopen={setopen} UserProfile={UserProfile} />
                        </motion.div>

                    </Box>
                )}

            </Box>

            <Box marginLeft={{ md: '310px' }} className="user-con">
                <Header percentage={percentage} setpercentage={setpercentage} />
                {isFetching ? <Loader height={'70vh'} /> : (
                    <Box>
                        {data.activePackages.length > 0 && (
                            <>

                                <Box display='flex' gap='6px' flexWrap={'wrap'} marginTop='20px'>

                                    {totalNames.length > 0 && totalNames.map((item, i) => (
                                        <Typography fontWeight='bold' fontSize={{ xs: '27px', sm: '30px', md: '35px' }} key={i}>
                                            {totalNames.length !== i + 1 ? `'${item}',` : `'${item}'`}
                                        </Typography>
                                    ))}


                                </Box>

                                <Box marginTop='10px'>
                                    <Grid container spacing={{ xs: 2.5, md: 1, lg: 6 }}>
                                        <Grid item xs={12} sm={12} md={8}>
                                            <Box display='flex' flexDirection={'column'} gap='20px'>
                                                <Box bgcolor={'white'} padding={{ xs: '20px 15px', sm: '20px 33px' }} borderRadius={'20px'}>
                                                    <Box display='flex' gap='10px' justifyContent={'space-between'}>
                                                        <Box display='flex' flexDirection={'column'} gap='10px'>
                                                            <Typography fontSize={{ xs: '15px', sm: '17px', lg: '18px' }} fontWeight={'bold'}> Packages Name</Typography>
                                                            <Typography fontSize={{ xs: '15px', sm: '17px', lg: '18px' }} fontWeight={'bold'}>Total Price</Typography>
                                                            <Typography fontSize={{ xs: '15px', sm: '17px', lg: '18px' }} fontWeight={'bold'}>Total Days</Typography>
                                                            <Typography fontSize={{ xs: '15px', sm: '17px', lg: '18px' }} fontWeight={'bold'}>Total Videos</Typography>
                                                            <Typography fontSize={{ xs: '15px', sm: '17px', lg: '18px' }} fontWeight={'bold'}>Status</Typography>
                                                            <Typography fontSize={{ xs: '15px', sm: '17px', lg: '18px' }} fontWeight={'bold'}>Started Date & Time</Typography>
                                                            <Typography fontSize={{ xs: '15px', sm: '17px', lg: '18px' }} fontWeight={'bold'}>Expiry Date & Time</Typography>
                                                        </Box>
                                                        <Box display='flex' flexDirection={'column'} gap='10px'>
                                                            <Box display='flex' gap='5px' flexWrap={'wrap'} justifyContent='center'>
                                                                {totalNames.length > 0 && totalNames.map((item, i) => (
                                                                    <Box key={i}>
                                                                        {i < 3 && (
                                                                            <Typography fontSize={{ xs: '15px', sm: '17px', lg: '18px' }} fontWeight={'bold'} textAlign={'center'}>
                                                                                {
                                                                                    totalNames.length === 1 ? `${item.length > 15 ? `${item.substring(0, 15)}...` : item}` :
                                                                                        totalNames.length === 2 ? ` ${i === 0 ? `${item.length > 11 ? `'${item.substring(0, 11)}...',` : `'${item}',`}` : `${item.length > 11 ? `'${item.substring(0, 11)}...',` : `'${item}'`}`} ` :
                                                                                            totalNames.length > 2 && ` ${i === 0 ? `${item.length > 6 ? `'${item.substring(0, 6)}...` : `'${item}',`}` : `${item.length > 6 ? `'${item.substring(0, 6)}...',` : `'${item}'`}`} `
                                                                                }
                                                                            </Typography>
                                                                        )}

                                                                    </Box>
                                                                ))}
                                                            </Box>
                                                            <Typography fontSize={{ xs: '15px', sm: '17px', lg: '18px' }} fontWeight={'bold'} textAlign={'center'}>${totalPrice}</Typography>
                                                            <Typography fontSize={{ xs: '15px', sm: '17px', lg: '18px' }} fontWeight={'bold'} textAlign={'center'}>{totalDays}</Typography>
                                                            <Typography fontSize={{ xs: '15px', sm: '17px', lg: '18px' }} fontWeight={'bold'} textAlign={'center'}>{totalVideos}</Typography>
                                                            <Typography fontSize={{ xs: '15px', sm: '17px', lg: '18px' }} fontWeight={'bold'} color='green' textAlign={'center'}>Active</Typography>
                                                            <Typography fontSize={{ xs: '15px', sm: '15px', lg: '18px' }} fontWeight={'bold'} color={!data.totalStartTime > 0 && '#AA336A'} textAlign={'center'}>{data.totalStartTime > 0 ? `${new Date(data.totalStartTime).toDateString()}, ${startedTime}` : 'Processing'}</Typography>
                                                            <Typography fontSize={{ xs: '15px', sm: '15px', lg: '18px' }} fontWeight={'bold'} color={!data.totalStartTime > 0 && '#AA336A'} textAlign={'center'}>{data.totalExpireTime && data.totalStartTime > 0 ? `${new Date(totalEndingDate).toDateString()}, ${endedTime}` : 'Processing'}</Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box bgcolor='white' borderRadius={'20px'} padding='20px'>
                                                    <Typography fontWeight='bold' fontSize='25px'>Total Package Used</Typography>
                                                    <Box display='flex' justifyContent='center' marginTop='20px'>
                                                        <Box width='120px' height='120px'>
                                                            <CircularProgressbar value={percentage ? percentage : 0} text={`${percentage ? percentage : 0}%`} strokeWidth={3}
                                                                styles={buildStyles({

                                                                    // Colors
                                                                    textColor: '#ef5350',
                                                                    trailColor: '#d6d6d6',
                                                                    pathColor: `#ef5350`,

                                                                })}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <Box>
                                                <Box bgcolor={'white'} padding='20px 33px' borderRadius={'20px'}>
                                                    <Box display='flex' justifyContent={'center'}>
                                                        <Box component='img' src={Logo} height='90px' width='90px' borderRadius={'50px'} sx={{
                                                            transition: 'all 1.6s ease-in-out',
                                                            '&:hover': {
                                                                transform: 'rotateY(360deg)',
                                                            }
                                                        }} />
                                                    </Box>
                                                    <Typography marginTop='10px' fontSize='20px' fontWeight={'bold'} textAlign='center'>Zema <br /> Entertainment</Typography>
                                                </Box>
                                                <Box bgcolor={'white'} padding='20px 33px' borderRadius={'20px'} marginTop='20px'>
                                                    <Box paddingBottom={'15px'} borderBottom='1px solid #bdbdbd'>
                                                        <Typography fontWeight='bold' textAlign='center' fontSize={'22px'}>Order Summary</Typography>
                                                    </Box>
                                                    <Box display={'flex'} justifyContent='space-between' padding={'15px 0'} borderBottom='1px solid #bdbdbd'>
                                                        <Box display='flex' flexDirection='column' gap='5px'>
                                                            <Typography fontSize={'18px'} fontWeight='bold' >Name</Typography>
                                                            <Typography fontSize={'18px'} fontWeight='bold' >Days</Typography>
                                                            <Typography fontSize={'18px'} fontWeight='bold' >Videos</Typography>
                                                        </Box>
                                                        <Box display='flex' flexDirection='column' gap='5px'>
                                                            <Typography fontSize={'18px'} textAlign='center' fontWeight='bold'>Runner</Typography>
                                                            <Typography fontSize={'18px'} textAlign='center' fontWeight='bold'>{totalDays}</Typography>
                                                            <Typography fontSize={'18px'} textAlign='center' fontWeight='bold'>{totalVideos}</Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box display='flex' marginTop={'10px'} justifyContent={'space-between'} >
                                                        <Typography fontSize={'19px'} fontWeight='bold'>Total Price:</Typography>
                                                        <Typography fontSize={'19px'} paddingRight='19px' fontWeight='bold'>${totalPrice}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </>
                        )}
                    </Box>
                )}
                {!isFetching && data && data.activePackages.length === 0 && (
                    <Box display={'flex'} justifyContent='center' marginTop={{ xs: '50px', md: '220px' }}>
                        <NoProductHeading>No Active Packages Found</NoProductHeading>
                    </Box>
                )}

            </Box>

        </StyledUser>
    )
}
export default ActivePackagesAdmin;